import * as constants from "../../../constants";
import { TAction, TDispatch } from "../../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize } from "@api/jsonApiParser";
import { Dispatch } from "redux";

export const FETCHED_TENANTSETTINGS = "FETCHED_TENANTSETTINGS";
export const SET_TENANTSETTINGS_PAGE_NO = "SET_TENANTSETTINGS_PAGE_NO";
export const SET_FILTERING_CRITERIA_TENANTSETTINGS = "SET_FILTERING_CRITERIA_TENANTSETTINGS";
export const INCREMENT_TENANTSETTINGS_PAGE_NO = "INCREMENT_TENANTSETTINGS_PAGE_NO";

export interface TenantSettingsState {
  tenantSettings: any;
  tenantSettingsPageNo: any;
  moreTenantSettings: boolean;
  filter: any;
}

const initialState = {
  tenantSettings: [],
  tenantSettingsPageNo: 1,
  moreTenantSettings: true,
  filter: undefined,
};

export const TenantSettingsReducer = (
  state: TenantSettingsState = initialState,
  action: TAction
) => {
  switch (action.type) {
    case FETCHED_TENANTSETTINGS: {
      const { payload } = action;
      const { tenantSettings, page } = payload.tenantSettings;

      let newTenantSettings = [];
      const moreTenantSettings = !(tenantSettings.length < constants.PAGINATION_SIZE);

      if (state.tenantSettingsPageNo === 1 || page === 1) {
        newTenantSettings = [...tenantSettings];
      } else {
        newTenantSettings = [...state.tenantSettings, ...tenantSettings];
      }

      return {
        ...state,
        tenantSettings: newTenantSettings,
        moreTenantSettings: moreTenantSettings,
      };
    }

    case SET_TENANTSETTINGS_PAGE_NO: {
      const moreTenantSettings = !(
        state.tenantSettings.length <
        (state.tenantSettingsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return {
        ...state,
        tenantSettingsPageNo: action.payload,
        moreTenantSettings: moreTenantSettings,
      };
    }

    case SET_FILTERING_CRITERIA_TENANTSETTINGS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_TENANTSETTINGS_PAGE_NO: {
      return {
        ...state,
        tenantSettingsPageNo: state.tenantSettingsPageNo + 1,
      };
    }
  }
  return state;
};

export const setTenantSettingsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_TENANTSETTINGS_PAGE_NO,
      payload,
    });
  };
};

export const fetchTenantSettingList = (page = 1, pageSize = 10, filter?) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = `TenantAppSetting/TenantAppSettingPaginated?Page=${page}&PageSize=${pageSize}`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        if ((key === "dynamicFilter" && filter[key]) || (key === "GenericText" && filter[key]))
          query += `&SearchGeneric=${filter[key]}`;
      });
    }

    return await http.get(query).then((response: TResponse) => {
      dispatch({
        type: FETCHED_TENANTSETTINGS,
        payload: {
          tenantSettings: {
            tenantSettings: (response.data as Record<string, unknown>[]).map(tenantSetting =>
              deserialize(tenantSetting)
            ),
            page,
          },
        },
      });

      dispatch({ type: INCREMENT_TENANTSETTINGS_PAGE_NO });

      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const uploadFileTenantSetting = (
  tenantId: string,
  applicationCode: BigInteger,
  settingsType: BigInteger,
  tenantAppSettingId: string,
  files: FormData
) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return http.post(
    "TenantAppSetting?tenantId=" +
      tenantId +
      "&applicationCode=" +
      applicationCode +
      "&settingsType=" +
      settingsType +
      "&tenantAppSettingId=" +
      tenantAppSettingId,
    files,
    config
  );
};

export const deleteTenantSetting = (id: string) => {
  return http.delete("TenantAppSetting/" + id);
};

export const getTenantSettingById = (id: string) => {
  return http.get("TenantAppSetting/" + id);
};
