import { TAction, TDispatch } from "@redux/types";
import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize } from "@api/jsonApiParser";
import { Dispatch } from "redux";
import { TApplication } from "@utils/applications";

export const GENDER_IS_LOADING = "GENDER_IS_LOADING";
export const LANGUAGES_IS_LOADING = "LANGUAGES_IS_LOADING";
export const APPLICATIONS_IS_LOADING = "APPLICATIONS_IS_LOADING";

export type TEnumerator = {
  genderIsLoading: boolean;
  languagesIsLoading: boolean;
  applicationsIsLoading: boolean;
};

const initialState = {
  genderIsLoading: false,
  languagesIsLoading: false,
  applicationsIsLoading: false,
};

const EnumeratorReducer = (state: TEnumerator = initialState, action: TAction): TEnumerator => {
  const { type, payload } = action;

  switch (type) {
    case GENDER_IS_LOADING:
      return {
        ...state,
        genderIsLoading: payload,
      };

    case LANGUAGES_IS_LOADING:
      return {
        ...state,
        languagesIsLoading: payload,
      };

    case APPLICATIONS_IS_LOADING:
      return {
        ...state,
        applicationsIsLoading: payload,
      };
  }

  return state;
};

export const fetchGenders = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: GENDER_IS_LOADING, payload: true });

  return await http.get("Enumerator/Sex").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: GENDER_IS_LOADING, payload: false });
    return response.data.map(apps => deserialize(apps));
  });
};

export const fetchLanguages = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: LANGUAGES_IS_LOADING, payload: true });

  return await http
    .get("Enumerator/Language")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: LANGUAGES_IS_LOADING, payload: false });
      return response.data.map(lang => deserialize(lang));
    });
};

export const fetchApplications = () => async (dispatch: TDispatch): Promise<TApplication[]> => {
  dispatch({ type: APPLICATIONS_IS_LOADING, payload: true });

  return await http
    .get("Enumerator/Applications")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: APPLICATIONS_IS_LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(apps => deserialize(apps));
    });
};

export { EnumeratorReducer };
