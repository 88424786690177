import React, { FC } from "react";
import { useFormatMessage } from "@hooks/formatMessage";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import { BlankRow, ListParent, Header, ListWrapper } from "./styles";
import { TScrollableContainer } from "./types";

const ScrollableList: FC<TScrollableContainer> = props => {
  const { items, renderItem, renderHeader, onLoadMore } = props;

  const scrollRef = useBottomScrollListener(onLoadMore);
  const formatMessage = useFormatMessage();

  return (
    <ListParent>
      <ListWrapper ref={scrollRef as React.RefObject<HTMLDivElement>}>
        {renderHeader && <Header>{renderHeader}</Header>}
        {items && items.length > 0 ? (
          items.map((item, index) => renderItem(item, index))
        ) : (
          <BlankRow>{formatMessage("app.noRecordAvailable")}</BlankRow>
        )}
      </ListWrapper>
    </ListParent>
  );
};

export { ScrollableList };
