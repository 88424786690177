/**
 * Renders the appropriate content depending if the predicate is true or false
 */
import { EValidationTestType } from "./validationConfigs";

// import { IDictionary } from '../models';

export function renderIf(
  condition: boolean,
  content: {
    ifTrue: () => JSX.Element | JSX.Element[];
    ifFalse: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (condition) {
    return content.ifTrue();
  } else {
    return content.ifFalse();
  }
}

/**
 * Renders the given content only if the condition is true
 */
export function renderIfTrue(
  condition: boolean,
  content: () => JSX.Element
): JSX.Element | JSX.Element[] | null {
  if (condition) {
    return content();
  }
  return null;
}

/**
 * Renders the given content only if the condition is false
 */
export function renderIfFalse(
  condition: boolean,
  content: () => JSX.Element | JSX.Element[]
): JSX.Element | JSX.Element[] | null {
  if (!condition) {
    return content();
  }
  return null;
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefinedTrue<T>(
  data: T,
  content: (data: T) => JSX.Element
): JSX.Element | null {
  if (typeof data !== "undefined" && data !== null) {
    return content(data);
  } else {
    return null;
  }
}

/**
 * Renders the appropriate content depending if the variable is defined or not
 * @param data      Data variable to check if is defined & not null
 * @param content   The content to render
 */
export function renderDefined<T>(
  data: T,
  content: {
    isDefined: (data: T) => JSX.Element | JSX.Element[];
    isNotDefined: () => JSX.Element | JSX.Element[];
  }
): JSX.Element | JSX.Element[] {
  if (typeof data !== "undefined" && data !== null) {
    return content.isDefined && content.isDefined(data);
  } else {
    return content.isNotDefined && content.isNotDefined();
  }
}

/**
 * Renders a callback from the callbacks dictionary based on a key (similar to the switch statement)
 * @param key               Key of the callback to call
 * @param callbacks         All the available callbacks
 * @param defaultCallback   Available if nothing else matches
 */
export function renderSwitch(
  key: string | number,
  callbacks: IDictionary<() => JSX.Element>,
  defaultCallback?: () => JSX.Element
): JSX.Element | null {
  if (callbacks[key]) {
    return callbacks[key]();
  } else {
    if (defaultCallback) {
      return defaultCallback();
    } else {
      return null;
    }
  }
}

export const parseDate = (str, type?) => {
  // console.log("parseDate utils: ", str);
  if (/\d{4}-\d{2}-\d{2}/gm.test(str)) {
    if (str != null && str !== "") {
      const d = new Date(str);
      let month = "" + (d.getMonth() + 1);
      let day = "" + d.getDate();
      const year = d.getFullYear();
      let hours = "" + d.getHours();
      let minutes = "" + d.getMinutes();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;

      if (type && type === "hr-min") {
        return [day, month, year].join(".") + " - " + [hours, minutes].join(":");
      }
      // console.log("will return date matched: ", day, month, year);
      const date = [day, month, year].join(".");
      if (date.includes("NaN")) {
        return;
      }
      return date;
    } else {
      return "";
    }
  } else {
    return str;
  }
};

export const parseToApiDate = str => {
  if (/\d{2}\.\d{2}\.\d{4}/gm.test(str)) {
    const parts = str.split(".");
    return parts[2] + "-" + parts[1] + "-" + parts[0] + "T00:00:00";
  }
  return str;
};

export function removeEmptyObjects(o) {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue; // If null or not an object, skip to the next iteration
    }
    // The property is an object
    removeEmptyObjects(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

export function emptyStringsToNull(o) {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      if (o[k] === "") {
        o[k] = null;
      }
      if (typeof o[k] === "undefined") {
        delete o[k];
      }
    } else {
      emptyStringsToNull(o[k]);
    }
  }
  return o;
}

export function formatNumber(num) {
  if (!num) {
    return num;
  }
  if (typeof num === "string" && /[a-zA-Z]/.test(num)) {
    return num;
  }
  if (typeof num === "string") {
    num = num.replace(/'/g, "").replace(/,/g, ".");
  }
  return parseFloat(num)
    .toFixed(2)
    .replace(/./g, function (c, i, a) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "'" + c : c;
    });
}

export function inputIsRequired(validationTests) {
  return (
    validationTests.indexOf(EValidationTestType.hasValue) >= 0 ||
    validationTests.indexOf(EValidationTestType.hasAnyValue) >= 0
  );
}

export function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function compareObjects(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function percentage(max, value) {
  return max && value ? (value * 100) / max : 0;
}

// export function formatNumberBack(num) {
// console.log("formatNumberBack: ", num);
// return num.replace("'", "");
// }

// export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
//     console.log("formatMoney: ", amount);
//     decimalCount = Math.abs(decimalCount);
//     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
//
//     const negativeSign = amount < 0 ? "-" : "";
//
//     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
//     let j = (i.length > 3) ? i.length % 3 : 0;
//
//     console.log("will return: ", negativeSign + (j ? i.substr(0, j) + thousands : '') +
//         i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
//         (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : ""));
//
//     return negativeSign + (j ? i.substr(0, j) + thousands : '') +
//         i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
//         (decimalCount ? decimal + Math.abs(amount - parseInt(i)).toFixed(decimalCount).slice(2) : "");
// };
