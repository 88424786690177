import { deserialize } from "@api/jsonApiParser";
import { Dispatch } from "redux";
import http from "../../../api/http";
import { LOADING } from "../../../constants";

export const importDatabase = (fileId: string, files: FormData) => {
  if (fileId) {
    return http.post("Database/Import?fileId=" + fileId);
  } else {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return http.post("Database/Import", files, config);
  }
};

export const fetchFiles = (filter?) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });
    let query = "";
    if (filter) {
      Object.keys(filter).forEach(key => {
        query += `${query ? "&" : "?"}Search=${filter[key]}`;
      });
    }
    query = "File/" + query;
    return await http.get(query).then((response: any) => {
      dispatch({ type: LOADING, payload: false });
      return response.data.map(res => deserialize(res));
    });
  };
};
