export const SIDEBAR_SET = "SIDEBAR_SET";
export const LOADING = "LOADING";

export const UPDATE_LOCALES = "UPDATE_LOCALES";
export const UPDATE_EXPIRATION = "UPDATE_EXPIRATION";

export const FETCH_ERROR = "FETCH_ERROR";
export const POST_ERROR = "POST_ERROR";
export const PATCH_ERROR = "PATCH_ERROR";
export const DELETE_ERROR = "DELETE_ERROR";

export const POST_SUCCESS = "POST_SUCCESS";
export const PATCH_SUCCESS = "PATCH_SUCCESS";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const FETCHED_WEBSERVICES = "FETCHED_WEBSERVICES";

export const POPUP = "POPUP";

export const PAGINATION_SIZE = 10;
