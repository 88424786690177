export function objectToSearchParams(object: IDictionary<any>) {
  const searchParams = new URLSearchParams();
  Object.keys(object).forEach(key => searchParams.set(key, object[key]));
  return searchParams;
}

export function objectToFormData(object: IDictionary<any>) {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.set(key, object[key]));
  return formData;
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
