import * as yup from "yup";

const FilesSchema = yup.object().shape({
  id: yup.string(),
  folderId: yup.object().required().nullable(),
  files: yup.mixed().test("isEmpty", "app.required", value => {
    return value && value.length > 0;
  }),
});

export { FilesSchema };
