import styled from "@emotion/styled";

const StyledContainer = styled.div`
  background-image: url("../../assets/imgs/background-1920w.jpg");
  //background-image: -webkit-image-set(
  //  url("../../assets/imgs/background-710w.jpg") 0.5x,
  //  url("../../assets/imgs/background-1024w.jpg") 0.75x,
  //  url("../../assets/imgs/background-1920w.jpg") 1x,
  //  url("../../assets/imgs/background-3840w.jpg") 2x
  //);
  background-size: cover;
  background-repeat: no-repeat;
`;

export { StyledContainer };
