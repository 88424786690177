import styled from "@emotion/styled";
import { Flex } from "reflexbox";
import { colors } from "@styles/colors";

const ListContainer = styled(Flex)`
  height: 100%;
  flex-direction: column;
  background: ${colors.greyLight};
`;

export { ListContainer };
