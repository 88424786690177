import http from "../../../api/http";
import { TConnectionsState } from "./types";
import { TAction } from "../../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";
import {
  buildFilterQueryString,
  // buildOrderQueryString,
  deserialize,
  serialize,
} from "../../../api/jsonApiParser";

import { Dispatch } from "redux";
import { TResponse } from "../../../api/types";

export const FETCHED_CONNECTIONS = "FETCHED_CONNECTIONS";
export const SET_FILTERING_CRITERIA_CONNECTIONS = "SET_FILTERING_CRITERIA_CONNECTIONS";
export const SET_ORDER_CRITERIA_CONNECTIONS = "SET_ORDER_CRITERIA_CONNECTIONS";
export const SET_CONNECTIONS_PAGE_NO = "SET_CONNECTIONS_PAGE_NO";
export const INCREMENT_CONNECTIONS_PAGE_NO = "INCREMENT_CONNECTIONS_PAGE_NO";

const initialState = {
  connections: [],
  connectionsPageNo: 1,
  moreConnections: true,
  filter: undefined,
  order: [],
};

const ConnectionsReducer = (state: TConnectionsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_CONNECTIONS: {
      let connections = [];
      const moreConnections = !(action.payload.length < PAGINATION_SIZE);

      if (state.connectionsPageNo === 1 || action.page === 1) {
        connections = [...action.payload];
      } else {
        connections = [...state.connections, ...action.payload];
      }

      return { ...state, connections: connections, moreConnections: moreConnections };
    }

    case SET_CONNECTIONS_PAGE_NO: {
      const moreConnections = !(
        state.connections.length <
        (state.connectionsPageNo + 1) * PAGINATION_SIZE
      );
      return { ...state, connectionsPageNo: action.payload, moreConnections: moreConnections };
    }

    case SET_FILTERING_CRITERIA_CONNECTIONS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_CONNECTIONS_PAGE_NO: {
      return {
        ...state,
        connectionsPageNo: state.connectionsPageNo + 1,
      };
    }
  }
  return state;
};

export const setConnectionsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_CONNECTIONS_PAGE_NO,
      payload,
    });
  };
};

export const setConnectionsFilterCriteria = (payload: any): TAction => ({
  type: SET_FILTERING_CRITERIA_CONNECTIONS,
  payload,
});

export const fetchConnectionsList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `Connection/ConnectionPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: FETCHED_CONNECTIONS,
          payload: response.data.map(connection => deserialize(connection)),
          page: page,
        });

        dispatch({
          type: INCREMENT_CONNECTIONS_PAGE_NO,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createConnection = (payload): Promise<TResponse> => {
  return http.post("Connection/", serialize(payload, "Connection"));
};

export const changeConnection = (id: string, payload): Promise<TResponse> => {
  return http.patch("Connection/" + id, payload);
};

export const deleteConnection = (id: string): Promise<TResponse> => {
  return http.delete("Connection/" + id);
};

export const changePasswordConnection = (id: string, password: string): Promise<TResponse> => {
  const info = { id: id, password: password };
  return http.post("Connection/ChangePassword", serialize(info, "ChangePassword"));
};

export const validateConnection = (payload): Promise<TResponse> => {
  return http.post("Connection/ValidateConnection/", serialize(payload, "Connection"));
};

export const getConnectionById = (id: string): Promise<TResponse> => {
  return http.get("Connection/" + id);
};

export { ConnectionsReducer };
