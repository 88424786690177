import * as yup from "yup";

const FolderSchema = yup.object().shape({
  id: yup.string(),
  folderName: yup.string().required(),
  parentFolderId: yup.object(),
  tenantId: yup.object(),
  folderType: yup.object().required().nullable(),
});

export { FolderSchema };
