import * as yup from "yup";

const VersionSchema = yup.object().shape({
  id: yup.string(),
  createdDate: yup.date().required(),
  endDate: yup.date().nullable(),
  application: yup.object().required().nullable(),
  tenantId: yup.object().nullable(),
  major: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  minor: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  build: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  revision: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
});

export { VersionSchema };
