import * as yup from "yup";

const DefaultVariableSchema = yup.object().shape({
  passwordSalt: yup.string().required(),
  secretKeyForOtpNetHotp: yup.string().required(),
  baseUrlPostalLetter: yup.string().required(),
  tokenPostalLetter: yup.string().required(),
  printInColorPostalLetter: yup.boolean(),
  priorityMailPostalLetter: yup.boolean(),
  sms_AwsAccessKeyId: yup.string().required(),
  sms_AwsSecretAccessKey: yup.string().required(),
  translation_AwsAccessKeyId: yup.string().required(),
  translation_AwsSecretAccessKey: yup.string().required(),
  secretForConnection: yup.string().required(),
  secretForMailServerSetting: yup.string().required(),
  secretForWebService: yup.string().required(),
});

export { DefaultVariableSchema };
