import React, { FC, useEffect } from "react";
import { Flex } from "reflexbox";

import {
  Form,
  Button,
  TextButton,
  FormInput as Input,
  ModalContainer,
  ModalHeader,
} from "@icr/react.uicomponents";
import { ReactComponent as ArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";
import { useSwal } from "@hooks/useSwal";

import { TModalPassword } from "./types";
import { WebServicePasswordSchema } from "./schema";
import { changePasswordWebservice } from "../ducks";
import { useFormatMessage } from "@hooks/formatMessage";

const WebServicesModalPassword: FC<TModalPassword> = ({ closeModal, initialData }) => {
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  // Language
  const formatMessage = useFormatMessage();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Password has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    swalConfirmationMessage({
      text: "Do you really want to change this webservice password?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Change password",
    }).then(value => {
      if (value) {
        const { id, password } = formData;

        changePasswordWebservice(id, password).then(res => {
          if (res.success) showSuccessMessage("changed");
        });
      }
    });
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <TextButton id="backBtn" onClick={closeModal} font="bold">
          <Flex mr="20px">
            <ArrowLeftBlue />
          </Flex>
          {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
        </TextButton>
      </ModalHeader>
      <Flex flexDirection="column">
        <Form onSubmit={onSubmit} schema={WebServicePasswordSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="password"
              name="password"
              type="password"
              width="50%"
              label={formatMessage("app.password")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="repeatPassword"
              name="repeatPassword"
              type="password"
              width="50%"
              label={formatMessage("app.repeatPassword")}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <TextButton id="ModalBtn_cancel" mr="32px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </TextButton>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </ModalContainer>
  );
};

export default WebServicesModalPassword;
