import React, { FC } from "react";
import { Flex } from "reflexbox";

import { TCheckbox } from "@components/Checkbox/types";
import { StyledLabel as Label } from "@components/Label";
import { StyledCheckbox } from "@components/Checkbox/styles";

const Checkbox: FC<TCheckbox> = ({
  label,
  name,
  wrapperProps,
  checked,
  setChecked,
  id,
  ...rest
}) => {
  const handleOnChange = () => {
    setChecked(!checked);
  };

  return (
    <Flex flexDirection="column" justifyContent="space-between" {...wrapperProps}>
      {label && <Label>{label}</Label>}
      <Flex height="50px" alignItems="center">
        <input name={name} type="checkbox" style={{ display: "none" }} {...rest} />
        <StyledCheckbox id={id + "StyledCheckbox"} checked={checked} onClick={handleOnChange} />
      </Flex>
    </Flex>
  );
};

export { Checkbox };
