import * as yup from "yup";

const TenantTranslationsSchema = yup.object().shape({
  id: yup.string(),
  translateId: yup.string().required(),
  originalText: yup.string(),
  tenantId: yup.object().required().nullable(),
  layer: yup.object().required().nullable(),
  module: yup.object().required().nullable(),
  translationType: yup.object().required().nullable(),
  language: yup.object().required().nullable(),
  baseTextTranslateId: yup.lazy(() =>
    yup.object().when("translationId", {
      is: val => !val,
      then: yup.object().required("Select an item or enter translation id").nullable(),
      otherwise: yup.object().nullable(),
    })
  ),
  translationId: yup.lazy(() =>
    yup.string().when("baseTextTranslateId", {
      is: val => val && val.value === 0,
      then: yup.string().required("Enter translation id or select an base text"),
      otherwise: yup.string(),
    })
  ),
  automaticTranslation: yup.boolean(),
  textTranslated: yup.string().when("automaticTranslation", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
});

export { TenantTranslationsSchema };
