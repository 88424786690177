import swal from "@sweetalert/with-react";

export type swalOptions = {
  text?: string;
  type: "success" | "warning" | "info" | "error";
  confirmationText?: string;
  cancelationText?: string;
  content?: JSX.Element;
};

type useSwalReturn = {
  swalAlert: (swalOptions: swalOptions) => Promise<any>;
  swalConfirmationMessage: (swalOptions: swalOptions) => Promise<any>;
  swalConfirmationWithCallback: (swalOptions: swalOptions, callback: () => void) => void;
  swalAlertWithCallback: (swalOptions: swalOptions, callback: () => void) => void;
};

const useSwal = (): useSwalReturn => {
  const swalAlert = (swalOptions: swalOptions): Promise<any> => {
    return swal({
      buttons: {
        ok: {
          text: "Ok",
          className: "modal-main-button primary",
        },
      },
      content: swalOptions.content,
      className: "overflow-auto",
      height: "100%",
      width: "100%",
      icon: swalOptions.type,
      text: swalOptions.content ?? swalOptions.text,
    });
  };
  const swalConfirmationMessage = (swalOptions: swalOptions): Promise<any> => {
    return swal({
      buttons: {
        cancel: swalOptions.cancelationText,
        confirm: {
          text: swalOptions.confirmationText,
          className: "modal-main-button primary",
        },
      },
      type: swalOptions.type,
      content: swalOptions.content,
      className: "overflow-auto",
      height: "100%",
      width: "100%",
      text: swalOptions.content ?? swalOptions.text,
    });
  };
  const swalConfirmationWithCallback = (swalOptions: swalOptions, callback: () => void): void => {
    swalConfirmationMessage(swalOptions).then(response => {
      if (response) callback();
    });
  };
  const swalAlertWithCallback = (swalOptions: swalOptions, callback: () => void): void => {
    swalAlert(swalOptions).then(response => {
      if (response) callback();
    });
  };

  return {
    swalAlert,
    swalConfirmationMessage,
    swalConfirmationWithCallback,
    swalAlertWithCallback,
  };
};

export { useSwal };
