import apiCaller from "../api/apiHandler";
import requestWraper from "../api/requestWraper";
import * as constants from "../constants";
const appUrl = process.env.REACT_APP_URL;
// employee === insurant

export const passwordForgotInit = username =>
  requestWraper(dispatch => {
    const headers = {
      "Content-Type": "application/json",
    };
    const infoPost = {
      data: {
        username: username,
        url: `${appUrl}login?password-reset=2&token=[token]`,
      },
    };
    return apiCaller("POST", "Authenticate/PasswordForgot", infoPost, headers, true)
      .then((response: any) => {
        dispatch({ type: constants.POST_SUCCESS, payload: response.data });
        return "OK";
      })
      .catch(err => {
        throw err;
      });
  });

export const createUser = () => {
  return true;
};

export const passwordReset = (password, token) =>
  requestWraper(dispatch => {
    const headers = {
      "Content-Type": "application/json",
    };
    const infoPost = {
      data: {
        password: password,
        token: token,
      },
    };
    return apiCaller("POST", "Authenticate/PasswordReset", infoPost, headers, true)
      .then((response: any) => {
        dispatch({ type: constants.POST_SUCCESS, payload: response.data });
        return "OK";
      })
      .catch(err => {
        throw err;
      });
  });
