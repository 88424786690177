import * as yup from "yup";

const ApplicationSchema = yup.object().shape({
  id: yup.string(),
  applicationCode: yup.object().required(),
  url: yup.string().required(),
  pathLocal: yup.string().nullable(),
  debuggingMode: yup.boolean(),
});

export { ApplicationSchema };
