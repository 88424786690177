import * as constants from "../../../constants";
import { TAction, TDispatch } from "../../../redux/types";

import http from "@api/http";
import { deserialize, serialize } from "@api/jsonApiParser";
import { TResponse } from "@api/types";
import { Dispatch } from "redux";

export const FETCHED_TENANTS = "FETCHED_TENANTS";
export const SET_FILTERING_CRITERIA_TENANTS = "SET_FILTERING_CRITERIA_TENANTS";
export const SET_TENANTS_PAGE_NO = "SET_TENANTS_PAGE_NO";
export const INCREMENT_TENANTS_PAGE_NO = "INCREMENT_TENANTS_PAGE_NO";

export interface TenantState {
  tenants: any;
  tenantsPageNo: any;
  moreTenants: boolean;
  filter: any;
}

const initialState = {
  tenants: [],
  tenantsPageNo: 1,
  moreTenants: true,
  filter: undefined,
};

export const TenantReducer = (state: TenantState = initialState, action: TAction): TenantState => {
  switch (action.type) {
    case FETCHED_TENANTS: {
      const { payload } = action;
      const { tenants, page } = payload;

      let newTenants = [];
      const moreTenants = !(tenants.length < constants.PAGINATION_SIZE);

      if (state.tenantsPageNo === 1 || page === 1) {
        newTenants = [...tenants];
      } else {
        newTenants = [...state.tenants, ...tenants];
      }

      return { ...state, tenants: newTenants, moreTenants: moreTenants };
    }

    case SET_TENANTS_PAGE_NO: {
      const moreTenants = !(
        state.tenants.length <
        (state.tenantsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, tenantsPageNo: action.payload, moreTenants: moreTenants };
    }

    case SET_FILTERING_CRITERIA_TENANTS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_TENANTS_PAGE_NO: {
      return {
        ...state,
        tenantsPageNo: state.tenantsPageNo + 1,
      };
    }
  }
  return state;
};

export const setTenantsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_TENANTS_PAGE_NO,
      payload,
    });
  };
};

export const fetchTenantsToDropdown = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http.get("Tenant/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: constants.LOADING, payload: false });
    return (response.data as Record<string, unknown>[]).map(user => deserialize(user));
  });
};

export const fetchTenantList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = `Tenant/TenantPaginated?Page=${page}&PageSize=${pageSize}`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        if ((key === "dynamicFilter" || key === "GenericText") && filter[key])
          query += `&SearchGeneric=${filter[key]}`;
      });
    }

    return await http.get(query).then((response: TResponse) => {
      dispatch({
        type: FETCHED_TENANTS,
        payload: {
          tenants: (response.data as Record<string, unknown>[]).map(tenant => deserialize(tenant)),
          page: page,
        },
      });

      dispatch({ type: INCREMENT_TENANTS_PAGE_NO });

      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const createTenant = payload => {
  return http.post("Tenant/", serialize(payload, "Tenant"));
};

export const changeTenant = (id: string, payload) => {
  return http.put("Tenant/" + id, serialize(payload, "Tenant"));
};

export const deleteTenant = (id: string) => {
  return http.delete("Tenant/" + id);
};

export const getTenantById = (id: string) => {
  return http.get("Tenant/" + id);
};
