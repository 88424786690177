import * as yup from "yup";

const ObjectSchema = yup.object().shape({
  id: yup.string(),
  objectName: yup.string().required(),
  applicationCode: yup.object().required().nullable(),
  objectType: yup.object().required().nullable(),
});

export { ObjectSchema };
