import * as React from "react";
import { InputLabel } from "./Label";
import { InputWrapper } from "./InputWrapper";
import { EInputType, InputContent } from "./Content";
import { renderDefinedTrue } from "../../utils/react";
import { EValidationTestType } from "../../utils/validationConfigs";
import { ValidationMessage, ValidationMessageProps } from "../ValidationMessage";

export enum EInputTextType {
  Standard = "Standard",
  Material = "Material",
}

export interface IInputConfig {
  // @TODO fix it for radio buttons!
  checkboxLabel?: any;
  checked?: boolean;
  clearable?: boolean;
  customComponent?: JSX.Element;
  defaultChecked?: boolean;
  defaultValidationMessageConfig?: ValidationMessageProps;
  defaultValue?: any;
  disabled?: boolean;
  dropdownLabel?: string;
  forcedValue?: any;
  id?: string;
  inputTextType?: EInputTextType;
  inputType?: EInputType;
  inputWrapperClass?: string;
  inputWrapperStyle?: React.CSSProperties;
  isFocused?: boolean;
  isLoading?: boolean;
  label?: string | JSX.Element;
  max?: number;
  maxLength?: number;
  min?: number;
  name?: string;
  onBlur?: any;
  onChange?: (name: any, value?: any, file?: any) => void;
  onDropdownOpen?: (isOpen: boolean) => void;
  onSelected?: any;
  placeholder?: string;
  radioOptions?: IInputConfig[];
  readOnly?: boolean;
  selectOptions?: Record<string, unknown>[];
  step?: string;
  style?: any;
  type?: string;
  validationMessageConfig?: ValidationMessageProps;
  validationTests?: EValidationTestType[];
  value?: any;
  filtering?: boolean;
  cols?: number;
  rows?: number;
  isOpen?: boolean;
  onFocus?: any;
}

export class OldInput extends React.Component<IInputConfig, unknown> {
  render(): JSX.Element {
    const {
      validationMessageConfig = null,
      inputWrapperStyle,
      children,
      style,
      inputWrapperClass,
    } = this.props;

    return (
      <InputWrapper
        inputWrapperStyle={inputWrapperStyle}
        inputWrapperClass={inputWrapperClass || ""}
      >
        {renderDefinedTrue(children, () => (
          <InputLabel style={style || {}}>{children}</InputLabel>
        ))}
        {renderDefinedTrue(validationMessageConfig, () => (
          <ValidationMessage {...validationMessageConfig} />
        ))}
        <InputContent inputConfig={this.props} />
      </InputWrapper>
    );
  }
}
