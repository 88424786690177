import { Dispatch } from "redux";

import http from "@api/http";
import { TResponse } from "@api/types";
import { buildFilterQueryString, deserialize } from "@api/jsonApiParser";

import * as constants from "../../../constants";
import { TAction } from "../../../redux/types";

export const SET_EMAILLOGS_PAGE_NO = "SET_EMAILLOGS_PAGE_NO";
export const FETCHED_EMAILLOGS = "FETCHED_EMAILLOGS";
export const SET_FILTERING_CRITERIA_EMAILLOGS = "SET_FILTERING_CRITERIA_EMAILLOGS";
export const INCREMENT_EMAIL_LOG_PAGE_NO = "INCREMENT_EMAIL_LOG_PAGE_NO";

export interface EmailLogsState {
  emailLogs: any;
  emailLogsPageNo: any;
  moreEmailLogs: boolean;
  filter: any;
}

const initialState = {
  emailLogs: [],
  emailLogsPageNo: 1,
  moreEmailLogs: true,
  filter: {},
};

export const EmailLogsReducer = (state: EmailLogsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_EMAILLOGS: {
      let emailLogs = [];
      const moreEmailLogs = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.emailLogsPageNo === 1 || action.page === 1) {
        emailLogs = [...action.payload];
      } else {
        emailLogs = [...state.emailLogs, ...action.payload];
      }

      return { ...state, emailLogs: emailLogs, moreEmailLogs: moreEmailLogs };
    }

    case SET_EMAILLOGS_PAGE_NO: {
      const moreEmailLogs = !(
        state.emailLogs.length <
        (state.emailLogsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, emailLogsPageNo: action.payload, moreEmailLogs: moreEmailLogs };
    }

    case SET_FILTERING_CRITERIA_EMAILLOGS: {
      if (!action.payload) {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload };
      }
    }

    case INCREMENT_EMAIL_LOG_PAGE_NO: {
      return {
        ...state,
        emailLogsPageNo: state.emailLogsPageNo + 1,
      };
    }
  }
  return state;
};

export const setEmailLogsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_EMAILLOGS_PAGE_NO,
      payload,
    });
  };
};

export const setEmailLogsFilterCriteria = (payload: any): TAction => ({
  type: SET_FILTERING_CRITERIA_EMAILLOGS,
  payload,
});

export const fetchEmailLogList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `EmailLog/EmailLogPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse<Record<string, unknown>[]>) => {
        dispatch({
          type: FETCHED_EMAILLOGS,
          payload: (response.data as Record<string, unknown>[]).map(emailLogList =>
            deserialize(emailLogList)
          ),
          page: page,
        });

        dispatch({ type: INCREMENT_EMAIL_LOG_PAGE_NO });

        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};
