import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import {
  Button,
  TextButton,
  Form,
  FormInput as Input,
  FormSelect as Select,
  ModalContainer,
  ModalHeader,
} from "@icr/react.uicomponents";

import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { CompanySchema } from "./schema";
import { changeCompany, createCompany, fetchCompanies } from "../ducks";
import { ReactComponent as ArrowLeftBlue } from "@assets/icons/arrow-left-blue.svg";

import { TDispatch } from "@redux/types";
import { useFormatMessage } from "@hooks/formatMessage";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const CompaniesModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;

  const [isEditing, setIsEditing] = useState<boolean>();
  const [parentCompanyList, setParentCompanyList] = useState<Record<string, unknown>[]>([]);

  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    setIsEditing(!!initialData.id);
    setParentCompanyList(prev =>
      initialData.id && prev ? prev.filter(data => data.id !== initialData.id) : prev
    );
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    dispatch(fetchCompanies()).then(res => {
      setParentCompanyList(
        res.map(option => ({
          value: option.id,
          label: option.companyName,
        }))
      );
    });
  }, [dispatch]);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "Company has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const companyMapping = {
      ...formData,
      parentCompanyId: formData.parentCompanyId ? formData.parentCompanyId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to edit this company?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeCompany(formData.id, companyMapping).then(ret => {
            if (ret.success) successfulMessage("updated");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this company?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Company",
      }).then(value => {
        if (value) {
          delete companyMapping.id;
          createCompany(companyMapping).then(res => {
            if (res.success) successfulMessage("inserted");
          });
        }
      });
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <TextButton id="backBtn" onClick={closeModal} font="bold">
          <Flex mr="20px">
            <ArrowLeftBlue />
          </Flex>
          {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
        </TextButton>
      </ModalHeader>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={CompanySchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="companyName"
              name="companyName"
              label={<FormattedMessage id="app.companyName" defaultMessage="Company Name" />}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="emailContact"
              name="emailContact"
              label={<FormattedMessage id="app.emailContact" defaultMessage="Email Contact" />}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="parentCompanyId"
              name="parentCompanyId"
              label={<FormattedMessage id="app.parentCompanyId" defaultMessage="Parent Company" />}
              options={parentCompanyList}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
            </Button>
            <Button id="ModalBtn_save" type="submit">
              <FormattedMessage id="app.save" defaultMessage="Save" />
            </Button>
          </Flex>
        </Form>
      </Flex>
    </ModalContainer>
  );
};

export default CompaniesModal;
