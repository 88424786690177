type TApplication = {
  id: string;
  name: string;
  description: string;
};

const buildApplicationIdentifier = (application: TApplication): string =>
  application.id + application.name.charAt(0).toLowerCase() + application.name.slice(1);

export { buildApplicationIdentifier };
export type { TApplication };
