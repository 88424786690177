import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { TChild, TForm } from "./types";

const recursiveMap = (children, fn, methods) => {
  return React.Children.map(children, child => {
    if (!React.isValidElement<TChild>(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveMap(child.props.children, fn, methods),
      });
    }

    return fn(child, methods);
  });
};

const createElem = (child, methods) => {
  const { errors, control, register, watch, setValue, getValues } = methods;
  const { props, type } = child;
  const { name } = props;

  return name
    ? React.createElement(type, {
        ...{
          ...props,
          watch,
          errors,
          control,
          getValues,
          setValue,
          register,
          key: child.props.name,
        },
      })
    : child;
};

const Form: FC<TForm> = ({
  defaultValues,
  manualErrors,
  children,
  onSubmit,
  schema,
  customErrors,
}) => {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });

  const { handleSubmit, setError } = methods;

  useEffect(() => {
    if (manualErrors && manualErrors.length > 0) {
      manualErrors.map(error =>
        setError(error.fieldName, {
          type: "manual",
          message: error.message,
        })
      );
    }
  }, [manualErrors, setError]);

  useEffect(() => {
    if (customErrors && customErrors.length > 0) {
      customErrors.map(field =>
        setError(field.name, {
          type: "custom",
          message: field.message,
        })
      );
    }
  }, [customErrors, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>{recursiveMap(children, createElem, methods)}</form>
  );
};

export { Form };
