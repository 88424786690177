import { Flex } from "reflexbox";
import React, { FC, useEffect } from "react";
import ReactSelect from "react-select";
import { FormattedMessage } from "react-intl";
import { useController } from "react-hook-form";

import { StyledLabel as Label } from "@components/Label";
import { StyledError as Error } from "@components/Error";
import { customStyles } from "@components/Form/Select/styles";
import { TSelect } from "@components/Select/types";

const FormSelect: FC<TSelect> = ({
  label,
  control,
  name,
  options,
  errors,
  wrapperProps,
  setValue,
  defaultValue,
  ...rest
}) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const {
    defaultValuesRef: { current },
  } = control;

  useEffect(() => {
    const selectedValue = current[name];
    const selectedOption = options?.find(opt => String(opt.value) === String(selectedValue));
    setValue(name, selectedOption);
  }, [current, name, options, setValue]);

  return (
    <Flex flexDirection="column" width="100%" pr="15px" pl="15px" {...wrapperProps}>
      {label && <Label>{label}</Label>}
      <Flex flexDirection="column" justifyContent="flex-end">
        <ReactSelect
          ref={ref}
          isClearable
          options={options}
          styles={customStyles}
          {...inputProps}
          {...rest}
        />
        <Error>
          {errors && errors[name] && (
            <FormattedMessage
              id={errors[name]?.message}
              defaultMessage="* This field is required"
            />
          )}
        </Error>
      </Flex>
    </Flex>
  );
};

export { FormSelect };
