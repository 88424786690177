import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { Input } from "@components/Input";
import { TFormInput } from "@components/Input/types";
import { StyledError as Error } from "@components/Error";
import { StyledWrapper as Wrapper } from "@components/Input/styles";

const FormInput: FC<TFormInput> = props => {
  const { name, errors, wrapperProps, width = "100%", hidden, ...rest } = props;

  return (
    <Wrapper pl="15px" pr="15px" hidden={hidden} {...wrapperProps}>
      <Input name={name} width={width} errors={errors} hidden={hidden} {...rest} />
      <Error>
        {errors &&
          errors[name] &&
          (errors[name]?.type === "custom" ? (
            errors[name]?.message
          ) : (
            <FormattedMessage
              id={errors[name]?.message}
              defaultMessage="* This field is required"
            />
          ))}
      </Error>
    </Wrapper>
  );
};

export { FormInput };
