import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";

import { Checkbox } from "@components/Checkbox";
import { Text } from "@components/Text";
import { ReactComponent as IconEdit } from "../../../assets/icons/edit.svg";
import { ReactComponent as IconTrash } from "../../../assets/icons/trash.svg";
import {
  activateMaintenanceModeDatabase,
  cleanUpDatabase,
  deactivateMaintenanceModeDatabase,
  getMaintenanceMode,
  updateDatabase,
} from "./ducks";

import "../../../styles/index.scss";
import { useFormatMessage } from "@hooks/formatMessage";
import { ListContainer } from "@components/ListContainer";
import { Card } from "@components/Card";
import { useSwal } from "@hooks/useSwal";

// Modal.setAppElement('#root');

const Databases: FC = () => {
  const [deleteTempFiles, setDeleteTempFiles] = useState(false);
  const [deleteIncompleteFiles, setDeleteIncompleteFiles] = useState(false);
  const [clearSession, setClearSession] = useState(false);
  const [inMaintenanceMode, setInMaintenanceMode] = useState(false);
  // Swal
  const { swalAlert, swalConfirmationMessage } = useSwal();
  const formatMessage = useFormatMessage();

  const handleChangeDeleteTempFiles = () => {
    setDeleteTempFiles(prev => !prev);
  };

  const handleChangeDeleteIncompleteFiles = () => {
    setDeleteIncompleteFiles(prev => !prev);
  };

  const handleChangeClearSession = () => {
    setClearSession(prev => !prev);
  };

  useEffect(() => {
    getMaintenanceMode()
      .then(res => {
        if (res) setInMaintenanceMode(Boolean(res));
      })
      .catch(() => {
        setInMaintenanceMode(false);
      });
  }, []);

  const successfullMessage = (message: string) => {
    swalAlert({
      text: `${message}`,
      type: "success",
      confirmationText: "Ok!",
    });
  };

  const update = () => {
    swalConfirmationMessage({
      text: "Do you really want update database?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Update Database",
    }).then(value => {
      if (value) {
        updateDatabase().then(res => {
          if (res.success) successfullMessage("Database updated!");
        });
      }
    });
  };

  const clean = () => {
    if (deleteTempFiles || deleteIncompleteFiles || clearSession) {
      swalConfirmationMessage({
        text: "Do you really want update database?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Update Database",
      }).then(value => {
        if (value) {
          cleanUpDatabase(deleteTempFiles, deleteIncompleteFiles, clearSession).then(res => {
            if (res.success) successfullMessage("Database cleaned!");
          });
        }
      });
    } else {
      successfullMessage("Select what to clean.");
    }
  };

  const deactivateMaintenance = () => {
    swalConfirmationMessage({
      text: "Do you really want deactivate maintenance mode?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Deactivate Maintenance Mode",
    }).then(value => {
      if (value) {
        deactivateMaintenanceModeDatabase().then(res => {
          if (res.success) {
            setInMaintenanceMode(false);
            successfullMessage("Maintenance mode deactivated!");
          }
        });
      }
    });
  };

  const activateMaintenance = () => {
    swalConfirmationMessage({
      text: "Do you really want activate maintenance mode?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Activate Maintenance Mode",
    }).then(value => {
      if (value) {
        activateMaintenanceModeDatabase().then(res => {
          if (res.success) {
            setInMaintenanceMode(true);
            successfullMessage("Maintenance mode activated!");
          }
        });
      }
    });
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <div className="content-container">
          <Card>
            <Flex flexDirection="column">
              <Flex>
                <Text>
                  {formatMessage(
                    "app.updateDatabaseInformation",
                    "Starts database with some informations creating or updating."
                  )}
                </Text>
              </Flex>
              <Flex mt="15px">
                <div
                  data-cy="updateDatabase"
                  className="modal-sub-button primary"
                  title="Update Database"
                  onClick={update}
                >
                  <div className="iconWrapper">
                    <IconEdit />
                  </div>
                  <span className="d-none d-sm-inline buttonExplanation text-uppercase ml-4">
                    {formatMessage("app.updateDatabase", "Update Database")}
                  </span>
                </div>
              </Flex>
            </Flex>
          </Card>
          <Card mt="20px">
            <Flex flexDirection="column" width="100%">
              <Flex>
                <Checkbox
                  id="deleteTempFiles"
                  name="deleteTempFiles"
                  label={formatMessage("app.deleteTempFiles", "Delete Temporary Files")}
                  wrapperProps={{ width: "30%" }}
                  checked={deleteTempFiles}
                  setChecked={handleChangeDeleteTempFiles}
                />
                <Checkbox
                  id="deleteIncompleteFiles"
                  name="deleteIncompleteFiles"
                  label={formatMessage("app.deleteIncompleteFiles", "Delete Incomplete Files")}
                  wrapperProps={{ width: "30%" }}
                  checked={deleteIncompleteFiles}
                  setChecked={handleChangeDeleteIncompleteFiles}
                />
                <Checkbox
                  id="clearSession"
                  name="clearSession"
                  label={formatMessage("app.clearSession", "Clear Session")}
                  wrapperProps={{ width: "30%" }}
                  checked={clearSession}
                  setChecked={handleChangeClearSession}
                />
              </Flex>
              <Flex>
                <div
                  data-cy="updateDatabase"
                  className="modal-sub-button primary"
                  title="Update Database"
                  onClick={clean}
                >
                  <div className="iconWrapper">
                    <IconTrash />
                  </div>
                  <span className="d-none d-sm-inline buttonExplanation text-uppercase ml-4">
                    {formatMessage("app.cleanDatabase", "Clean Database")}
                  </span>
                </div>
              </Flex>
            </Flex>
          </Card>
          <Card mt="20px">
            <Flex flexDirection="column">
              {inMaintenanceMode ? (
                <Flex>
                  <div
                    data-cy="deactivateMaintenance"
                    className="modal-sub-button primary"
                    title="Deactivate Maintenance Mode"
                    onClick={deactivateMaintenance}
                  >
                    <div className="iconWrapper">
                      <IconEdit />
                    </div>
                    <span className="d-none d-sm-inline buttonExplanation text-uppercase ml-4">
                      {formatMessage("app.deactivateMaintenance", "Deactivate Maintenance Mode")}
                    </span>
                  </div>
                </Flex>
              ) : (
                <Flex>
                  <div
                    data-cy="activateMaintenance"
                    className="modal-sub-button primary"
                    title="Activate Maintenance Mode"
                    onClick={activateMaintenance}
                  >
                    <div className="iconWrapper">
                      <IconEdit />
                    </div>
                    <span className="d-none d-sm-inline buttonExplanation text-uppercase ml-4">
                      {formatMessage("app.activateMaintenance", "Activate Maintenance Mode")}
                    </span>
                  </div>
                </Flex>
              )}
            </Flex>
          </Card>
        </div>
      </Flex>
    </ListContainer>
  );
};

export default Databases;
