import http from "../../../api/http";
import { TWebServicesState } from "./types";
import { TDispatch } from "../../../redux/types";
import * as constants from "../../../../src/constants";
import { buildFilterQueryString, deserialize, serialize } from "../../../api/jsonApiParser";
import { Dispatch } from "redux";
import { TResponse } from "@api/types";

export const FETCHED_WEBSERVICES = "FETCHED_WEBSERVICES";
export const SET_FILTERING_CRITERIA_WEBSERVICES = "SET_FILTERING_CRITERIA_WEBSERVICES";
export const SET_WEBSERVICES_PAGE_NO = "SET_WEBSERVICES_PAGE_NO";
export const INCREMENT_PAGE_NO = "INCREMENT_PAGE_NO";

const initialState = {
  webServices: [],
  webServicesPageNo: 1,
  moreWebServices: true,
  filter: undefined,
};

const WebServicesReducer = (state: TWebServicesState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_WEBSERVICES: {
      let webServices = [];
      const moreWebServices = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.webServicesPageNo === 1 || action.page === 1) {
        webServices = [...action.payload];
      } else {
        webServices = [...state.webServices, ...action.payload];
      }

      return { ...state, webServices, moreWebServices: moreWebServices };
    }

    case SET_WEBSERVICES_PAGE_NO: {
      const moreWebServices = !(
        state.webServices.length <
        (state.webServicesPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, webServicesPageNo: action.payload, moreWebServices: moreWebServices };
    }

    case INCREMENT_PAGE_NO: {
      return {
        ...state,
        webServicesPageNo: state.webServicesPageNo + 1,
      };
    }

    case SET_FILTERING_CRITERIA_WEBSERVICES: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }
  }
  return state;
};

export const setWebservicesPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_WEBSERVICES_PAGE_NO,
      payload,
    });
  };
};

export const fetchWebServicesToDropdown = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http.get("WebService/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: constants.LOADING, payload: false });
    return response.data.map(user => deserialize(user));
  });
};

export const fetchWebServices = (page = 1, pageSize = 10, filter?) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `WebService/WebServicePaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: constants.FETCHED_WEBSERVICES,
          payload: response.data.map(connection => deserialize(connection)),
          page: page,
        });

        dispatch({ type: INCREMENT_PAGE_NO });

        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};

export const createWebservice = payload => {
  return http.post("WebService/", serialize(payload, "WebService"));
};

export const changeWebservice = (id: string, payload) => {
  return http.patch("WebService/" + id, payload);
};

export const deleteWebservice = (id: string) => {
  return http.delete("WebService/" + id);
};

export const changePasswordWebservice = (id: string, password: string) => {
  const info = { id: id, password: password };
  return http.post("WebService/ChangePassword", serialize(info, "ChangePassword"));
};

export const getWebserviceById = (id: string) => {
  return http.get("WebService/" + id);
};

export { WebServicesReducer };
