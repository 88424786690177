import { Dispatch } from "redux";

import { TResponse } from "@api/types";
import { buildFilterQueryString, deserialize, serialize } from "@api/jsonApiParser";

import http from "../../api/http";
import { TCompaniesState } from "./types";
import { LOADING, PAGINATION_SIZE } from "../../constants";

export const FETCHED_COMPANIES = "FETCHED_COMPANIES";
export const COMPANIES_IS_LOADING = "COMPANIES_IS_LOADING";
export const SET_COMPANIES_PAGE_NO = "SET_COMPANIES_PAGE_NO";
export const INCREMENT_COMPANIES_PAGE_NO = "INCREMENT_COMPANIES_PAGE_NO";
export const SET_FILTERING_CRITERIA_COMPANIES = "SET_FILTERING_CRITERIA_COMPANIES";

const initialState = {
  companies: [],
  companiesPageNo: 1,
  moreCompanies: true,
  filter: undefined,
  companiesIsLoading: false,
};

export const CompaniesReducer = (state: TCompaniesState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case FETCHED_COMPANIES: {
      const { data, page } = payload;

      let companies = [];
      const moreCompanies = !(data.length < PAGINATION_SIZE);

      if (state.companiesPageNo === 1 || page === 1) {
        companies = [...data];
      } else {
        companies = [...state.companies, ...data];
      }

      return { ...state, companies: companies, moreCompanies: moreCompanies };
    }

    case SET_COMPANIES_PAGE_NO: {
      const moreCompanies = !(
        state.companies.length <
        (state.companiesPageNo + 1) * PAGINATION_SIZE
      );
      return { ...state, companiesPageNo: payload, moreCompanies: moreCompanies };
    }

    case SET_FILTERING_CRITERIA_COMPANIES: {
      if (payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: payload.value };
      }
    }

    case INCREMENT_COMPANIES_PAGE_NO: {
      return {
        ...state,
        companiesPageNo: state.companiesPageNo + 1,
      };
    }

    case COMPANIES_IS_LOADING: {
      return {
        ...state,
        companiesIsLoading: payload,
      };
    }
  }
  return state;
};

export const setCompaniesPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_COMPANIES_PAGE_NO,
      payload,
    });
  };
};

export const fetchCompanyList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });
    const query = `Company/CompanyPaginated?Page=${page}&PageSize=${pageSize}`;

    await http.get(filter ? buildFilterQueryString(filter, query) : query).then((response: any) => {
      dispatch({
        type: FETCHED_COMPANIES,
        payload: { data: response.data.map(connection => deserialize(connection)), page },
      });

      dispatch({
        type: INCREMENT_COMPANIES_PAGE_NO,
      });

      dispatch({ type: LOADING, payload: false });
    });
  };
};

export const createCompany = (payload): Promise<TResponse> => {
  return http.post("Company/", serialize(payload, "Company"));
};

export const changeCompany = (id: string, payload): Promise<TResponse> => {
  return http.put("Company/" + id, serialize(payload, "Company"));
};

export const deleteCompany = (id: string): Promise<TResponse> => {
  return http.delete("Company/" + id);
};

export const getCompanyById = (id: string): Promise<TResponse> => {
  return http.get("Company/" + id);
};

export const fetchCompanies = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: COMPANIES_IS_LOADING, payload: true });

  return await http.get("Company/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: COMPANIES_IS_LOADING, payload: false });
    return response.data.map(user => deserialize(user));
  });
};
