import { colors } from "@styles/colors";

const customStyles = {
  control: provided => ({
    ...provided,
    height: "50px",
    borderRadius: 0,
    backgroundColor: "transparent",
    border: "none",
    borderBottom: `2px solid ${colors.grey}`,
    WebkitBoxShadow: "none",
    boxShadow: "none",
    "&:hover, &:focus": {
      WebkitBoxShadow: "none",
      boxShadow: "none",
      border: "none",
      borderBottom: `2px solid ${colors.blueLight}`,
    },
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: colors.blueLight,
  }),
  container: provided => ({
    ...provided,
    fontSize: "16px",
  }),
  option: provided => ({
    ...provided,
    padding: "5px 5px",
  }),
};

export { customStyles };
