import * as React from "react";
import classNames from "classnames";

import { IInputConfig } from "../index";
import { DropdownOption } from "./DropdownOption";
import { defined } from "../../../utils/variableEvaluation";
import { ReactComponent as IconArrow } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as IconClose } from "../../../assets/icons/x-dark.svg";
import styles from "./DropdownInput.module.scss"; // Import css modules stylesheet as styles
import "./DropdownInput.scss";

const backendPagination = process.env.REACT_APP_BACKEND_PAGINATION;

interface IProps {
  dropdownOptions?: IInputConfig[];
  name?: string;
  value?: string[] | string | number;
  isDisabled: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  onOpen?: (isOpen: boolean) => void;
  onSelected?: (name: string, value: string) => void;
  onBlur?: any;
  filtering?: boolean;
  isOpen?: any;
  onFocus?: any;
}

interface IState {
  isOpen: boolean;
  currentlyActiveIndex: number;
  optionSelected: string;
}

export class DropdownInput extends React.Component<IProps, IState> {
  public constructor(props?: any, context?: any) {
    super(props, context);
    this.state = {
      isOpen: false,
      optionSelected: "",
      currentlyActiveIndex: -1,
    };
  }

  private listRef = null;

  componentDidMount() {
    window.addEventListener("click", this.handleClickWindow);
    const options = this.props.dropdownOptions;
    if (!this.props.filtering && options.length === 1) {
      this.setState({
        isOpen: false,
        currentlyActiveIndex: parseInt(options[0].id),
        optionSelected: options[0].dropdownLabel,
      });
      this.props.onSelected(this.props.name, options[0].id);
    }
    // window.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickWindow);
    // window.removeEventListener('keydown', this.handleKeyPress);
  }

  selectOption = idx => {
    this.setState({ currentlyActiveIndex: idx });
    if (this.listRef.children && this.listRef.children[idx]) {
      this.listRef.children[idx].scrollIntoView({ block: "end", behavior: "smooth" });
    }
    // this.listRef.childNodes[idx].classList.add('active');
  };

  private handleKeyPress = event => {
    event.stopPropagation();
    const code = event.keyCode || event.charCode;
    if (!this.props.readOnly) {
      if (code === 40) {
        // arrow down
        if (this.state.currentlyActiveIndex < this.props.dropdownOptions.length - 1) {
          if (this.state.currentlyActiveIndex < 0) {
            this.selectOption(0);
          } else {
            this.selectOption(this.state.currentlyActiveIndex + 1);
          }
        }
        if (!this.state.isOpen) {
          this.setState({ isOpen: true });
        }
      } else if (code === 38 && this.state.currentlyActiveIndex > 0) {
        // arrow up
        this.selectOption(this.state.currentlyActiveIndex - 1);
      } else if (code === 27) {
        // escape
        this.setState({ isOpen: false });
      } else if (code === 13) {
        event.preventDefault();
        // enter
        // console.log("currentlyActiveIndex", this.state.currentlyActiveIndex, this.props.dropdownOptions[this.state.currentlyActiveIndex]['dropdownLabel']);
        if (this.state.currentlyActiveIndex >= 0) {
          this.handleOptionClick(
            this.props.dropdownOptions[this.state.currentlyActiveIndex].dropdownLabel,
            this.state.currentlyActiveIndex
          );
        }
        this.setState({ isOpen: !this.state.isOpen });
      } else if (code === 9) {
        this.setState({ isOpen: false });
      }
    }
  };

  private handleClickWindow = () => {
    this.setState({ isOpen: false });
  };

  private handleWrapperClick = () => {
    const isDisabled = defined(this.props.isDisabled) && this.props.isDisabled;

    if (!this.props.readOnly) {
      if (!isDisabled) {
        // const nextIsOpen = !this.state.isOpen;
        this.setState({ isOpen: true });
        this.props.onOpen && this.props.onOpen(!this.state.isOpen);
      }
    }
    // e.stopPropagation();
  };

  private handleOptionClick = (value: string, idx: number, e?) => {
    e && e.stopPropagation();
    if (!this.props.readOnly) {
      this.setState({ optionSelected: value, currentlyActiveIndex: idx, isOpen: false });
      let idVal = "";
      if (backendPagination === "true" && this.props.filtering) {
        idVal = idx >= 0 ? this.props.dropdownOptions[idx].dropdownLabel : "";
      } else {
        idVal = idx >= 0 ? this.props.dropdownOptions[idx].id : "";
      }
      // this.props.onChange(this.props.name, value, idVal);
      this.props.onSelected(this.props.name, idVal);
    }
  };

  private handleFocus = () => {
    // console.log("handleFocus");
    setTimeout(() => {
      if (!this.props.readOnly) {
        this.setState({ isOpen: true });
      }
      this.props.onFocus && this.props.onFocus();
    }, 150);
  };

  private handleBlur = () => {
    setTimeout(() => {
      if (this.props.value !== "" || this.props.clearable === false) {
        const value = this.props.dropdownOptions.filter(option => {
          return RegExp(this.props.value ? this.props.value.toString() : "", "i").test(
            option.dropdownLabel
          );
        });
        if (value.length > 0) {
          this.setState({
            isOpen: false,
            currentlyActiveIndex: parseInt(value[0].id),
            optionSelected: value[0].dropdownLabel,
          });
          if (backendPagination === "true" && this.props.filtering) {
            this.props.onSelected(this.props.name, value[0].dropdownLabel);
          } else {
            this.props.onSelected(this.props.name, value[0].id);
          }
        } else {
          this.setState({ isOpen: false, currentlyActiveIndex: -1, optionSelected: "" });
          this.props.onSelected(this.props.name, "");
        }
      } else if (this.props.value !== "") {
        this.setState({ isOpen: false, currentlyActiveIndex: -1, optionSelected: "" });
        this.props.onSelected(this.props.name, "");
      }
    }, 150);
  };

  // private handleTextInputChange = (value) => {
  //     console.log(value);
  // }

  private renderChildren(child, i): JSX.Element {
    return React.cloneElement(child, {
      key: `InputText ${i}`,
      value: this.props.value,
      // onChange: (value) => this.handleTextInputChange(value)
      /* || this.state.optionSelected */
    });
  }

  handleArrowClick(e) {
    // console.log("arrow clicked");
    e.stopPropagation();

    const isOpen = this.state.isOpen;
    if (!this.props.readOnly) {
      this.setState({ isOpen: !isOpen });
      this.props.onOpen && this.props.onOpen(!isOpen);
    }
  }

  render() {
    const { children, dropdownOptions, value } = this.props;

    const options = dropdownOptions.map((input: IInputConfig, idx) => {
      // If an option was selected, don't filter
      let regexMatch;
      const selected = dropdownOptions.find(option => option.dropdownLabel === value);
      if (this.state.optionSelected !== value && value !== "" && !selected) {
        regexMatch = RegExp(this.props.value ? this.props.value.toString() : "", "i").test(
          input.dropdownLabel
        );
      } else {
        regexMatch = true;
      }
      return (
        regexMatch && (
          <DropdownOption
            key={input.id}
            id={input.id}
            dropdownLabel={input.dropdownLabel}
            selected={idx === this.state.currentlyActiveIndex}
            onOptionClick={(label, e) => this.handleOptionClick(label, idx, e)}
          />
        )
      );
    });
    return (
      <div
        className={`dropdown ${styles.inputSelectWrapper} ${
          (defined(this.props.isOpen) && this.props.isOpen && this.state.isOpen) ||
          (!defined(this.props.isOpen) && this.state.isOpen)
            ? styles.isOpen
            : ""
        }`}
        onClick={this.handleWrapperClick}
        onKeyDown={e => this.handleKeyPress(e)}
        onFocus={this.handleFocus}
        onBlur={this.props.name !== "languageInput" && this.handleBlur}
      >
        {React.Children.map(children, (child, i) => this.renderChildren(child, i))}
        {this.props.value &&
          (this.props.clearable === false ? null : (
            <div
              data-cy="cancelDropdown"
              className={styles.clearIcon}
              onClick={e => {
                e.stopPropagation();
                this.handleOptionClick("", -1);
              }}
            >
              <IconClose className="strokeGrey" />
            </div>
          ))}
        <div
          className={classNames("inputSelectArrow", styles.inputSelectArrow)}
          onClick={e => this.handleArrowClick(e)}
        >
          <IconArrow />
        </div>
        <div
          ref={div => {
            this.listRef = div;
          }}
          className={styles.inputSelectOptionsWrapper + " selectOptionsWrapper"}
        >
          {options}
        </div>
      </div>
    );
  }
}
