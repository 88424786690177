import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { THeaderProps } from "@components/Header/types";
import { useFormatMessage } from "@hooks/formatMessage";
import { ReactComponent as IconLogout } from "@assets/icons/logout.svg";

import { OldInput } from "../OldInput";
import { TRootState } from "../../redux/types";
import { Button, EButtonType } from "../OldButton";
import { DropdownCollapse } from "../DropdownCollapse";
import { logOut } from "../../shared/state/AuthenticateActions";

import "./Header.scss";

const Header: FC<THeaderProps> = props => {
  const { title, children } = props;

  const userData = useSelector(({ LoginReducer }: TRootState) => LoginReducer.userData);
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();

  return (
    <header className="header-container">
      <div className="headerTitle">{title}</div>
      <div className="headerContent">{children}</div>
      <DropdownCollapse
        label={userData && userData.firstName ? userData.firstName + " " + userData.lastName : ""}
      >
        <h2 className="dropdownContent">
          {userData && userData.firstName ? userData.firstName : ""}
        </h2>
        <p className="email dropdownContent">{userData && userData.email ? userData.email : ""}</p>
        <OldInput
          id="languageInput"
          name="languageInput"
          inputWrapperClass="dropdownContent"
          inputWrapperStyle={{
            width: "80px",
            marginLeft: "auto",
          }}
          label="Language"
          clearable={false}
          onSelected={() => null}
          value={null}
          selectOptions={[
            {
              dropdownLabel: "EN",
              id: "en",
              value: "en",
            },
            {
              dropdownLabel: "DE",
              id: "de",
              value: "de",
            },
          ]}
          type="text"
        />
        <Button
          className="red outline full-width dropdownContent mt-5"
          onClick={() => dispatch(logOut())}
          to={null}
          buttonType={EButtonType.Button}
        >
          {formatMessage("app.logout", "Sign Out")}
          <div className="iconWrapper small ml-4" style={{ height: "20px", marginTop: "-5px" }}>
            <IconLogout className="" />
          </div>
        </Button>
      </DropdownCollapse>
    </header>
  );
};

export default Header;
