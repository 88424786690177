import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { useDispatch } from "react-redux";

import {
  Button,
  TextButton,
  Form,
  FormInput as Input,
  FormSelect as Select,
  FormCheckbox as Checkbox,
} from "@icr/react.uicomponents";

import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { createConnection, changeConnection } from "../ducks";
import { fetchWebServicesToDropdown } from "../../WebServices/ducks";
import { fetchTenantsToDropdown } from "../../../Tenant/Tenant/ducks";

import { TProps } from "./types";
import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

import { setLocale } from "yup";
import { ConnectionSchema } from "./schema";
import { fetchDatabaseTypes } from "actions/enumerator";
import { TDispatch } from "../../../../redux/types";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ConnectionsModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;

  const [isEditing, setIsEditing] = useState<boolean>();
  const [databaseTypeList, setDatabaseTypeList] = useState<Record<string, unknown>[]>();
  const [webServiceList, setWebServiceList] = useState<Record<string, unknown>[]>();
  const [tenantList, setTenantList] = useState<Record<string, unknown>[]>();

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  // Fetch lists
  useEffect(() => {
    dispatch(fetchDatabaseTypes()).then(ret => setDatabaseTypeList(ret));
    dispatch(fetchWebServicesToDropdown()).then(ret => setWebServiceList(ret));
    dispatch(fetchTenantsToDropdown()).then(ret => setTenantList(ret));
  }, [dispatch]);

  const showSuccessMessage = action => {
    swalAlertWithCallback(
      {
        text: `Connection has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const connection = {
      ...formData,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
      webServiceId: formData.webServiceId ? formData.webServiceId.value : undefined,
      databaseType: formData.databaseType ? formData.databaseType.value : undefined,
    };

    if (isEditing) {
      // Confirm actionn
      swalConfirmationMessage({
        text: "Do you really want to change this application?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          // Create object to send to api
          const connectionToEdit = Object.keys(connection).map(key => {
            return {
              op: "replace",
              path: `/${[key]}`,
              value: connection[key],
            };
          });

          changeConnection(formData.id, connectionToEdit).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      // Confirm action
      swalConfirmationMessage({
        text: "Do you really want to create this application?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Create new Connection",
      }).then(value => {
        if (value) {
          delete connection.id;

          createConnection(connection).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={ConnectionSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="tenantId"
              name="tenantId"
              label={formatMessage("app.tenant", "Tenant")}
              options={tenantList?.map(option => ({
                value: option.id,
                label: option.tenantName,
              }))}
            />
            <Select
              id="webServiceId"
              name="webServiceId"
              label={formatMessage("app.webService", "Web Service")}
              options={webServiceList?.map(option => ({
                value: option.id,
                label: option.webserviceName,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="databaseType"
              name="databaseType"
              label={formatMessage("app.databaseType", "Database Type")}
              options={databaseTypeList?.map(option => ({
                value: +option.id,
                label: option.description,
              }))}
            />
            <Input
              id="schemaDb"
              name="schemaDb"
              label={formatMessage("app.schemaDb", "Schema Db")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="hostName"
              name="hostName"
              label={formatMessage("app.hostName", "Host Name")}
            />
            <Input id="port" name="port" label={formatMessage("app.port", "Port")} />
            <Checkbox
              id="showSql"
              name="showSql"
              label={formatMessage("app.showSql", "Show SQL")}
              wrapperProps={{ width: "47%" }}
            />
            <Checkbox
              id="isActive"
              name="isActive"
              label={formatMessage("app.isActive", "Is Active")}
              wrapperProps={{ width: "47%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Input id="dbName" name="dbName" label={formatMessage("app.dbName", "Database Name")} />
            <Input
              id="username"
              name="username"
              label={formatMessage("app.username", "Username")}
            />
          </Flex>
          {!isEditing && (
            <Flex mt="30px">
              <Input
                id="password"
                name="password"
                type="password"
                label={formatMessage("app.password", "Password")}
              />
              <Input
                id="passwordrepeat"
                name="passwordrepeat"
                type="password"
                label={formatMessage("app.passwordRepeat", "Repeat Password")}
              />
            </Flex>
          )}

          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default ConnectionsModal;
