import styled from "@emotion/styled";

import { colors } from "@styles/colors";
import { THeaderText } from "@components/HeaderText/types";

const HeaderText = styled.div<THeaderText>`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ active }) => (active ? colors.primary : colors.grey)};
`;

export { HeaderText };
