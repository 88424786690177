import * as constants from "../constants";
import de from "../constants/locales/de.json";
import en from "../constants/locales/en.json";
import fr from "../constants/locales/fr.json";
import it from "../constants/locales/it.json";

const getInitialState = () => {
  return {
    en: en,
    it: it,
    de: de,
    fr: fr,
  };
};

const localesReducer = (state: any = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.UPDATE_LOCALES: {
      return { ...state, ...action.payload };
    }
  }
  return state;
};

export default localesReducer;
