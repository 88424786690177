import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { StyledError as Error } from "@components/Error";
import { StyledLabel as Label } from "@components/Label";
import { StyledWrapper as Wrapper } from "@components/Input/styles";

import { TTextArea } from "@components/Form/TextArea/types";

const TextArea: FC<TTextArea> = props => {
  const {
    name,
    errors,
    wrapperProps,
    hidden,
    cols = 50,
    rows = 5,
    label,
    register,
    setValue,
    ...rest
  } = props;

  return (
    <Wrapper pl="15px" pr="15px" hidden={hidden} {...wrapperProps}>
      {label && <Label>{label}</Label>}
      <textarea
        {...rest}
        name={name}
        cols={cols}
        rows={rows}
        ref={register}
        onChange={e => setValue(name, e.target.value)}
      />
      <Error>
        {errors &&
          errors[name] &&
          (errors[name]?.type === "custom" ? (
            errors[name]?.message
          ) : (
            <FormattedMessage
              id={errors[name]?.message}
              defaultMessage="* This field is required"
            />
          ))}
      </Error>
    </Wrapper>
  );
};

export { TextArea };
