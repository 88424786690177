import { Cookies } from "react-cookie";
import { push } from "connected-react-router";

import http from "@api/http";
import apiCaller from "@api/apiHandler";
import { serialize } from "@api/jsonApiParser";

import { store } from "../../redux/store";
import { LOGIN_SUCCESS } from "../../constants";
import { TResponse } from "@api/types";
import { TSMSTokenValidation } from "@features/LoginToken/modal/types";

const apiUrl = process.env.REACT_APP_REST_API_URL;

export const generateEmailTokenValidation = payload => {
  return http.post("Authenticate/GenerateEmailTokenValidation", payload);
};

export const generateSMSTokenValidation = payload => {
  return http
    .post(apiUrl + "Authenticate/GenerateSMSTokenValidation", serialize(payload, "LoginToken"))
    .then((ret: TResponse<TSMSTokenValidation>) => ret);
};

export const validateSMSToken = payload => {
  const cookies = new Cookies();
  const headers = {
    "Content-Type": "application/json",
  };
  return apiCaller("POST", "Authenticate/ValidateSMSToken", payload, headers).then(
    (response: any) => {
      if (response.message === "Success") {
        const bearerToken = Object.prototype.hasOwnProperty.call(response, "token")
          ? response.token
          : null;
        cookies.set("bearerToken", bearerToken, {});
        store.dispatch({ type: LOGIN_SUCCESS, payload: response });
        store.dispatch(push("/users/users"));
      }
    }
  );
};
