import { Cookies } from "react-cookie";
import { TAction } from "@redux/types";

const cookies = new Cookies();

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_REFRESH_TOKEN = "LOGIN_REFRESH_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const LOGIN_IS_LOADING = "LOGIN_IS_LOADING";

export type LoginState = {
  authentication: any;
  userData: any;
  errors: Array<any>;
  isLoading: boolean;
};

const initialState = {
  authentication: {
    login: null,
    token: cookies.get("bearerToken"),
  },
  userData: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {},
  errors: [],
  isLoading: false,
};

const LoginReducer = (state: LoginState = initialState, action: TAction): LoginState => {
  const { payload, error, type } = action;

  switch (type) {
    case LOGIN: {
      // console.log("reducer login start", action.payload);
      return {
        ...state,
        authentication: {
          name: payload.login,
          rememberMe: payload.rememberMe,
          token: payload.token,
          step: payload.step,
        },
      };
    }

    case LOGIN_SUCCESS: {
      const { data, step, token } = payload;

      const userData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
      };

      const authentication = {
        ...state.authentication,
        token,
        step,
      };

      window.localStorage.setItem("userData", JSON.stringify(userData));
      return { ...state, authentication, userData };
    }

    case LOG_OUT: {
      cookies.remove("bearerToken");
      window.localStorage.removeItem("userData");
      // return { ...state, authentication: {login: null, token: null, expirationDate: null}, userData: null };
      return { ...initialState };
    }

    case LOGIN_ERROR: {
      // @TODO better handle state change when error
      return {
        ...state,
        authentication: initialState.authentication,
        errors: [error],
        userData: null,
      };
    }

    case LOGIN_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
  }

  return state;
};

export { LoginReducer };
