import * as React from "react";

import { InputText } from "../Text";
import { InputRadio } from "../Radio";
import { InputCheckbox } from "../Checkbox";
import { InputSelect } from "../Select/input";
import { renderSwitch } from "../../../utils/react";
import { IInputConfig, EInputTextType } from "../index";
import { defined } from "../../../utils/variableEvaluation";

import { InputFile } from "../File";
import { Textarea } from "../Textarea";

export enum EInputType {
  Textarea = "Textarea",
  File = "File",
  Text = "Text",
  Checkbox = "Checkbox",
  Radio = "Radio",
  Select = "Select",
  Custom = "Custom",
}

interface IProps {
  inputConfig: IInputConfig;
}

export class InputContent extends React.Component<IProps, unknown> {
  private inputType(): EInputType {
    const { type, selectOptions, radioOptions, customComponent } = this.props.inputConfig;
    if (type === "textarea") {
      return EInputType.Textarea;
    } else if (type === "file") {
      return EInputType.File;
    } else if (type === "checkbox") {
      return EInputType.Checkbox;
    } else if (defined(selectOptions)) {
      return EInputType.Select;
    } else if (defined(radioOptions)) {
      return EInputType.Radio;
    } else if (defined(customComponent)) {
      return EInputType.Custom;
    } else {
      return EInputType.Text;
    }
  }

  public render(): JSX.Element {
    const {
      checkboxLabel,
      checked,
      clearable,
      customComponent,
      disabled,
      forcedValue,
      inputTextType,
      isFocused,
      isLoading,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      onDropdownOpen,
      onSelected,
      placeholder,
      radioOptions,
      readOnly,
      selectOptions,
      step,
      type,
      value,
      filtering,
      id,
      cols,
      rows,
      isOpen,
      onFocus,
    } = this.props.inputConfig;

    return renderSwitch(this.inputType(), {
      [EInputType.Custom]: () => customComponent!,
      [EInputType.Checkbox]: () => (
        <InputCheckbox
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          checked={checked}
          readOnly={readOnly}
          label={checkboxLabel}
          type={type!}
          isDisabled={disabled}
          onChange={onChange!}
        />
      ),
      [EInputType.Radio]: () => (
        <InputRadio radioOptions={radioOptions} readOnly={readOnly} forcedValue={forcedValue} />
      ),
      [EInputType.Select]: () => (
        <InputSelect
          clearable={clearable}
          inputTextType={inputTextType || EInputTextType.Material}
          isDisabled={disabled}
          isFocused={isFocused}
          isLoading={isLoading}
          name={name!}
          onBlur={onBlur}
          onChange={onChange}
          onDropdownOpen={onDropdownOpen!}
          onSelected={onSelected}
          placeholder={placeholder}
          readOnly={readOnly}
          selectOptions={selectOptions}
          type={type!}
          value={value}
          filtering={filtering}
          isOpen={isOpen}
          onFocus={onFocus}
        />
      ),
      [EInputType.Text]: () => (
        <InputText
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          value={value}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          isDisabled={disabled}
          inputTextType={inputTextType || EInputTextType.Material}
          onChange={onChange}
          onBlur={onBlur}
          filtering={filtering}
        />
      ),
      [EInputType.File]: () => (
        <InputFile
          id={id}
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          isDisabled={disabled}
          onChange={onChange}
        />
      ),
      [EInputType.Textarea]: () => (
        <Textarea
          id={id}
          isFocused={isFocused}
          isLoading={isLoading}
          name={name}
          value={value}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          isDisabled={disabled}
          onChange={onChange}
          cols={cols}
          rows={rows}
        />
      ),
    });
  }
}
