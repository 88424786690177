import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  ListContainer,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";

import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import { fetchEmailLogList, setEmailLogsPageNo } from "./ducks";

const EmailLogs: FC = () => {
  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { emailLogs, emailLogsPageNo, moreEmailLogs } = useSelector(
    ({ EmailLogsReducer: { emailLogs, emailLogsPageNo, moreEmailLogs } }: TRootState) => ({
      emailLogs,
      emailLogsPageNo,
      moreEmailLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchEmailLogList());
  }, [dispatch]);

  const handleScroll = () => {
    if (moreEmailLogs) {
      dispatch(
        fetchEmailLogList(emailLogsPageNo, 10, {
          GenericText: genericTextFilter,
        })
      );
    }
  };

  const filterEmailLogs = () => {
    dispatch(setEmailLogsPageNo(1)).then(() => {
      dispatch(
        fetchEmailLogList(1, 10, {
          GenericText: genericTextFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setEmailLogsPageNo(1)).then(() => {
      dispatch(fetchEmailLogList(1, 10));
    });
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <ScrollableList
            items={emailLogs}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.emailStatusName", "Status")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.timestamp", "Date")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.recipient", "Recipient")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.subject", "Subject")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"userRow" + index}
                id={item.id as string}
                expandable={true}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.emailStatusName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.timestamp}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.recipient}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.subject}</Text>
                    </ListColumn>
                    <ListColumn icon></ListColumn>
                  </Flex>
                }
                more={
                  <Flex width="100%" mb="40px">
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.message", "Message")}
                      </Text>
                      <Text mt="10px">{item.message}</Text>
                    </ListColumn>
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.text", "Text")}
                      </Text>
                      <Text mt="10px">{item.text}</Text>
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Flex>
          <Filter
            id="filterArea"
            searchButtonText={formatMessage("app.search", "Search")}
            onClickSearchButton={filterEmailLogs}
            clearButtonText={formatMessage("app.clear", "Clear")}
            onClickClearButton={clearFilters}
            open={true}
          >
            <Input
              id="dynamicSearch"
              label="Dynamic Filter"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={genericTextFilter}
              onChange={e => setGenericTextFilter(e.target.value)}
            />
          </Filter>
        </Flex>
      </Flex>
    </ListContainer>
  );
};

export default EmailLogs;
