import styled from "@emotion/styled";

import { TColumn, TExpandable } from "@components/ExpandableRow/types";
import { Flex } from "reflexbox";

const sizes = {
  1: "100%",
  2: "80%,",
  3: "30%",
};

const StyledExpandableRow = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 18px 60px -30px rgba(1, 50, 62, 0.2);

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
`;

const Top = styled.div`
  display: flex;
  height: 72px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 18px 60px -30px rgba(1, 50, 62, 0.2);
  vertical-align: middle;
  padding: 0 32px;
  align-items: center;
`;

const Column = styled.div<TColumn>`
  flex-basis: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    margin-right: 5px;
  }

  &:not(.column-icon):not(:first-child):not(:last-child) {
    margin: 0 5px;
  }

  ${({ icon = false }) =>
    icon &&
    `
    height: auto;
    max-width: 64px;
    min-width: 34px;
    width: 34px;
    overflow: visible;
  `};

  @media (max-width: 845px) {
    flex-basis: ${({ size = 1 }) => sizes[size]};
  }

  //&.column-icon {
  //  max-width: 64px;
  //  min-width: 34px;
  //  width: 34px;
  //  overflow: visible;
  //
  //  &:not(:last-child) {
  //    margin-right: 28px;
  //  }
  //
  //  &:last-child {
  //    opacity: 0.65;
  //  }
  //}
`;

const Expandable = styled(Flex)<TExpandable>`
  max-height: 0;
  overflow: hidden;
  transition: max-height linear 0.3s;

  ${({ expanded = false }) => expanded && `max-height: 400px;`};
`;

const More = styled(Flex)`
  width: 100%;
  padding: 32px 32px 0 32px;
  border-top: 1px solid #f5f5f5;
`;

export { Top, StyledExpandableRow, Column, Expandable, More };
