import React, { FC, useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getParameterByName } from "@utils/toSearchParams";

import { TLogin } from "@features/LoginToken/types";
import { TDispatch } from "@redux/types";
import { useFormatMessage } from "@hooks/formatMessage";

import logo1x from "../../assets/imgs/logo-220w.jpg";
import logo2x from "../../assets/imgs/logo-600w.jpg";
import "./Login.scss";
import { doLogin } from "../../shared/state/AuthenticateActions";
import { passwordForgotInit, passwordReset } from "@actions/user";
import {
  LoginStep,
  ResetPasswordStep1,
  ResetPasswordStep2,
  ResetPasswordStep3,
} from "@features/Login/steps";
import { StyledContainer as Container } from "./styles";

const Login: FC<TLogin> = ({ location }) => {
  const { search } = location;
  // const [user, setUser] = useState("");
  const [passwordResetStep, setPasswordResetStep] = useState("0");
  // const [oneTimeToken, setOneTimeToken] = useState("");
  const [passwordResetToken, setPasswordResetToken] = useState("");
  // const [password, setPassword] = useState("");
  // const [showValidations, setShowValidations] = useState(false);
  // const [newPassword, setNewPassword] = useState("");

  const history = useHistory();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  useEffect(() => {
    if (search && search.length > 0) {
      // setOneTimeToken(getParameterByName("oneTimeToken", search));
      // setUser(getParameterByName("user", search));
      // setPasswordResetStep(getParameterByName("password-reset", search));
    }

    if (search === "") {
      setPasswordResetStep("0");
    } else {
      if (getParameterByName("2fa", search) === "1") {
        setPasswordResetStep("3");
      } else {
        setPasswordResetStep(getParameterByName("password-reset", search));
        setPasswordResetToken(getParameterByName("token", search));
      }
    }
  }, [search]);

  useEffect(() => {
    document.body.classList.add("login");

    return () => {
      document.body.classList.remove("login");
    };
  }, []);

  const login = formData => dispatch(doLogin(formData, history));

  const sendPasswordResetRequest = formData => {
    const { username } = formData;

    dispatch(passwordForgotInit(username)).then(res => {
      if (res === "OK") {
        history.push({
          search: "?" + new URLSearchParams({ "password-reset": "4" }).toString(),
        });
      }
    });
  };

  const setNewPassword = formData => {
    const { newPassword } = formData;

    dispatch(passwordReset(newPassword, passwordResetToken)).then(res => {
      if (res === "OK") {
        toastr.success(formatMessage("app.newpassword"), formatMessage("app.passwordwaschanged"));
        setTimeout(() => {
          history.push({
            pathname: `/login`,
            search: "",
          });
        }, 2000);
      }
    });
  };

  const resetPassword = () => {
    history.push({
      search: "?" + new URLSearchParams({ "password-reset": "1", user: "" }).toString(),
    });
  };

  return (
    <Container>
      <div className="card topCard d-block d-sm-none">
        <img alt="logo" src={logo1x} srcSet={logo1x + " 1x," + logo2x + " 2x"} />
      </div>
      <div className="row">
        {passwordResetStep === "0" && <LoginStep login={login} resetPassword={resetPassword} />}
        {passwordResetStep === "1" && (
          <ResetPasswordStep1 sendPasswordResetRequest={sendPasswordResetRequest} />
        )}
        {passwordResetStep === "2" && <ResetPasswordStep2 setNewPassword={setNewPassword} />}
        {passwordResetStep === "4" && <ResetPasswordStep3 />}
      </div>
    </Container>
  );
};

export default Login;
