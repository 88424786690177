/**
 * Util class to parse data from and to api using jsonAPI patterns
 */

export function deserialize(jsonObject) {
  return {
    id: jsonObject?.id,
    ...jsonObject?.attributes,
  };
}

export function serialize(object, type) {
  // Create object with attributes
  const attributes = {};
  // Build with actual object
  Object.keys(object).forEach(key => {
    if (key !== "id") attributes[key] = object[key];
  });
  // Build return with id and attributes
  return {
    data: {
      id: object.id,
      attributes: attributes,
      type: type,
    },
    jsonapi: {
      version: "1.0",
      meta: null,
    },
  };
}

export function buildFilterQueryString(object, query): string {
  if (!object) return query;

  // Build query based on filter object
  Object.keys(object).forEach(key => {
    if ((key === "dynamicFilter" || key === "GenericText") && object[key])
      query += `&SearchGeneric=${object[key]}`;
    else if (key && object[key] && typeof object[key] !== "boolean")
      query += `&Search=${key.replace("Filter", "")} co ${object[key]}`;
    else if (key && object[key] && typeof object[key] === "boolean")
      query += `&Search=${key.replace("Filter", "")} eq ${object[key]}`;
  });

  return query;
}

export function buildOrderQueryString(array, query): string {
  if (!array) return query;

  array.forEach(order => {
    query += "&OrderBy=" + order;
  });
  return query;
}
