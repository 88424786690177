import * as yup from "yup";

const TenantMappingsSchema = yup.object().shape({
  id: yup.string(),
  tenantId: yup.object().required().nullable(),
  type: yup.object().required().nullable(),
  value: yup.string().required(),
});

export { TenantMappingsSchema };
