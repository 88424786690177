import * as constants from "../constants";
import { toastr } from "react-redux-toastr";
import { push } from "connected-react-router";
import { LOG_OUT } from "../shared/state/AuthenticateReducers";

// Default function to wrap requests and treat errors or default behaviors
export default function requestWraper(fn) {
  return function (dispatch) {
    return fn(dispatch)
      .then(res => {
        dispatch({ type: constants.LOADING, payload: false });
        return res;
      })
      .catch(err => {
        // let jsonResponse;
        // try {
        //     jsonResponse = JSON.parse(err);
        // } catch (e) {
        //     jsonResponse = {
        //         errors: [{
        //             title: "Error",
        //             status: ""
        //         }]
        //     }
        // }
        // console.log("PARSED ERROR", jsonResponse)

        const errorList = err?.errors;
        // if(err && err.errors) errorList = err.errors;

        // Treat errors (if needs to treat list in future, do foreach)
        if (errorList && errorList.length > 0) {
          const error = errorList[0];

          // Treat unauthorized and forbidden
          // TODO: verify error code typing
          if (
            error.code === "403" ||
            error.code === "401" ||
            error.detail === "Session not found or expired."
          ) {
            dispatch({ type: LOG_OUT, payload: null });
            dispatch(push("/login"));
          } else {
            dispatch({ type: constants.LOADING, payload: false });
          }
          toastr.error(errorList[0].title, errorList[0].detail);
        }
        return err;
      });
  };
}
