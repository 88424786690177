import * as React from "react";
import classNames from "classnames";
import { defined } from "../../utils/variableEvaluation";

import styles from "./ValidationMessage.module.scss"; // Import css modules stylesheet as styles

export const EMPTY_VALIDATION_MESSAGE_CONFIG = { message: "" };

export enum EValidationMessageType {
  Standard = "standard",
  Success = "success",
  Error = "error",
}

export interface ValidationMessageProps {
  message: string;
  type?: EValidationMessageType;
}

export function ValidationMessage(props: ValidationMessageProps) {
  const isStandard = !defined(props.type) || props.type === EValidationMessageType.Standard;
  const isSuccess = props.type === EValidationMessageType.Success;
  const isError = props.type === EValidationMessageType.Error;

  const validationClass = classNames(styles.validationMessage, "validationMessage", {
    [styles[EValidationMessageType.Standard]]: isStandard,
    [styles[EValidationMessageType.Success]]: isSuccess,
    [styles[EValidationMessageType.Error]]: isError,
    error: isError,
    [styles.empty]: !(props.message && props.message.length > 0),
  });
  return <div className={validationClass}>{props.message || ""}</div>;
}
