import * as constants from "../../../constants";
import { TAction, TDispatch } from "../../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize, serialize } from "@api/jsonApiParser";
import { Dispatch } from "redux";

export const FETCHED_TENANTMAPPINGS = "FETCHED_TENANTMAPPINGS";
export const SET_TENANTMAPPINGS_PAGE_NO = "SET_TENANTMAPPINGS_PAGE_NO";
export const SET_FILTERING_CRITERIA_TENANTMAPPINGS = "SET_FILTERING_CRITERIA_TENANTMAPPINGS";
export const INCREMENT_TENANTMAPPINGS_PAGE_NO = "INCREMENT_TENANTMAPPINGS_PAGE_NO";

export interface TenantMappingsState {
  tenantMappings: any;
  tenantMappingsPageNo: any;
  moreTenantMappings: boolean;
  filter: any;
}

const initialState = {
  tenantMappings: [],
  tenantMappingsPageNo: 1,
  moreTenantMappings: true,
  filter: undefined,
};

export const TenantMappingsReducer = (
  state: TenantMappingsState = initialState,
  action: TAction
) => {
  switch (action.type) {
    case FETCHED_TENANTMAPPINGS: {
      const { payload } = action;
      const { page, tenantMappings } = payload;

      let newTenantMappings = [];
      const moreTenantMappings = !(tenantMappings.length < constants.PAGINATION_SIZE);

      if (state.tenantMappingsPageNo === 1 || page === 1) {
        newTenantMappings = [...tenantMappings];
      } else {
        newTenantMappings = [...state.tenantMappings, ...tenantMappings];
      }

      return {
        ...state,
        tenantMappings: newTenantMappings,
        moreTenantMappings: moreTenantMappings,
      };
    }

    case SET_TENANTMAPPINGS_PAGE_NO: {
      const moreTenantMappings = !(
        state.tenantMappings.length <
        (state.tenantMappingsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return {
        ...state,
        tenantMappingsPageNo: action.payload,
        moreTenantMappings: moreTenantMappings,
      };
    }

    case SET_FILTERING_CRITERIA_TENANTMAPPINGS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_TENANTMAPPINGS_PAGE_NO: {
      return {
        ...state,
        tenantMappingsPageNo: state.tenantMappingsPageNo + 1,
      };
    }
  }
  return state;
};

export const setTenantMappingsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_TENANTMAPPINGS_PAGE_NO,
      payload,
    });
  };
};

export const fetchTenantMappingList = (
  page = 1,
  pageSize = 10,
  filter?: Record<string, unknown>
) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = `TenantMapping/TenantMappingPaginated?Page=${page}&PageSize=${pageSize}`;
    if (filter) {
      Object.keys(filter).forEach(key => {
        if ((key === "dynamicFilter" && filter[key]) || (key === "GenericText" && filter[key]))
          query += `&SearchGeneric=${filter[key]}`;
      });
    }

    return await http.get(query).then((response: TResponse) => {
      dispatch({
        type: FETCHED_TENANTMAPPINGS,
        payload: {
          tenantMappings: (response.data as Record<string, unknown>[]).map(tenantMapping =>
            deserialize(tenantMapping)
          ),
          page: page,
        },
      });

      dispatch({ type: INCREMENT_TENANTMAPPINGS_PAGE_NO });

      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const createTenantMapping = payload => {
  return http.post("TenantMapping/", serialize(payload, "TenantMapping"));
};

export const changeTenantMapping = (id: string, payload) => {
  return http.put("TenantMapping/" + id, serialize(payload, "TenantMapping"));
};

export const deleteTenantMapping = (id: string) => {
  return http.delete("TenantMapping/" + id);
};

export const getTenantMappingById = (id: string) => {
  return http.get("TenantMapping/" + id);
};
