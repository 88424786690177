import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { FormattedMessage } from "react-intl";

import { Checkbox } from "@components/Checkbox";
import { StyledLabel as Label } from "@components/Label";
import { StyledError as Error } from "@components/Error";
import { TCheckbox } from "@components/Checkbox/types";

const FormCheckbox: FC<TCheckbox> = props => {
  const {
    id,
    label,
    name,
    register,
    errors,
    wrapperProps,
    setValue,
    getValues,
    onChange,
    ...rest
  } = props;

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (getValues) {
      setChecked(getValues(name));
    }
  }, [getValues, name]);

  useEffect(() => {
    if (setValue) {
      setValue(name, checked);
    }
  }, [checked, name, onChange, setValue]);

  return (
    <Flex
      pl="15px"
      pr="15px"
      flexDirection="column"
      justifyContent="space-between"
      {...wrapperProps}
    >
      {label && <Label>{label}</Label>}
      <Flex height="50px" alignItems="center">
        <input name={name} ref={register} type="checkbox" style={{ display: "none" }} {...rest} />
        <Checkbox id={id} checked={checked} setChecked={setChecked} />
        <Error>
          {errors && errors[name] && (
            <FormattedMessage
              id={errors[name]?.message}
              defaultMessage="* This field is required"
            />
          )}
        </Error>
      </Flex>
    </Flex>
  );
};

export { FormCheckbox };
