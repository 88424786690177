import styled from "@emotion/styled";

import { colors } from "@styles/colors";

const StyledError = styled.div`
  padding-top: 5px;
  font-size: 12px;
  color: ${colors.red};
`;

export { StyledError };
