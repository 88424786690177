import React, { FC, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalContainer, ModalHeader, TextButton, Wizard } from "@icr/react.uicomponents";

import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";
import { Step1, Step2, Step3 } from "@features/User/Users/Modal/steps";
import { ReactComponent as ArrowLeftBlue } from "@assets/icons/arrow-left-blue.svg";
import { changeUser, createUser, setFormUser, updateUserRoles } from "@features/User/Users/ducks";

import { fetchRoles } from "@actions/role";

import { TProps } from "./types";
import { TDispatch, TRootState } from "@redux/types";
import { buildNewUserRolesList } from "@utils/users";
import { TApplication } from "@utils/applications";
import { fetchApplications, fetchGenders, fetchLanguages } from "../../../../reducers/enumerator";
import { fetchCompanies } from "@features/Company/ducks";
import { Flex } from "reflexbox";

const UsersModal: FC<TProps> = props => {
  const { closeModal } = props;

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { swalAlert, swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const [isEditing, setIsEditing] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [rolesList, setRolesList] = useState<Record<string, unknown>[]>([]);
  const [applicationsList, setApplicationsList] = useState<TApplication[]>([]);
  const [companiesList, setCompaniesList] = useState<Record<string, unknown>[]>([]);
  const [languagesList, setLanguagesList] = useState<Record<string, unknown>[]>([]);
  const [gendersList, setGendersList] = useState<Record<string, unknown>[]>([]);

  const [currentStep, setCurrentStep] = useState(0);

  const { formUser } = useSelector(
    ({ UsersReducer: { formUser } }: TRootState) => ({
      formUser,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchRoles()).then(ret => setRolesList(ret));
    dispatch(fetchApplications()).then(ret => setApplicationsList(ret));
    dispatch(fetchCompanies()).then(ret => setCompaniesList(ret));
    dispatch(fetchLanguages()).then(ret => setLanguagesList(ret));
    dispatch(fetchGenders()).then(ret => setGendersList(ret));

    setIsEditing(!!formUser.id);
  }, [dispatch, formUser.id]);

  const showSuccessMessage = (username, action) => {
    swalAlertWithCallback(
      {
        text: `User: ${username} has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const editUser = (user, userRoles, userId, haveUserRoles) => {
    delete user.id;

    const userToEdit = Object.keys(user).map(key => {
      return {
        op: "replace",
        path: `/${[key]}`,
        value: user[key],
      };
    });

    swalConfirmationMessage({
      text: "Do you really want to change this user?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Save changes",
    }).then(value => {
      if (value) {
        changeUser(userId, userToEdit).then(ret => {
          if (ret.success) {
            if (haveUserRoles) {
              updateUserRoles({ roles: userRoles }).then((ret: any) => {
                setIsLoading(false);

                if (ret.success) {
                  showSuccessMessage(formUser.username, "changed");
                } else {
                  swalAlert({
                    text: "An Error has occured while setting the roles!",
                    type: "error",
                  });
                }
              });
            } else {
              showSuccessMessage(formUser.username, "changed");
            }
          }
        });
      }

      setIsLoading(false);
    });
  };

  const newUser = (user, userRoles) => {
    swalConfirmationMessage({
      text: "Do you really want to create this user?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Save new User",
    }).then(value => {
      if (value) {
        user = {
          ...user,
          roles: userRoles,
        };

        createUser(user).then(ret => {
          setIsLoading(false);
          if (ret.success) {
            showSuccessMessage(user.username, "created");
          } else {
            swalAlert({
              text: "An Error has occured while add user!",
              type: "error",
            });
          }
        });
      }

      setIsLoading(false);
    });
  };

  const onSubmitStep1 = formData => {
    dispatch(
      setFormUser({
        ...formUser,
        ...formData,
        sex: formData?.sex?.value,
        language: formData?.language?.value,
        companyId: formData?.companyId?.value,
      })
    );

    setCurrentStep(1);
  };

  const onSubmitStep2 = formData => {
    dispatch(
      setFormUser({
        ...formUser,
        ...formData,
      })
    );
    setCurrentStep(2);
  };

  const onSubmitStep3 = formData => {
    setIsLoading(true);

    const user = {
      ...formUser,
      ...formData,
    };

    dispatch(setFormUser(user));

    delete user.roles;

    const userRoles = buildNewUserRolesList(user, formUser, applicationsList);
    const haveUserRoles = userRoles && userRoles.length > 0;

    if (isEditing) {
      editUser(user, userRoles, user.id, haveUserRoles);
    } else if (haveUserRoles && !isEditing) {
      newUser(user, userRoles);
    } else {
      swalAlert({ text: "You must select at least one role", type: "error" });
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <TextButton id="backBtn" onClick={closeModal} font="bold">
          <Flex mr="20px">
            <ArrowLeftBlue />
          </Flex>
          {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
        </TextButton>
      </ModalHeader>
      <Wizard
        id="userWizard"
        title={formatMessage("app.addNewEntry")}
        currentStep={currentStep}
        steps={[
          {
            title: "Step 1",
            content: (
              <Step1
                onSubmit={onSubmitStep1}
                initialData={formUser}
                gendersList={gendersList}
                languagesList={languagesList}
                companiesList={companiesList}
                closeModal={closeModal}
              />
            ),
            active: currentStep === 0,
          },
          {
            title: "Step 2",
            content: (
              <Step2
                onSubmit={onSubmitStep2}
                back={() => setCurrentStep(0)}
                isEditing={isEditing}
                initialData={formUser}
              />
            ),
            active: currentStep === 1,
          },
          {
            title: "Step 3",
            content: (
              <Step3
                onSubmit={onSubmitStep3}
                back={() => setCurrentStep(1)}
                applicationsList={applicationsList}
                rolesList={rolesList}
                initialData={formUser}
                isLoading={isLoading}
              />
            ),
            active: currentStep === 2,
          },
        ]}
      />
    </ModalContainer>
  );
};

export default UsersModal;
