import * as yup from "yup";

const TenantSettingsSchema = yup.object().shape({
  id: yup.string(),
  tenantId: yup.object().required().nullable(),
  applicationCode: yup.object().required().nullable(),
  type: yup.object().required().nullable(),
  files: yup.mixed().test("isEmpty", "app.required", value => {
    return value && value.length > 0;
  }),
});

export { TenantSettingsSchema };
