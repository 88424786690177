import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "../../../styles/index.scss";

import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  Checkbox,
  ListContainer,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";

import { TDispatch, TRootState } from "../../../redux/types";
import { fetchVersionLogList, setVersionLogsPageNo } from "./ducks";

const VersionLogs: FC = () => {
  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { versionLogsPageNo, moreVersionLogs, versionLogs } = useSelector(
    ({ VersionLogReducer: { versionLogsPageNo, moreVersionLogs, versionLogs } }: TRootState) => ({
      versionLogsPageNo,
      moreVersionLogs,
      versionLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchVersionLogList());
  }, [dispatch]);

  const handleScroll = () => {
    if (moreVersionLogs) {
      dispatch(fetchVersionLogList(versionLogsPageNo));
    }
  };

  const filterVersionLogs = () => {
    dispatch(setVersionLogsPageNo(1)).then(() => {
      dispatch(
        fetchVersionLogList(1, 10, {
          GenericText: genericTextFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");
    dispatch(setVersionLogsPageNo(1)).then(() => {
      dispatch(fetchVersionLogList());
    });
  };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <ScrollableList
            items={versionLogs}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.fullVersion", "Version")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.applicationName", "Application")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.success", "Success")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.message", "Message")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={`versionRow${index}`}
                id={item.id as string}
                expandable={true}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold"> {item.fullVersion}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.applicationName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Checkbox id="checkboxSuccess" checked={item.success as boolean} />
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.message}</Text>
                    </ListColumn>
                    <ListColumn icon></ListColumn>
                  </Flex>
                }
                more={
                  <Flex width="100%" mb="40px">
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.statement", "Statement")}
                      </Text>
                      <Text mt="10px">{item.statement}</Text>
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterVersionLogs}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            value={genericTextFilter}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default VersionLogs;
