import * as constants from "../constants";
import { Dispatch } from "redux";

import http from "@api/http";
import apiCaller from "@api/apiHandler";
import requestWraper from "@api/requestWraper";
import { deserialize } from "@api/jsonApiParser";
import { TResponse } from "@api/types";
import { TDispatch } from "@redux/types";

export const fetchLayers = () => async (dispatch: Dispatch): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/Layer")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(info => deserialize(info));
    });
};

export const fetchModules = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/Module")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(info => deserialize(info));
    });
};

export const fetchTranslationTypes = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });
  return await http
    .get("Enumerator/TranslationType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(info => deserialize(info));
    });
};

export const fetchBaseTextTranslates = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });
  return await http
    .get("Enumerator/BaseTextTranslate")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(info => deserialize(info));
    });
};

export const fetchDatabaseTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/DatabaseType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(apps => deserialize(apps));
    });
};

export const fetchWebServiceBindingTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/WebServiceBindingType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(webservices =>
        deserialize(webservices)
      );
    });
};

export const fetchAuthenticationTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/AuthenticationType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return (response.data as Record<string, unknown>[]).map(authenticationsTypes =>
        deserialize(authenticationsTypes)
      );
    });
};

export const fetchObjectTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/ObjectType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(apps => deserialize(apps));
    });
};

export const fetchTenantMappingTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/TenantMappingType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(apps => deserialize(apps));
    });
};

export const fetchTenantSettingTypes = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http
    .get("Enumerator/TenantSettingsType")
    .then((response: TResponse<Record<string, unknown>[]>) => {
      dispatch({ type: constants.LOADING, payload: false });
      return response.data.map(apps => deserialize(apps));
    });
};

export const fetchFolderTypes = () =>
  requestWraper(async dispatch => {
    try {
      dispatch({ type: constants.LOADING, payload: true });
      return await apiCaller("GET", `Enumerator/FolderType`).then((response: any) => {
        dispatch({ type: constants.LOADING, payload: false });
        return response.data.map(apps => deserialize(apps));
      });
    } catch (err) {
      // @TODO: better german translation
      throw err || "Etwas ist schief gelaufen!";
    }
  });
