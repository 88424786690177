import * as yup from "yup";

const UserPasswordSchema = yup.object().shape({
  id: yup.string(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

export { UserPasswordSchema };
