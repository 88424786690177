import { useIntl } from "react-intl";

type TUseFormatMessage = (id: string, defaultMessage?: string) => string;

const useFormatMessage = (): TUseFormatMessage => {
  const intl = useIntl();

  return (id, defaultMessage) => intl.formatMessage({ id, defaultMessage });
};

export { useFormatMessage };
