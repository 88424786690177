import React, { FC } from "react";
import { Flex } from "reflexbox";

import { TInput } from "@components/Input/types";
import { StyledLabel as Label } from "@components/Label";

import { StyledInput, StyledWrapper as Wrapper } from "./styles";

const Input: FC<TInput> = ({
  name,
  type,
  label,
  errors,
  hidden,
  wrapperProps,
  width = "100%",
  register,
  ...rest
}) => {
  return (
    <Wrapper hidden={hidden} {...wrapperProps}>
      {label && <Label>{label}</Label>}
      <Flex flexDirection="column" justifyContent="flex-end">
        <StyledInput
          name={name}
          width={width}
          type={hidden ? "hidden" : type}
          hasError={errors && errors[name]}
          ref={register}
          {...rest}
        />
      </Flex>
    </Wrapper>
  );
};

export { Input };
