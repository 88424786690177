import * as yup from "yup";

const DatabaseImportSchema = yup.object().shape({
  fileId: yup.lazy(() =>
    yup.object().when("files", {
      is: val => val && val.length > 0,
      then: yup.object().required("Select an file or upload an file").nullable(),
      otherwise: yup.object().nullable(),
    })
  ),
  files: yup.lazy(() =>
    yup.mixed().when("fileId", {
      is: val => val && val.value === 0,
      then: yup.string().required("Upload an file or select an file"),
      otherwise: yup.mixed().nullable(),
    })
  ),
});

export { DatabaseImportSchema };
