import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import {
  Button,
  TextButton,
  Form,
  FormInput as Input,
  FormCheckbox as Checkbox,
} from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";
import { useSwal } from "@hooks/useSwal";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { TProps } from "./types";
import { MailServerSchema } from "./schema";
import { createMailServerSetting, changeMailServerSetting } from "../ducks";
import { TextArea } from "@components/Form/TextArea";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const MailServerSettingsModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Mail server setting has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const mailServerSetting = { ...formData };

    delete mailServerSetting.id;

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this mail server setting?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Mail Server",
      }).then(value => {
        if (value) {
          delete mailServerSetting.password;
          delete mailServerSetting.passwordrepeat;
          const mailServerSettingToEdit = Object.keys(mailServerSetting).map(key => {
            return {
              op: "replace",
              path: `/${[key]}`,
              value: mailServerSetting[key],
            };
          });

          changeMailServerSetting(formData.id, mailServerSettingToEdit).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this mail server setting?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Mail Server",
      }).then(value => {
        if (value) {
          createMailServerSetting(mailServerSetting).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={MailServerSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="emailFrom"
              name="emailFrom"
              label={formatMessage("app.emailFrom", "E-mail")}
            />
            <Input
              id="mailServerFqdn"
              name="mailServerFqdn"
              label={formatMessage("app.mailServerFqdn", "Domain Name")}
            />
            <Input
              id="port"
              name="port"
              type="number"
              label={formatMessage("app.port", "Port")}
              wrapperProps={{ width: "10%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Checkbox
              id="enableSsl"
              name="enableSsl"
              label={formatMessage("app.enableSsl", "Enable Ssl")}
              wrapperProps={{ width: "10%" }}
            />
            <Checkbox
              id="isActive"
              name="isActive"
              label={formatMessage("app.isActive", "Is Active")}
              wrapperProps={{ width: "10%" }}
            />
            <Input
              id="username"
              name="username"
              label={formatMessage("app.username", "Username")}
            />
            {!isEditing && (
              <>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  label={formatMessage("app.password", "Password")}
                />
                <Input
                  id="passwordrepeat"
                  name="passwordrepeat"
                  type="password"
                  label={formatMessage("app.passwordrepeat", "Repeat Password")}
                />
              </>
            )}
          </Flex>
          <Flex mt="30px">
            <TextArea
              id="defaultBodyHtml"
              name="defaultBodyHtml"
              label={formatMessage("app.defaultBodyHtml", "Default Body Html")}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default MailServerSettingsModal;
