import { TFileStructureState } from "./types";
import { TDispatch } from "../../redux/types";
import * as constants from "../../../src/constants";

import http from "@api/http";
import { buildFilterQueryString, deserialize, serialize } from "@api/jsonApiParser";
import { TResponse } from "@api/types";
import { Dispatch } from "redux";

export const FETCHED_FILESTRUCTURE = "FETCHED_FILESTRUCTURE";
export const SET_FILTERING_CRITERIA_FILESTRUCTURE = "SET_FILTERING_CRITERIA_FILESTRUCTURE";
export const SET_FILESTRUCTURE_PAGE_NO = "SET_FILESTRUCTURE_PAGE_NO";
export const INCREMENT_FILESTRUCTURE_PAGE_NO = "INCREMENT_FILESTRUCTURE_PAGE_NO";

const initialState = {
  foldersAndFiles: [],
  foldersAndFilesPageNo: 1,
  moreFoldersAndFiles: true,
  filter: undefined,
};

const FileStructureReducer = (state: TFileStructureState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_FILESTRUCTURE: {
      let foldersAndFiles = [];
      const moreFoldersAndFiles = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.foldersAndFilesPageNo === 1 || action.page === 1) {
        foldersAndFiles = [...action.payload];
      } else {
        foldersAndFiles = [...state.foldersAndFiles, ...action.payload];
      }

      return {
        ...state,
        foldersAndFiles: foldersAndFiles,
        moreFoldersAndFiles: moreFoldersAndFiles,
      };
    }

    case SET_FILESTRUCTURE_PAGE_NO: {
      const moreFoldersAndFiles = !(
        state.foldersAndFiles.length <
        (state.foldersAndFilesPageNo + 1) * constants.PAGINATION_SIZE
      );
      return {
        ...state,
        foldersAndFilesPageNo: action.payload,
        moreFoldersAndFiles: moreFoldersAndFiles,
      };
    }

    case SET_FILTERING_CRITERIA_FILESTRUCTURE: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_FILESTRUCTURE_PAGE_NO: {
      return {
        ...state,
        foldersAndFilesPageNo: state.foldersAndFilesPageNo + 1,
      };
    }
  }
  return state;
};

export const setFileStructurePageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_FILESTRUCTURE_PAGE_NO,
      payload,
    });
  };
};

export const fetchFileStructureList = (
  page = 1,
  pageSize = 10,
  folderId = "",
  filter?: Record<string, unknown>
) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `FileStructure?page=${page}&pageSize=${pageSize}&folderId=${folderId}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_FILESTRUCTURE,
          payload: (response.data as Record<string, unknown>[]).map(FileStructure =>
            deserialize(FileStructure)
          ),
          page: page,
        });
        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};

// Actions to FOLDER --------------------------
export const createFolder = payload => {
  return http.post("Folder/", serialize(payload, "Folder"));
};

export const changeFolder = (id: string, payload) => {
  return http.put("Folder/" + id, serialize(payload, "Folder"));
};

export const deleteFolder = (id: string) => {
  return http.delete("Folder/" + id);
};

export const getFolderById = (id: string) => {
  return http.get("Folder/" + id);
};

export const downloadFolder = (id: string) => {
  return http.get("Folder/Download/" + id);
};

// Actions to FILE --------------------------
export const uploadFile = (folderId: string, fileId: string, files: FormData) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  let query = `File/Upload/?folderId=${folderId}`;
  if (fileId) query += `&fileId=${fileId}`;

  return http.post(query, files, config);
};

export const deleteFile = (id: string) => {
  return http.delete("File/" + id);
};

export const getFileById = (id: string) => {
  return http.get("File/" + id);
};

export const downloadFile = (id: string) => {
  return http.get("File/Download/" + id, { responseType: "blob" });
};

export const fetchFolders = (filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<Record<string, unknown>[]> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = "";
    if (filter) {
      Object.keys(filter).forEach(key => {
        query += `${query ? "&" : "?"}Search=${filter[key]}`;
      });
    }
    query = "Folder/" + query;

    return await http.get(query).then((response: TResponse) => {
      dispatch({ type: constants.LOADING, payload: false });

      dispatch({ type: INCREMENT_FILESTRUCTURE_PAGE_NO });

      return (response.data as Record<string, unknown>[]).map(res => deserialize(res));
    });
  };
};

export { FileStructureReducer };
