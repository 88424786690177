import React, { FC, useEffect } from "react";
import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { bindActionCreators, Dispatch } from "redux";
import { HashRouter as Router, Link, Redirect, Route } from "react-router-dom";

import Login from "./features/Login";
import LoginTokens from "./features/LoginToken/LoginToken";

import { ReactComponent as IconClose } from "@assets/icons/close.svg";
import { ReactComponent as IconHamburger } from "@assets/icons/hamburger.svg";

import {
  IconCompany,
  IconConfiguration,
  IconLog,
  IconSessions,
  IconSystemConfiguration,
  IconTenant,
  IconUsers,
} from "@components/Icons";
import { ReactComponent as IconFileStructure } from "assets/icons/filestructure.svg";

import * as actions from "./actions/";
import privateRoutes from "./routes/routes";
import { TAction, TRootState } from "./redux/types";
import { logOut, logOutLocally } from "./shared/state/AuthenticateActions";
import "./App.scss";
import PrivateRoute from "./routes/PrivateRoute";
import clsx from "clsx";
import { useFormatMessage } from "@hooks/formatMessage";
import { setCustomMessages } from "@icr/react.uicomponents";

export interface IconProps {
  color: string;
}

export interface AppProps {
  sidebarOpened: boolean;
  authentication: any;
  setSidebar: any;
  loading: boolean;
  logOut: any;
  location: any;
}

const setBodyClassNames = opened => {
  if (opened) {
    document.body.classList.add("opened");
    document.body.classList.remove("closed");
  } else {
    document.body.classList.remove("opened");
    document.body.classList.add("closed");
  }
};

const toggleDiv = (id: string) => {
  const div = document.getElementById(id);
  if (div.style.display !== "block") {
    div.style.display = "block";
  } else {
    div.style.display = "none";
  }
};

const mapStateToProps = ({ LoginReducer, AppReducer }: TRootState) => ({
  authentication: LoginReducer.authentication,
  sidebarOpened: AppReducer.sidebarOpened,
});

const mapDispatchToProps = (dispatch: Dispatch<TAction>) => ({
  setSidebar: val => dispatch(actions.setSidebar(val)),
  logOut: bindActionCreators(logOut, dispatch),
  logOutLocally: bindActionCreators(logOutLocally, dispatch),
});

const App: FC<AppProps> = ({ sidebarOpened, setSidebar, authentication, location }) => {
  setBodyClassNames(sidebarOpened);

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setCustomMessages({
      required: formatMessage("app.requiredField"),
    });
  }, [formatMessage]);

  // Checks if user is already logged in with valid token.
  // If needs to treat some permission to show routes, logic is here
  const isUserNotLoggedIn = token => {
    if (!token) {
      logOutLocally();
      return true;
    } else return false;
  };

  // Shows message to user with session expired or inexisten
  const expiredAlert = () => {
    if (location.pathname !== "/login" && location.pathname !== "/") {
      toastr.error("Session expired", "Please log in again");
    }

    return true;
  };

  return (
    <Router>
      {!isUserNotLoggedIn(authentication.token) && authentication.step !== "2fa" && (
        <Sidebar
          rootClassName={clsx("sidebarContainer", sidebarOpened && "open")}
          sidebarClassName="sidebar"
          contentClassName={clsx("sidebarContent", sidebarOpened && "open")}
          overlayClassName="sidebarOverlay"
          open={sidebarOpened}
          docked={false}
          styles={{
            sidebar: {},
          }}
          onSetOpen={opened => {
            setBodyClassNames(opened);
            setSidebar(opened);
          }}
          sidebar={<div></div>}
        >
          <div
            className="hamburgerContainer d-flex"
            onMouseLeave={() => {
              document.getElementById("users").style.display = "none";
              setBodyClassNames(false);
              setSidebar(false);
            }}
          >
            <button
              tabIndex={1}
              onClick={() => {
                setBodyClassNames(!sidebarOpened);
                setSidebar(!sidebarOpened);
              }}
              style={{
                zIndex: 2,
                position: "absolute",
                height: "40px",
                top: "18px",
                width: "48px",
              }}
              className="hamburger"
            >
              {sidebarOpened ? <IconClose /> : <IconHamburger />}
            </button>
            <div className="links">
              <div
                id="dropdown"
                onClick={() => {
                  toggleDiv("users");
                  setBodyClassNames(document.getElementById("users").style.display === "block");
                  setSidebar(document.getElementById("users").style.display === "block");
                }}
              >
                <Link className="sidebarLink users" to={undefined} id="linkMenuUsers">
                  <div data-cy="userLink" className="iconWrapper large sidebarIconWrapper">
                    <IconUsers color="#AFAFB1" />
                  </div>
                  <span className="ml-4" style={{ margin: "12px" }}>
                    User
                  </span>
                </Link>
                <br />
                <div id="users" style={{ display: "none" }}>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to="/users/users"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Users
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/users/roles"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        User Roles
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <div
                id="dropdown"
                onClick={() => {
                  toggleDiv("logs");
                  setBodyClassNames(document.getElementById("logs").style.display === "block");
                  setSidebar(document.getElementById("logs").style.display === "block");
                }}
              >
                <Link className="sidebarLink users" to={undefined} id="linkMenuLogs">
                  <div data-cy="logLink" className="iconWrapper large sidebarIconWrapper">
                    <IconLog color="#AFAFB1" />
                  </div>
                  <span className="ml-4" style={{ margin: "12px" }}>
                    Log
                  </span>
                </Link>
                <br />
                <div id="logs" style={{ display: "none" }}>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to={{
                        pathname: "/logs/logonLogs",
                        state: { currentTab: "logonLogs" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Logon Logs
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to={{
                        pathname: "/logs/emailLogs",
                        state: { currentTab: "emailLogs" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Email Logs
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to={{
                        pathname: "/logs/smsLogs",
                        state: { currentTab: "smsLogs" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        SMS Logs
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to={{
                        pathname: "/logs/versionLogs",
                        state: { currentTab: "versionLogs" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Version Logs
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <div
                id="dropdown"
                onClick={() => {
                  toggleDiv("systemconfiguration");
                  setBodyClassNames(
                    document.getElementById("systemconfiguration").style.display === "block"
                  );
                  setSidebar(
                    document.getElementById("systemconfiguration").style.display === "block"
                  );
                }}
              >
                <Link className="sidebarLink users" to={undefined} id="linkMenuSystemConfiguration">
                  <div data-cy="logLink" className="iconWrapper large sidebarIconWrapper">
                    <IconSystemConfiguration color="#AFAFB1" />
                  </div>
                  <span className="ml-4" style={{ margin: "12px" }}>
                    System Configuration
                  </span>
                </Link>
                <br />
                <div id="systemconfiguration" style={{ display: "none" }}>
                  <p>
                    <Link
                      to="/systemconfiguration/database"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Database
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/systemconfiguration/translation"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Translation
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/systemconfiguration/versions"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Versions
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/systemconfiguration/databaseexport"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Database Export
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/systemconfiguration/databaseimport"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Database Import
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="/systemconfiguration/defaultvariables"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Default Variables
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <div
                id="dropdown"
                onClick={() => {
                  toggleDiv("configuration");
                  setBodyClassNames(
                    document.getElementById("configuration").style.display === "block"
                  );
                  setSidebar(document.getElementById("configuration").style.display === "block");
                }}
              >
                <Link className="sidebarLink users" to={undefined} id="linkMenuConfiguration">
                  <div data-cy="logLink" className="iconWrapper large sidebarIconWrapper">
                    <IconConfiguration color="#AFAFB1" />
                  </div>
                  <span className="ml-4" style={{ margin: "12px" }}>
                    Configuration
                  </span>
                </Link>
                <br />
                <div id="configuration" style={{ display: "none" }}>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to={{
                        pathname: "/configuration/connections",
                        state: { currentTab: "connections" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Connections
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={{
                        pathname: "/configuration/mailserversettings",
                        state: { currentTab: "mailserversettings" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        MailServer Settings
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={{
                        pathname: "/configuration/objects",
                        state: { currentTab: "objects" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Objects
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={{
                        pathname: "/configuration/objectroles",
                        state: { currentTab: "objectroles" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Object Roles
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={{
                        pathname: "/configuration/webservices",
                        state: { currentTab: "webservices" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        WebServices
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={{
                        pathname: "/configuration/applications",
                        state: { currentTab: "applications" },
                      }}
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Applications
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <Link
                className="sidebarLink users"
                to="/filestructure"
                onClick={() => {
                  setBodyClassNames(false);
                  setSidebar(false);
                }}
              >
                <div data-cy="userLink" className="iconWrapper large sidebarIconWrapper">
                  <IconFileStructure color="#AFAFB1" />
                </div>
                <span className="ml-4" style={{ margin: "12px" }}>
                  File Structure
                </span>
              </Link>
              <br />
              <Link
                className="sidebarLink users"
                to="/sessions"
                onClick={() => {
                  setBodyClassNames(false);
                  setSidebar(false);
                }}
              >
                <div data-cy="userLink" className="iconWrapper large sidebarIconWrapper">
                  <IconSessions color="#AFAFB1" />
                </div>
                <span className="ml-4" style={{ margin: "12px" }}>
                  Sessions
                </span>
              </Link>
              <br />
              <Link
                className="sidebarLink users"
                to="/company"
                onClick={() => {
                  setBodyClassNames(false);
                  setSidebar(false);
                }}
              >
                <div data-cy="userLink" className="iconWrapper large sidebarIconWrapper">
                  <IconCompany color="#AFAFB1" />
                </div>
                <span className="ml-4" style={{ margin: "12px" }}>
                  Company
                </span>
              </Link>
              <br />
              <div
                id="dropdown"
                onClick={() => {
                  toggleDiv("tenants");
                  setBodyClassNames(document.getElementById("tenants").style.display === "block");
                  setSidebar(document.getElementById("tenants").style.display === "block");
                }}
              >
                <Link className="sidebarLink users" to={undefined} id="linkMenuTenant">
                  <div data-cy="logLink" className="iconWrapper large sidebarIconWrapper">
                    <IconTenant color="#AFAFB1" />
                  </div>
                  <span className="ml-4" style={{ margin: "12px" }}>
                    Tenant
                  </span>
                </Link>
                <br />
                <div id="tenants" style={{ display: "none" }}>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to="/tenant/tenants"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Tenant
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to="/tenant/tenantmappings"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Mapping
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to="/tenant/tenantsettings"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Settings
                      </span>
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-decoration-none black"
                      to="/tenant/tenanttranslations"
                      onClick={() => {
                        setBodyClassNames(false);
                        setSidebar(false);
                      }}
                    >
                      <span className="ml-4" style={{ margin: "12px", color: "black" }}>
                        Translations
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <br />
            </div>
            <br />
          </div>
        </Sidebar>
      )}

      <Route
        path="/login"
        render={props =>
          !isUserNotLoggedIn(authentication.token) && authentication.step !== "2fa" ? (
            expiredAlert() && <Redirect to="/" />
          ) : (
            <Login {...props} />
          )
        }
      />

      <Route
        path="/login-token"
        render={props =>
          !isUserNotLoggedIn(authentication.token) && authentication.step !== "2fa" ? (
            expiredAlert() && <Redirect to="/" />
          ) : (
            <LoginTokens {...props} />
          )
        }
      />

      <Route
        exact
        path="/"
        render={() =>
          isUserNotLoggedIn(authentication.token) ? (
            expiredAlert() && <Redirect to="/login" />
          ) : (
            <Redirect to="/users/users" />
          )
        }
      />

      {privateRoutes.map(page => (
        <PrivateRoute path={page.path} key={page.path}>
          {page.component}
        </PrivateRoute>
      ))}
    </Router>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
