import React, { FC } from "react";
import { toastr } from "react-redux-toastr";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";

import { TPrivateRoute } from "./types";
import { TRootState } from "../redux/types";
import { logOutLocally } from "../shared/state/AuthenticateActions";

const PrivateRoute: FC<TPrivateRoute> = ({ children, path, key }) => {
  const authentication = useSelector(({ LoginReducer }: TRootState) => LoginReducer.authentication);
  const dispatch = useDispatch();

  const location = useLocation();
  const { token } = authentication;

  const logout = () => {
    dispatch(logOutLocally());

    if (location.pathname !== "/login" && location.pathname !== "/") {
      toastr.error("Session expired", "Please log in again");
    }

    return true;
  };

  const renderChildrenWithProps = props => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...props, location });
      }

      return child;
    });
  };

  return (
    <Route
      path={path}
      key={key}
      render={props => (token ? renderChildrenWithProps(props) : logout() && <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
