import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import { Form } from "@components/Form";
import { useSwal } from "@hooks/useSwal";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";

import { TProps } from "./types";
import { TranslationsSchema } from "./schema";
import { useFormatMessage } from "@hooks/formatMessage";
import { changeTranslation, createTranslation } from "../ducks";
import {
  Button,
  Card,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  TextButton,
  ModalBackButton,
} from "@icr/react.uicomponents";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TranslationsModal: FC<TProps> = props => {
  const {
    languageList,
    baseTextTranslateIdList,
    translationTypeList,
    moduleList,
    layerList,
    closeModal,
    initialData,
  } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Translation has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const translation = {
      ...formData,
      layer: formData.layer ? formData.layer.value : undefined,
      module: formData.module ? formData.module.value : undefined,
      translationType: formData.translationType ? formData.translationType.value : undefined,
      language: formData.language ? formData.language.value : undefined,
      baseTextTranslateId: formData.baseTextTranslateId
        ? formData.baseTextTranslateId.value
        : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this translation?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Translation",
      }).then(value => {
        if (value) {
          changeTranslation(formData.id, translation).then(ret => {
            if (ret.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this translation?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Translation",
      }).then(value => {
        if (value) {
          delete translation.id;
          createTranslation(translation).then(ret => {
            if (ret.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalBackButton
          onCloseModal={closeModal}
          backMessage={formatMessage("app.backToOverview")}
        />
        <ModalTitle title={formatMessage("app.addNewEntry")} />
      </ModalHeader>
      <Card>
        <Flex flexDirection="column" mb={20}>
          <Form onSubmit={onSubmit} schema={TranslationsSchema} defaultValues={initialData}>
            <Flex>
              <Input id="id" name="id" hidden />
              <Input id="originalText" name="originalText" hidden />
              <Select
                id="layer"
                name="layer"
                label={formatMessage("app.layer", "Layer")}
                options={layerList.map(option => ({
                  value: option.id,
                  label: option.description,
                }))}
              />
              <Select
                id="module"
                name="module"
                label={formatMessage("app.module", "Module")}
                options={moduleList.map(option => ({
                  value: option.id,
                  label: option.description,
                }))}
              />
            </Flex>
            <Flex mt="30px">
              <Select
                id="translationType"
                name="translationType"
                label={formatMessage("app.translationType", "Translation Type")}
                options={translationTypeList.map(option => ({
                  value: option.id,
                  label: option.description,
                }))}
              />
              <Select
                id="language"
                name="language"
                label={formatMessage("app.language", "Language")}
                options={languageList
                  .filter(data => data.id !== "en-US")
                  .map(option => ({
                    value: option.id,
                    label: option.description,
                  }))}
              />
            </Flex>
            <Flex mt="30px">
              <Select
                id="baseTextTranslateId"
                name="baseTextTranslateId"
                label={formatMessage("app.baseTextTranslateId", "Base Text Translate")}
                options={baseTextTranslateIdList.map(option => ({
                  value: option.id,
                  label: option.description,
                }))}
              />
              <Input
                id="translationId"
                name="translationId"
                label={formatMessage("app.translationId", "Translation Id")}
              />
            </Flex>
            <Flex mt="30px">
              <Input
                id="textTranslated"
                name="textTranslated"
                label={formatMessage("app.textTranslated", "Text Translated")}
              />
              <Checkbox
                id="automaticTranslation"
                name="automaticTranslation"
                label={formatMessage("app.automaticTranslation", "Automatic Translation")}
                wrapperProps={{ width: "100%" }}
              />
            </Flex>
            <Flex mt={26} width="100%" justifyContent="flex-end" pr="15px">
              <TextButton id="ModalBtn_cancel" mr="32px" onClick={closeModal}>
                {formatMessage("app.cancel")}
              </TextButton>
              <Button id="ModalBtn_save" type="submit">
                {formatMessage("app.save", "Save")}
              </Button>
            </Flex>
          </Form>
        </Flex>
      </Card>
    </ModalContainer>
  );
};

export default TranslationsModal;
