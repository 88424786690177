import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  setObjectRolesPageNo,
  fetchObjectRolesList,
  deleteObjectRole,
  getObjectRoleById,
} from "./ducks";
import ObjectRolesModal from "./Modal/ObjectRolesModal";
import { TDispatch, TRootState } from "../../../redux/types";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { Flex } from "reflexbox";
import { useSwal } from "@hooks/useSwal";
import { useHistory, useLocation } from "react-router-dom";
import { useFormatMessage } from "@hooks/formatMessage";

import { deserialize } from "../../../api/jsonApiParser";
import { getParameterByName } from "../../../utils/toSearchParams";

import "../../../styles/index.scss";
import "./ObjectRoles.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const ObjectRoles: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addObjectRoleForm, setAddObjectRoleForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [genericTextFilter, setGenericTextFilter] = useState<string>("");

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { objectRolesList, moreObjectRoles, objectRolesPageNo } = useSelector(
    ({ ObjectRolesReducer: { objectRoles, moreObjectRoles, objectRolesPageNo } }: TRootState) => ({
      objectRolesList: objectRoles,
      moreObjectRoles,
      objectRolesPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchObjectRolesList());
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreObjectRoles) {
      setShowMoreSpinner(true);
      dispatch(
        fetchObjectRolesList(objectRolesPageNo, 10, {
          GenericText: genericTextFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
      });
    }
  };

  const filterObjectRoles = () => {
    dispatch(setObjectRolesPageNo(1)).then(() => {
      dispatch(
        fetchObjectRolesList(1, 10, {
          GenericText: genericTextFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setObjectRolesPageNo(1)).then(() => {
      dispatch(fetchObjectRolesList(1, 10));
    });
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getObjectRoleById(entry.id).then(ret => {
        if (ret) {
          setAddObjectRoleForm(deserialize(ret.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setAddObjectRoleForm({});
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterObjectRoles = () => {
    history.push({
      search: "",
    });
    filterObjectRoles();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterObjectRoles();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Object Role has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this object role?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove object role",
    }).then(value => {
      if (value) {
        deleteObjectRole(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterObjectRoles);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password && (
          <ObjectRolesModal
            closeModal={closeModal}
            initialData={addObjectRoleForm}
            fetchRoles={undefined}
            fetchObjects={undefined}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addObjectRoleBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewobjectrole", "Add Object Role")}
            onClick={() => {
              setAddObjectRoleForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={objectRolesList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.objectName", "Object Name")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.roleName", "Role Name")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"objectRoleRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold"> {item.objectName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.roleName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit User"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Object Role"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterObjectRoles}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default ObjectRoles;
