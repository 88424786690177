import * as yup from "yup";
import { setLocale } from "yup";

setLocale({
  mixed: {
    default: "required",
    required: "required",
  },
  string: {
    required: "required",
  },
  number: {
    required: "required",
  },
  object: {
    required: "required",
  },
});

const Step1Schema = yup.object().shape({
  id: yup.string(),
  firstName: yup.string().required(),
  middleName: yup.string().required(),
  lastName: yup.string().required(),
  birthDate: yup.date().required(),
  socialSecurityNumber: yup.string().required(),
  sex: yup.object().required().nullable(),
  completeAddress: yup.string().required(),
  language: yup.object().required().nullable(),
  companyId: yup.object().required().nullable(),
});

const Step2Schema = yup.object().shape({
  username: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  email: yup.string().email().required(),
  mobileNumber: yup.string().required(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

const Step3Schema = yup.object().shape({
  id: yup.string(),
});

export { Step1Schema, Step2Schema, Step3Schema };
