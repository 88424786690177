import { Dispatch } from "redux";
import http from "../../../api/http";
import { deserialize, serialize } from "../../../api/jsonApiParser";
import { LOADING } from "../../../constants";

export const saveDefaultVariables = payload => {
  return http.put("DefaultVariable", serialize(payload, "DefaultVariable"));
};

export const fetchDefaultVariables = (): any => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    return await http.get(`DefaultVariable/`).then((response: any) => {
      dispatch({ type: LOADING, payload: false });
      return deserialize(response.data);
    });
  };
};
