import { buildApplicationIdentifier } from "./applications";

const buildNewUserRolesList = (formData, initialData, applicationsList) => {
  const userRolesList = [];

  // Find user roles on formData
  applicationsList.forEach(application => {
    const applicationIdentifier = buildApplicationIdentifier(application);
    // If user changed role on form, and this changed role is different from old (if there's an old)
    if (
      (formData[applicationIdentifier] && !initialData[applicationIdentifier]) ||
      (formData[applicationIdentifier] &&
        initialData[applicationIdentifier] &&
        formData[applicationIdentifier].value !== initialData[applicationIdentifier].value)
    ) {
      userRolesList.push({
        roleId: formData[applicationIdentifier].value,
        application: application.id,
        applicationName: application.name,
        userId: formData.id,
      });
    }

    // Removes from formData reference
    delete formData[applicationIdentifier];
  });

  return userRolesList;
};

export { buildNewUserRolesList };
