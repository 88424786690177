import * as yup from "yup";

const LoginTokenEmailSchema = yup.object().shape({
  id: yup.string(),
  email: yup.string().email().required(),
});

const LoginTokenSMSSchema = yup.object().shape({
  id: yup.string(),
  mobilePhoneToken: yup.string().required(),
});

export { LoginTokenEmailSchema, LoginTokenSMSSchema };
