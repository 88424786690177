import React from "react";
import classNames from "classnames";

import "./Drawer.scss";
import { Flex } from "reflexbox";

interface Props {
  opened: boolean;
  id?: string;
}

class Drawer extends React.Component<Props, unknown> {
  render() {
    return (
      <Flex
        tabIndex={-1}
        id={this.props.id}
        className={classNames("drawerContainer", {
          opened: this.props.opened,
        })}
      >
        <div className="drawerContent">{this.props.opened && this.props.children}</div>
      </Flex>
    );
  }
}

export default Drawer;
