import styled from "@emotion/styled";

import { colors } from "@styles/colors";
import { TStyledLabel } from "@components/Label/types";
import { fontSize } from "styled-system";

const StyledLabel = styled.div<TStyledLabel>`
  display: flex;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  padding-bottom: 5px;
  color: ${({ light = true }) => (light ? colors.primaryLight : colors.primary)};
  ${fontSize};
`;

export { StyledLabel };
