import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";

import ConnectionsModalPassword from "./ModalPassword/ConnectionsModalPassword";
import ConnectionsModal from "./Modal/ConnectionsModal";
import { getParameterByName } from "../../../utils/toSearchParams";
import { deserialize } from "../../../api/jsonApiParser";
import {
  setConnectionsPageNo,
  fetchConnectionsList,
  deleteConnection,
  getConnectionById,
} from "./ducks";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
} from "@icr/react.uicomponents";
import { ReactComponent as IconKey } from "@assets/icons/key-temp.svg";

import { TDispatch, TRootState } from "../../../redux/types";
import { useSwal } from "@hooks/useSwal";
import { useHistory, useLocation } from "react-router-dom";
import { useFormatMessage } from "@hooks/formatMessage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Flex } from "reflexbox";
import "../../../styles/index.scss";
import "./Connections.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const Connections: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addConnectionForm, setaddConnectionForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);

  const [genericTextFilter, setGenericTextFilter] = useState<string>("");

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { connectionsList, moreConnections, connectionsPageNo } = useSelector(
    ({ ConnectionsReducer: { connections, moreConnections, connectionsPageNo } }: TRootState) => ({
      connectionsList: connections,
      moreConnections,
      connectionsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchConnectionsList());
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreConnections) {
      setShowMoreSpinner(true);
      dispatch(
        fetchConnectionsList(connectionsPageNo, 10, {
          GenericText: genericTextFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
      });
    }
  };

  const filterConnections = () => {
    dispatch(setConnectionsPageNo(1)).then(() => {
      dispatch(
        fetchConnectionsList(1, 10, {
          GenericText: genericTextFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setConnectionsPageNo(1)).then(() => {
      dispatch(fetchConnectionsList(1, 10));
    });
  };

  const openModal = (content, message?, entry?) => {
    getConnectionById(entry.id).then((ret: any) => {
      if (ret) {
        delete ret.data.attributes.password;
        setaddConnectionForm(deserialize(ret.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterConnections = () => {
    history.push({
      search: "",
    });
    filterConnections();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterConnections();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Connection has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this connection?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove connection",
    }).then(value => {
      if (value) {
        deleteConnection(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterConnections);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password ? (
          <ConnectionsModal
            closeModal={closeModal}
            initialData={addConnectionForm}
            fetchDatabaseTypes={undefined}
            fetchWebServices={undefined}
            fetchTenants={undefined}
          />
        ) : (
          <ConnectionsModalPassword closeModal={closeModal} initialData={addConnectionForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addConnectionBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewconnection", "Add Connection")}
            onClick={() => {
              setaddConnectionForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={connectionsList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.user", "User")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.webServiceName", "Web Service")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.dbName", "Database")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.hostName", "Host")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.databaseTypeName", "Database Type")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"connectionRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.username}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.webserviceName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.dbName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.hostName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.databaseTypeName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Connection"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <IconKey
                        id={"changePasswordEntry" + item.id}
                        title="Edit Password"
                        onClick={() => openModal(formMode.password, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Connection"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
                // more={
                // <React.Fragment>
                //   <Column>
                //     <div className="d-flex flex-row showChildOnHover">
                //       <Text light={false} fontSize="16px">
                //         {item.tenantName}
                //       </Text>
                //     </div>
                //   </Column>
                //   <Column>
                //     <div className="d-flex flex-row showChildOnHover">
                //       <Text light={false} fontSize="16px">
                //         {item.schemaDb}
                //       </Text>
                //     </div>
                //   </Column>
                //   <Column>
                //     <div className="d-flex flex-row showChildOnHover">
                //       <Text light={false} fontSize="16px">
                //         {item.port}
                //       </Text>
                //     </div>
                //   </Column>
                //   <Column>
                //     <div className="d-flex flex-row showChildOnHover">
                //       <Checkbox
                //         id="TableShowSqlInput"
                //         name="TableShowSqlInput"
                //         readOnly={true}
                //         value={(item.showSql as string) || ""}
                //         checked={item.showSql as boolean}
                //         label={formatMessage("app.showSql", "Show Sql")}
                //         wrapperProps={{ width: "20%" }}
                //       />
                //     </div>
                //   </Column>
                //   <Column>
                //     <div className="d-flex flex-row showChildOnHover">
                //       <Checkbox
                //         id="TableIsActiveInput"
                //         name="TableIsActiveInput"
                //         readOnly={true}
                //         value={(item.isActive as string) || ""}
                //         checked={item.isActive as boolean}
                //         label={formatMessage("app.isActive", "Is Active")}
                //         wrapperProps={{ width: "20%" }}
                //       />
                //     </div>
                //   </Column>
                // </React.Fragment>
                // }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterConnections}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Connections;
