import thunk from "redux-thunk";
import logger from "redux-logger";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore, Store } from "redux";

import { TRootState } from "./types";
import createReducer from "./rootReducer";
import en from "../constants/locales/en.json";

const historyS = createHashHistory();

/* eslint-disable no-underscore-dangle */
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(routerMiddleware(historyS), thunk, logger);

const i18nInitialState = {
  locale: "en",
  messages: {
    ...en,
  },
};

const reducer = createReducer(historyS);

const store = createStore(
  reducer as any,
  { intl: i18nInitialState } as any,
  composeEnhancers(middleware as any)
) as Store<TRootState>;

export { store, historyS };
