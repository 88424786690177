import React, { FC, useState } from "react";
import { Flex } from "reflexbox";
import classNames from "classnames";

import { TExpandableRow } from "@components/ExpandableRow/types";
import { ReactComponent as IconArrowDown } from "@assets/icons/arrow-down.svg";

import "./ExpandableRow.scss";
import { Button, EButtonType } from "../OldButton";
import { Column, Expandable, More, StyledExpandableRow, Top } from "./styles";

const ExpandableRow: FC<TExpandableRow> = props => {
  const [expanded, setExpanded] = useState(false);

  const { "data-cy": dataCy, expandable = false, top, bottom, more, id, key } = props;

  const toggleExpanded = e => {
    e.preventDefault();
    e.stopPropagation();
    setExpanded(prev => !prev);
  };

  return (
    <StyledExpandableRow data-cy={dataCy} id={id} key={key}>
      <Top onClick={e => expandable && toggleExpanded(e)}>
        {top}
        {expandable && (
          <Column icon>
            <Button
              className={classNames("icon-arrow", { rotated: expanded })}
              onClick={e => toggleExpanded(e)}
              buttonType={EButtonType.ButtonIcon}
            >
              <IconArrowDown />
            </Button>
          </Column>
        )}
      </Top>
      <Flex width="100%">{bottom}</Flex>
      {expandable && (
        <Expandable expanded={expanded} onClick={e => toggleExpanded(e)}>
          <More>{more && more}</More>
        </Expandable>
      )}
    </StyledExpandableRow>
  );
};

export default ExpandableRow;
