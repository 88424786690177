import React, { FC, useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import SMSLogs from "./SmsLog/SmsLog";
import LogonLog from "./LogonLog/LogonLog";
import EmailLogs from "./EmailLog/EmailLog";
import VersionLogs from "./VersionLog/VersionLog";
import Header from "../../components/Header";

import "../../styles/index.scss";
import "./LogsBase.scss";
import { useFormatMessage } from "@hooks/formatMessage";
import { HeaderTab } from "@components/HeaderTab";
import { HeaderText } from "@components/HeaderText";
import { ListContainer } from "@components/ListContainer";

interface Props {
  locales?: any;
}

const tabs = [
  { path: "logonLogs", renderComponent: props => <LogonLog {...props} /> },
  { path: "emailLogs", renderComponent: props => <EmailLogs {...props} /> },
  { path: "smsLogs", renderComponent: props => <SMSLogs {...props} /> },
  { path: "versionLogs", renderComponent: props => <VersionLogs {...props} /> },
];

const LogsBase: FC<Props> = props => {
  const { locales } = props;

  const [currentTab, setCurrentTab] = useState("");
  const { path, url } = useRouteMatch();
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const location = useLocation();
  const state = location.state as Record<string, string>;

  useEffect(() => {
    if (state) {
      setCurrentTab(state.currentTab);
    }
  }, [state]);

  return (
    <ListContainer>
      <Header title="Logs" locales={locales}>
        {tabs.map(tab => (
          <HeaderTab active={currentTab === tab.path}>
            <Link to={{ pathname: `${url}/${tab.path}`, state: { currentTab: tab.path } }}>
              <HeaderText active={currentTab === tab.path}>
                {formatMessage(`logs.${tab.path}`)}
              </HeaderText>
            </Link>
          </HeaderTab>
        ))}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route path={`${path}/${tab.path}`}>{tab.renderComponent({ location, history })}</Route>
        ))}
      </Switch>
    </ListContainer>
  );
};

export default LogsBase;
