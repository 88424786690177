// System
import React from "react";
import { Observable } from "rxjs";
import { map } from "rxjs/internal/operators/map";
import { toastr } from "react-redux-toastr";
// Models
import { Method, _Headers, Body } from "./types";
// Actions
import { logOut } from "../shared/state/AuthenticateActions";
// Externals
import swal from "@sweetalert/with-react";

const baseUrl = process.env.REACT_APP_REST_API_URL;

export default class BaseRequestModel {
  constructor(
    private url: string,
    private method: Method,
    private headers: _Headers,
    private body?: Body
  ) {
    this.url = url;
    this.method = method || "GET";
    this.headers = headers || {};
    this.body = body;
  }

  request(): Observable<any> {
    return new Observable(observer => {
      fetch(`${baseUrl}${this.url}`, {
        method: this.method,
        headers: this.headers,
        body: this.body,
      })
        .then((r: any) => {
          // if (r.status === 200) return r.json();
          // else return {};
          return r.json();
        })
        .then((data: any) => {
          observer.next(data);
          observer.complete();
        })
        .catch((e: any) => {
          observer.error(e);
        });
      return () => {
        // clean up on unsubscribe
      };
    }).pipe(
      map((ret: any) => {
        // New error treatment
        // Set success to true
        ret.success = true;
        // Check if success is true
        if (ret?.errors && ret?.errors?.length > 0) {
          // Set success to false
          ret.success = false;
          // Assign
          const errorList = ret.errors;
          // Create text to swal message
          let swalMessage = "";
          // Treat all error items from array
          errorList.forEach(error => {
            // Treat unauthorized and forbidden
            if (error.code === "403" || error.code === "401") {
              logOut();
            }
            if (errorList.length === 1) {
              toastr.error(error.title, {
                timeOut: 0,
                component: (
                  <div id="detailErrorOnToastr" className="rrt-text">
                    {error.detail} ({error.code})
                  </div>
                ),
              });
            } else {
              swalMessage += `- ${
                error.detail.length > 30 ? error.detail.slice(0, 30) + "..." : error.detail
              } (${error.code})\n`;
            }
          });
          // Show errors on swal if have more than one
          if (errorList.length > 1) {
            this.treatSwalMessage(swalMessage);
          }
        }
        return ret;
      })
    );
  }

  private treatSwalMessage(message: string): void {
    swal({
      buttons: {
        ok: {
          text: "Ok",
          className: "modal-main-button primary",
        },
      },
      className: "overflow-auto",
      height: "100%",
      width: "100%",
      icon: "error",
      closeOnEsc: true,
      title: "Some errors have ocurred",
      text: message,
    });
  }
}
