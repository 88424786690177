import { Dispatch } from "redux";

import http from "@api/http";
import { store } from "@redux/store";
import { TResponse } from "@api/types";
import { deserialize } from "@api/jsonApiParser";
import { FETCHED_ROLES, ROLES_IS_LOADING } from "@features/User/Roles/ducks";

export const fetchRoles = () => async (dispatch: Dispatch): Promise<Record<string, unknown>[]> => {
  dispatch({ type: ROLES_IS_LOADING, payload: true });
  const page = store.getState().RolesReducer.rolesPageNo;

  // TODO: filter/order not yet implemented
  // const filters = store.getState().roles.filter;
  // const orders = store.getState().roles.order;
  // const query = `User/UserPaginated?Page=${page}&PageSize=${pageSize}`;
  // const filterQuery = filters ? buildFilterQueryString(filters, query) : query;
  // const orderQuery = orders ? buildOrderQueryString(orders, filterQuery) : filterQuery;

  return await http.get("Role/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: ROLES_IS_LOADING, payload: false });
    dispatch({
      type: FETCHED_ROLES,
      payload: { roles: response.data.map(role => deserialize(role)), page },
    });

    return response.data.map(role => deserialize(role));
  });
};
