import * as React from "react";
import classNames from "classnames";

import { EInputTextType } from "../index";
import { formatNumber } from "../../../utils/react";
import { exists } from "../../../utils/variableEvaluation";
import { ReactComponent as IconSearch } from "../../../assets/icons/search.svg";
import { ReactComponent as IconCalendar } from "../../../assets/icons/calendar.svg";
import styles from "./InputText.module.scss"; // Import css modules stylesheet as styles

interface IProps {
  isFocused: boolean;
  isLoading: boolean;
  name?: string;
  value?: string[] | string | number;
  placeholder: string;
  type: string;
  onChange?: (name: string, value: string) => void;
  additionalClass?: string;
  isDisabled?: boolean;
  inputTextType?: EInputTextType;
  onBlur?: any;
  autoFocus?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  min?: number;
  max?: number;
  step?: string;
  filtering?: boolean;
  isDropdown?: boolean;
}

interface IState {
  isFocused: boolean;
}

export class InputText extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  private inputRef: HTMLInputElement;
  private focusTimeoutId;

  componentDidMount() {
    this.focusTimeoutId = setTimeout(() => {
      if (this.props.isFocused) {
        this.inputRef.focus();
      }
    }, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeoutId);
  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    this.props.onChange && this.props.onChange(name, value);
  };

  private handleFocus = () => {
    this.setState({ isFocused: true });
    // console.log("handleChange",e.currentTarget);
  };

  private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    if (this.props.type === "date") {
      this.setState({ isFocused: false }, () => {
        this.props.onChange(name, this.determineValue(value));
      });
    } else {
      this.setState({ isFocused: false });
    }
    this.props.onBlur && this.props.onBlur(e);
  };

  determineValue = val => {
    if (this.props.type === "date") {
      return this.formatDate(val);
    } else {
      if (this.state.isFocused) {
        return val;
      } else {
        if (this.props.type === "money") {
          return formatNumber(val);
          // } else if (this.props.type === "percentage") {
          //     return val ? (val + "%") : "";
        } else {
          return val;
        }
      }
    }
  };

  formatDate = val => {
    if (val === "") return val;
    let value = val;
    if (!value) return val;
    if (this.state.isFocused) {
      const parts = val.split(".");
      if (parts.length === 1) {
        if (val.length > 2) {
          value = val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length === 2) {
        if (parts[0].length === 1 && parts[1].length === 0) {
          value = "0" + parts[0] + ".";
        }
        if (parts[1].length === 3) {
          value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
        }
      }
      if (parts.length === 3) {
        if (parts[1].length === 1 && parts[2].length === 0) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
        if (parts[1].length === 2) {
          value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
        }
      }
    } else {
      const parts = val.split(".");
      if (parts.length === 1) {
        if (val.length > 2) {
          return val[0] + val[1] + "." + val[2];
        }
      }
      if (parts.length === 2) {
        if (parts[0].length === 1) {
          return "0" + parts[0] + ".";
        }
        if (parts[1].length > 2) {
          return parts[0] + "." + parts[1][0] + parts[1][1] + ".";
        }
      }
      if (parts.length === 3) {
        if (parts[2].length === 2) {
          if (parseInt(parts[2]) < 45) {
            value = parts[0] + "." + parts[1] + "." + "20" + parts[2];
          } else {
            value = parts[0] + "." + parts[1] + "." + "19" + parts[2];
          }
        } else if (parts[2].length === 4) {
          value = parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[0].length === 1) {
          value = "0" + parts[0] + "." + parts[1] + "." + parts[2];
        }
        if (parts[1].length === 1) {
          value = parts[0] + ".0" + parts[1] + "." + parts[2];
        }
      }
    }
    return value;
  };

  public render() {
    const {
      name,
      value,
      placeholder,
      type,
      isFocused,
      isLoading,
      additionalClass,
      isDisabled,
      readOnly,
      min,
      max,
      step,
      maxLength,
      inputTextType,
      filtering,
      isDropdown,
    } = this.props;

    const inputClass = classNames(styles.inputText, {
      [additionalClass]: exists(additionalClass),
      [styles.hasValue]: exists(value),
      [styles.textStandard]: inputTextType === EInputTextType.Standard,
      [styles.textMaterial]: inputTextType === EInputTextType.Material,
    });

    return (
      <div className={styles.inputContainer}>
        <input
          name={name}
          type={type === "money" || type === "date" ? "text" : type}
          min={min}
          max={max}
          step={step}
          placeholder={placeholder}
          value={this.determineValue(value)}
          maxLength={maxLength || 255}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          readOnly={readOnly}
          onChange={this.handleChange}
          className={inputClass}
          ref={input => isFocused && (this.inputRef = input)}
          autoFocus={isFocused}
          disabled={isDisabled || isLoading}
          autoComplete="off"
        />

        <div
          className={classNames({
            [styles.filtering]: filtering,
            [styles.dropdown]: isDropdown,
            [styles.language]: name === "languageInput",
          })}
        />

        {!isDropdown ? (
          type === "date" ? (
            <IconCalendar />
          ) : filtering ? (
            <IconSearch />
          ) : null
        ) : null}
      </div>
    );
  }
}
