import * as React from "react";
import { defined } from "../../utils/variableEvaluation";
import "./InputWrapper.scss";

interface IProps {
  inputWrapperStyle: React.CSSProperties;
  inputWrapperClass: string;
}

export class InputWrapper extends React.Component<IProps, unknown> {
  render() {
    const { inputWrapperStyle, children } = this.props;

    return (
      <div
        className={"input-wrapper " + this.props.inputWrapperClass}
        style={defined(inputWrapperStyle) ? inputWrapperStyle : {}}
      >
        {children}
      </div>
    );
  }
}
