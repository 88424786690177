import styled from "@emotion/styled";

const ListParent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  @media (max-width: 575px) {
    padding-right: 0;
    padding-left: 0;
    padding-top: 32px;
  }
`;

const ListWrapper = styled.div`
  position: absolute;
  overflow: auto;
  inset: 0;
  padding: 50px 32px 80px 32px;
`;

const Header = styled.div`
  display: flex;
  height: 17px;
  width: 100%;
  padding: 0 32px;
  margin-bottom: 24px;

  @media (max-width: 845px) {
    display: none;
  }
`;

const BlankRow = styled.div`
  box-sizing: border-box;
  height: 100px;
  border: 2px dashed #afafb1;
  border-radius: 8px;
  line-height: 105px;
  color: #afafb1;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  background: none;
`;

export { ListParent, ListWrapper, Header, BlankRow };
