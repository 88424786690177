import { buildFilterQueryString, deserialize } from "@api/jsonApiParser";

import { TDispatch } from "../../../redux/types";
import * as constants from "../../../constants";
import { INCREMENT_LOGONLOGS_PAGE_NO } from "../LogonLog/ducks";
import { TResponse } from "@api/types";
import http from "@api/http";
import { Dispatch } from "redux";

export interface SMSLogState {
  smsLogs: any;
  smsLogsPageNo: any;
  moreSMSLogs: boolean;
  filter: any;
}

const initialState = {
  smsLogs: [],
  smsLogsPageNo: 1,
  moreSMSLogs: true,
  filter: undefined,
};

export const FETCHED_SMSLOGS = "FETCHED_SMSLOGS";
export const SET_FILTERING_CRITERIA_SMSLOGS = "SET_FILTERING_CRITERIA_SMSLOGS";
export const SET_SMSLOGS_PAGE_NO = "SET_SMSLOGS_PAGE_NO";
export const INCREMENT_SMSLOG_PAGE_NO = "INCREMENT_SMSLOG_PAGE_NO";

export const SMSLogReducer = (state: SMSLogState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_SMSLOGS: {
      let smsLogs = [];
      const moreSMSLogs = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.smsLogsPageNo === 1 || action.page === 1) {
        smsLogs = [...action.payload];
      } else {
        smsLogs = [...state.smsLogs, ...action.payload];
      }

      return { ...state, smsLogs: smsLogs, moreSMSLogs: moreSMSLogs };
    }

    case SET_SMSLOGS_PAGE_NO: {
      const moreSMSLogs = !(
        state.smsLogs.length <
        (state.smsLogsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, smsLogsPageNo: action.payload, moreSMSLogs: moreSMSLogs };
    }

    case SET_FILTERING_CRITERIA_SMSLOGS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_LOGONLOGS_PAGE_NO: {
      return {
        ...state,
        smsLogsPageNo: state.smsLogsPageNo + 1,
      };
    }
  }
  return state;
};

export const setSMSLogsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_SMSLOGS_PAGE_NO,
      payload,
    });
  };
};

export const fetchSMSLogList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `SMSLog/SMSLogPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse) => {
        dispatch({
          type: FETCHED_SMSLOGS,
          payload: (response.data as Record<string, unknown>[]).map(smsLogList =>
            deserialize(smsLogList)
          ),
          page: page,
        });

        dispatch({ type: INCREMENT_SMSLOG_PAGE_NO });

        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};
