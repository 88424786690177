import { parseDate } from "./react";
import { EValidationMessageType } from "../components/ValidationMessage";

// Tests //

export enum EValidationTestType {
  hasValue = "hasValue",
  hasAnyValue = "hasAnyValue",
  acceptAGBS = "acceptAGBS",
  isPasswordMatch = "isPasswordMatch",
  isEmail = "isEmail",
  isPhoneNumber = "isPhoneNumber",
  isPassword = "isPassword",
  isPin = "isPin",
  isSsn = "isSsn",
  isDate = "isDate",
  isFirstDayOfTheMonth = "isFirstDayOfTheMonth",
  isLastDayOfTheMonth = "isLastDayOfTheMonth",
  isBeschaftingungsgrad = "isBeschaftingungsgrad",
  isNumber = "isNumber",
  isPositive = "isPositive",
  isText = "isText",
  isDegree = "isDegree",
  dateBetween = "dateBetween",
}

// Test Messages //
export const VALIDATION_MESSAGE = {
  hasValue() {
    return {
      message: "Pflichtfeld darf nicht leer sein",
      type: EValidationMessageType.Error,
    };
  },
  hasAnyValue() {
    return {
      message: "Pflichtfeld darf nicht leer sein",
      type: EValidationMessageType.Error,
    };
  },
  isPasswordMatch() {
    return {
      message: "Passwörter stimmen nicht überein", // tslint:disable-line
      type: EValidationMessageType.Error,
    };
  },
  isEmail() {
    return {
      message: "E-Mail ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isPhoneNumber() {
    return {
      message: "Telefonnummer ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isPin() {
    return {
      message: "PIN ist ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isPassword() {
    return {
      message:
        "Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens 1 Sonderzeichen enthalten.",
      type: EValidationMessageType.Error,
    };
  },
  isSsn() {
    return {
      message: "AHV-Nummer ungültig",
      type: EValidationMessageType.Error,
    };
  },
  isDate() {
    return {
      message: "Bitte korrektes Datumsformat “DD.MM.YYYY” verwenden",
      type: EValidationMessageType.Error,
    };
  },
  isFirstDayOfTheMonth() {
    return {
      message: "Please select the first day of the month",
      type: EValidationMessageType.Error,
    };
  },
  isLastDayOfTheMonth() {
    return {
      message: "Please select the last day of the month",
      type: EValidationMessageType.Error,
    };
  },
  isBeschaftingungsgrad() {
    return {
      message: "Beschäftigungsgrad in Prozent eingeben. (0 - 140)",
      type: EValidationMessageType.Error,
    };
  },
  isNumber() {
    return {
      message: "Bitte eine Nummer eingeben.",
      type: EValidationMessageType.Error,
    };
  },
  isPositive() {
    return {
      message: "Number between 0 and 100.",
      type: EValidationMessageType.Error,
    };
  },
  dateBetween(messages?) {
    let message = "";
    if (!messages.from && !messages.until) {
      message = "Bitte andere Felder ausfüllen";
    } else if (messages.from && !messages.until) {
      message = `Das Datum sollte nach oder gleich dem ${parseDate(messages.from)} liegen.`;
    } else {
      message = `Das Datum sollte zwischen ${parseDate(messages.from)} und ${parseDate(
        messages.until
      )}`;
    }
    return {
      message: message,
      type: EValidationMessageType.Error,
    };
  },
};
