import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TDispatch, TRootState } from "../../../redux/types";
import { deleteObject, fetchObjectsList, getObjectById, setObjectsPageNo } from "./ducks";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { Flex } from "reflexbox";
import { useSwal } from "@hooks/useSwal";
import { useHistory, useLocation } from "react-router-dom";
import { useFormatMessage } from "@hooks/formatMessage";

import ObjectsModal from "./Modal/ObjectsModal";

import { getParameterByName } from "../../../utils/toSearchParams";
import { deserialize } from "../../../api/jsonApiParser";

import "../../../styles/index.scss";
import "./Objects.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const Objects: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addObjectForm, setAddObjectForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [genericTextFilter, setGenericTextFilter] = useState<string>("");

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { objectsList, moreObjects, objectsPageNo } = useSelector(
    ({ ObjectsReducer: { objects, moreObjects, objectsPageNo } }: TRootState) => ({
      objectsList: objects,
      moreObjects,
      objectsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchObjectsList());
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreObjects) {
      setShowMoreSpinner(true);
      dispatch(
        fetchObjectsList(objectsPageNo, 10, {
          GenericText: genericTextFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
      });
    }
  };

  const filterObjects = () => {
    dispatch(setObjectsPageNo(1)).then(() => {
      dispatch(
        fetchObjectsList(1, 10, {
          GenericText: genericTextFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setObjectsPageNo(1)).then(() => {
      dispatch(fetchObjectsList(1, 10));
    });
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getObjectById(entry.id).then(ret => {
        if (ret) {
          setAddObjectForm(deserialize(ret.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setAddObjectForm({});
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterObjects = () => {
    history.push({
      search: "",
    });
    filterObjects();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterObjects();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Object has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this object?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove object",
    }).then(value => {
      if (value) {
        deleteObject(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterObjects);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password && (
          <ObjectsModal closeModal={closeModal} initialData={addObjectForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addObjectBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewobject", "Add Object")}
            onClick={() => {
              setAddObjectForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={objectsList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.objectName", "Object")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.applicationName", "Application")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.objectTypeName", "Object Type")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"objectRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.objectName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.applicationName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.objectTypeName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Object"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Object"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterObjects}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Objects;
