import * as yup from "yup";

const LoginSchema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required(),
});

const Step1Schema = yup.object().shape({
  username: yup.string().required(),
});

const ResetPasswordStep2Schema = yup.object().shape({
  newPassword: yup.string().required(),
});

export { LoginSchema, Step1Schema, ResetPasswordStep2Schema };
