import * as yup from "yup";

const ConnectionSchema = yup.object().shape({
  id: yup.string(),
  tenantId: yup.object().required().nullable(),
  webServiceId: yup.object().required().nullable(),
  databaseType: yup.object().required().nullable(),
  schemaDb: yup.string().required(),
  hostName: yup.string().required(),
  showSql: yup.boolean(),
  isActive: yup.boolean(),
  dbName: yup.string().required(),
  username: yup.string().required(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

export { ConnectionSchema };
