import { Flex } from "reflexbox";
import styled from "@emotion/styled";
import { space } from "styled-system";
import { colors } from "@styles/colors";

import { TStyledInput, TStyledWrapper } from "./types";

const StyledInput = styled.input<TStyledInput>`
  ${space};
  padding: 5px 0;
  width: ${({ width }) => width};
  border: none;
  background: transparent;
  color: ${colors.primary};
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 16px;
  border-bottom: 2px solid ${({ hasError }) => (hasError ? colors.red : colors.grey)};
  border-radius: 0;
  transition: border-bottom linear 0.2s;

  &:hover,
  &:focus {
    border-bottom: 2px solid ${({ hasError }) => (hasError ? colors.red : colors.grey)};
    outline: none;
  }
`;

const StyledWrapper = styled(Flex)<TStyledWrapper>`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  width: 100%;
  flex-direction: column;
`;

export { StyledInput, StyledWrapper };
