import React, { FC, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import Header from "@components/Header";
import Database from "./Database/Database";
import Translation from "./Translation/Translation";
import Versions from "./Versions/Versions";
import DatabaseExport from "./DatabaseExport/DatabaseExport";
import DatabaseImport from "./DatabaseImport/DatabaseImport";
import DefaultVariable from "./DefaultVariables/DefaultVariables";

import { HeaderTab } from "@components/HeaderTab";
import { HeaderText } from "@components/HeaderText";

import { useFormatMessage } from "@hooks/formatMessage";

import { TSystemConfigurationBaseProps } from "./types";
import { ListContainer } from "@components/ListContainer";

const tabs = [
  { path: "database", renderComponent: props => <Database {...props} /> },
  { path: "translation", renderComponent: props => <Translation {...props} /> },
  { path: "versions", renderComponent: props => <Versions {...props} /> },
  { path: "databaseexport", renderComponent: props => <DatabaseExport {...props} /> },
  { path: "databaseimport", renderComponent: props => <DatabaseImport {...props} /> },
  { path: "defaultvariables", renderComponent: props => <DefaultVariable {...props} /> },
];

const SystemConfigurationBase: FC<TSystemConfigurationBaseProps> = props => {
  const { locales, history } = props;

  const [currentTab, setCurrentTab] = useState("");
  const { path, url } = useRouteMatch();
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const state = location.state as Record<string, string>;

  useEffect(() => {
    if (state) {
      setCurrentTab(state.currentTab);
    }
  }, [state]);

  return (
    <ListContainer>
      <Header title="System Configuration" locales={locales}>
        {tabs.map(tab => (
          <HeaderTab active={currentTab === tab.path}>
            <Link to={{ pathname: `${url}/${tab.path}`, state: { currentTab: tab.path } }}>
              <HeaderText active={currentTab === tab.path}>
                {formatMessage(`systemconfiguration.tab${tab.path}`)}
              </HeaderText>
            </Link>
          </HeaderTab>
        ))}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route path={`${path}/${tab.path}`}>{tab.renderComponent({ location, history })}</Route>
        ))}
      </Switch>
    </ListContainer>
  );
};

export default SystemConfigurationBase;
