import * as yup from "yup";

const TenantsSchema = yup.object().shape({
  id: yup.string(),
  companyId: yup.object().required().nullable(),
  tenantCode: yup.string().required(),
  tenantName: yup.string().required(),
  description: yup.string().nullable(),
  insuranceId: yup.string().nullable(),
});

export { TenantsSchema };
