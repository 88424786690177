import { store } from "../../redux/store";
import * as constants from "../../constants";
import { ACTION } from "../../shared/models/action";
import { TAction, TDispatch } from "../../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { buildFilterQueryString, buildOrderQueryString, deserialize } from "@api/jsonApiParser";
import { Dispatch } from "redux";

export const FETCHED_SESSIONS = "FETCHED_SESSIONS";
export const SET_SESSIONS_PAGE_NO = "SET_SESSIONS_PAGE_NO";
export const SET_ORDER_CRITERIA_SESSIONS = "SET_ORDER_CRITERIA_SESSIONS";
export const SET_FILTERING_CRITERIA_SESSIONS = "SET_FILTERING_CRITERIA_SESSIONS";
export const INCREMENT_SESSIONS_PAGE_NO = "INCREMENT_SESSIONS_PAGE_NO";

export interface SessionState {
  sessions: any;
  sessionsPageNo: any;
  moreSessions: any;
  filter: any;
  order: Array<string>;
}

const initialState = {
  sessions: [],
  sessionsPageNo: 1,
  moreSessions: true,
  filter: {},
  order: [],
};

export const SessionReducer = (state: SessionState = initialState, action: TAction) => {
  const orderedSessions: Array<string> = [];

  switch (action.type) {
    case SET_SESSIONS_PAGE_NO: {
      const moreSessions = !(
        state.sessions.length <
        (state.sessionsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, sessionsPageNo: action.payload, moreSessions: moreSessions };
    }

    case FETCHED_SESSIONS: {
      const { payload } = action;
      const { page, sessions } = payload;

      let newSessions = [];
      const moreSessions = !(sessions.length < constants.PAGINATION_SIZE);

      if (state.sessionsPageNo === 1 || page === 1) {
        newSessions = [...sessions];
      } else {
        newSessions = [...state.sessions, ...sessions];
      }

      return { ...state, sessions: newSessions, moreSessions: moreSessions };
    }

    case SET_FILTERING_CRITERIA_SESSIONS: {
      if (!action.payload) {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload };
      }
    }

    case INCREMENT_SESSIONS_PAGE_NO: {
      return {
        ...state,
        sessionsPageNo: state.sessionsPageNo + 1,
      };
    }

    // Adds, removes or sets ordering criteria
    case SET_ORDER_CRITERIA_SESSIONS: {
      switch (action.payload.method) {
        case "add":
          if (state.order.includes(action.payload.value)) {
            // if order for a field is set, remove it
            return {
              ...state,
              sessionsPageNo: 1,
              order: [...state.order.filter(order => order !== action.payload.value)],
            };
          } else {
            return {
              ...state,
              sessionsPageNo: 1,
              order: [...state.order.concat(action.payload.value)],
            };
          }
        case "remove":
          return {
            ...state,
            sessionsPageNo: 1,
            order: [...state.order.filter(order => order !== action.payload.value)],
          };
        case "set":
          for (const key in action.payload.value) {
            if (action.payload.value[key] !== "") {
              orderedSessions.push(key + action.payload.value[key]);
            }
          }
          return { ...state, sessionsPageNo: 1, order: orderedSessions };
        case "reset":
          return { ...state, sessionsPageNo: 1, order: [] };
      }
    }
  }

  return state;
};

export const setSessionsPageNo = (payload: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: SET_SESSIONS_PAGE_NO,
      payload,
    });
  };
};

export const setSessionsOrderCriteria = (payload: Record<string, unknown>): ACTION => ({
  type: SET_ORDER_CRITERIA_SESSIONS,
  payload,
});

export const setSessionsFilterCriteria = (payload: Record<string, unknown>): ACTION => ({
  type: SET_FILTERING_CRITERIA_SESSIONS,
  payload,
});

export const fetchSessionsList = (pageSize = 10) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `Session/SessionPaginated?Page=${
      store.getState().SessionReducer.sessionsPageNo
    }&PageSize=${pageSize}`;

    const filters = store.getState().SessionReducer.filter;
    const orders = store.getState().SessionReducer.order;
    const filterQuery = filters ? buildFilterQueryString(filters, query) : query;
    const orderQuery = orders ? buildOrderQueryString(orders, filterQuery) : filterQuery;

    return await http.get(orderQuery).then((response: TResponse) => {
      dispatch({
        type: FETCHED_SESSIONS,
        payload: {
          sessions: (response.data as Record<string, unknown>[]).map(sessions =>
            deserialize(sessions)
          ),
          page: store.getState().SessionReducer.sessionsPageNo,
        },
      });

      dispatch({ type: INCREMENT_SESSIONS_PAGE_NO });

      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const deleteSession = (id: string): Promise<TResponse> => {
  return http.delete("Session/" + id);
};
