import * as yup from "yup";

const CompanySchema = yup.object().shape({
  id: yup.string(),
  companyName: yup.string().required(),
  emailContact: yup.string().required(),
  parentCompanyId: yup.object().nullable(),
});

export { CompanySchema };
