import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { useDispatch } from "react-redux";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { Button, TextButton } from "@icr/react.uicomponents";
import { FormSelect as Select } from "@components/Form/Select";
import { InputFile } from "@components/Form/InputFile";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { TenantSettingsSchema } from "./schema";
import { uploadFileTenantSetting } from "../ducks";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { fetchTenantSettingTypes } from "../../../../actions/enumerator";
import { fetchApplications } from "../../../../reducers/enumerator";
import { fetchTenantsToDropdown } from "../../Tenant/ducks";
import { useFormatMessage } from "@hooks/formatMessage";
import { TDispatch } from "../../../../redux/types";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TenantSettingsModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;

  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const [tenantsList, setTenants] = useState<any>();
  const [applicationsList, setApplications] = useState<any>();
  const [tenantSettingTypesList, setTenantSettingTypes] = useState<any>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    dispatch(fetchTenantsToDropdown()).then(val => setTenants(val));
    dispatch(fetchApplications()).then(val => setApplications(val));
    dispatch(fetchTenantSettingTypes()).then(val => setTenantSettingTypes(val));
  }, [dispatch]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Setting has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const info = new FormData();
    info.append("files", formData.files[0]);

    swalConfirmationMessage({
      text: "Do you really want to upload this tenant setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Upload Setting",
    }).then(value => {
      if (value) {
        uploadFileTenantSetting(
          formData.tenantId ? formData.tenantId.value : undefined,
          formData.applicationCode ? formData.applicationCode.value : undefined,
          formData.type ? formData.type.value : undefined,
          formData.id,
          info
        ).then(ret => {
          if (ret.success) showSuccessMessage("created");
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={TenantSettingsSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="tenantId"
              name="tenantId"
              label={formatMessage("app.tenantId", "Tenant")}
              options={tenantsList?.map(option => ({
                value: option.id,
                label: option.tenantName,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="applicationCode"
              name="applicationCode"
              label={formatMessage("app.applicationCode", "Application")}
              options={applicationsList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
            <Select
              id="type"
              name="type"
              label={formatMessage("app.type", "Type")}
              options={tenantSettingTypesList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <InputFile
              id="files"
              name="files"
              label={formatMessage("app.files", "File")}
              multiple={true}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default TenantSettingsModal;
