import styled from "@emotion/styled";

import { colors } from "@styles/colors";
import { TStyledCheckbox } from "@components/Checkbox/types";

import checkmarkBold from "@assets/icons/checkmark-bold.svg";

const StyledCheckbox = styled.div<TStyledCheckbox>`
  height: 26px;
  width: 26px;
  border-radius: 13px;
  border: 2px solid ${({ checked }) => (checked ? colors.greyDark : colors.grey)};
  cursor: pointer;
  background: ${({ checked }) => checked && `url(${checkmarkBold}) center / contain no-repeat`};
  background-size: 10px;
`;

export { StyledCheckbox };
