import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import {
  Button,
  TextButton,
  Form,
  FormInput as Input,
  FormSelect as Select,
} from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";
import { ReactComponent as IconArrowLeftBlue } from "../../../assets/icons/arrow-left-blue.svg";

import { TProps } from "./types";
import { FolderSchema } from "./schema";
import { createFolder, changeFolder } from "../ducks";
import { useSwal } from "@hooks/useSwal";

setLocale({
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const FoldersModal: FC<TProps> = props => {
  const { parentFolderList, tenantList, folderTypeList, closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "Folder has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const folderLoc = {
      ...formData,
      parentFolderId: formData.parentFolderId ? formData.parentFolderId.value : undefined,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
      folderType: formData.folderType ? formData.folderType.value : undefined,
    };

    if (isEditing) {
      // Confirm action
      swalConfirmationMessage({
        text: "Do you really want to change this folder?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeFolder(formData.id, folderLoc).then(res => {
            if (res.success) successfulMessage("changed");
          });
        }
      });
    } else {
      // Confirm action
      swalConfirmationMessage({
        text: "Do you really want to create this folder?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Folder",
      }).then(value => {
        if (value) {
          delete folderLoc.id;

          createFolder(folderLoc).then(res => {
            if (res.success) successfulMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={FolderSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="folderName"
              name="folderName"
              label={formatMessage("app.folderName", "Folder Name")}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="parentFolderId"
              name="parentFolderId"
              label={formatMessage("app.parentFolderId", "Parent Folder")}
              options={parentFolderList.map(option => {
                return {
                  value: option.id,
                  label: option.folderName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="tenantId"
              name="tenantId"
              label={formatMessage("app.tenantId", "Tenant")}
              options={tenantList.map(option => {
                return {
                  value: option.id,
                  label: option.tenantName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="folderType"
              name="folderType"
              label={formatMessage("app.folderType", "Folder Type")}
              options={folderTypeList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default FoldersModal;
