import * as yup from "yup";

const WebServicePasswordSchema = yup.object().shape({
  id: yup.string(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  repeatPassword: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
});

export { WebServicePasswordSchema };
