import React from "react";

import LogsBase from "../features/Logs/LogsBase";
import UserBase from "../features/User/UserBase";
import Session from "../features/Session/Session";
import Companies from "../features/Company/Company";
import TenantBase from "../features/Tenant/TenantBase";
import ConfigurationBase from "../features/Configuration/ConfigurationBase";
import SystemConfigurationBase from "../features/SystemConfiguration/SystemConfigurationBase";
import FileStructure from "../features/FileStructure/FileStructure";

const privateRoutes = [
  {
    path: "/users",
    component: <UserBase />,
  },
  {
    path: "/configuration",
    component: <ConfigurationBase />,
  },
  {
    path: "/systemconfiguration",
    component: <SystemConfigurationBase />,
  },
  {
    path: "/sessions",
    component: <Session />,
  },
  {
    path: "/company",
    component: <Companies />,
  },
  {
    path: "/tenant",
    component: <TenantBase />,
  },
  {
    path: "/logs",
    component: <LogsBase />,
  },
  {
    path: "/filestructure",
    component: <FileStructure />,
  },
];

export default privateRoutes;
