import { Link } from "react-router-dom";
import { defined } from "../../utils/variableEvaluation";
import { renderSwitch } from "../../utils/react";
import * as React from "react";
import classNames from "classnames";
// import './Button.scss';
import "./Button.scss";
import Spinner from "../Spinner";

export enum EButtonType {
  Button = "Button",
  ButtonText = "Button Text",
  ButtonIcon = "Button Icon",
}

enum EButtonActionType {
  InternalLink = "Internal Link",
  ExternalLink = "External Link",
  NormalButton = "Normal Button",
}

interface IProps {
  buttonType?: EButtonType;
  to?: string;
  href?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  submit?: boolean;
  className?: string;
  onClick?: any;
  dataCyName?: string;
}

export class Button extends React.Component<IProps> {
  private action(): string {
    if (defined(this.props.to)) {
      return EButtonActionType.InternalLink;
    } else if (defined(this.props.href)) {
      return EButtonActionType.ExternalLink;
    } else {
      return EButtonActionType.NormalButton;
    }
  }

  private renderButton() {
    const {
      isDisabled,
      buttonType,
      to,
      href,
      children,
      className,
      onClick,
      submit,
      dataCyName,
      id,
      ...remainingProps
    } = this.props;

    const buttonClass = classNames(className, {
      button: buttonType === EButtonType.Button,
      "button-text": buttonType === EButtonType.ButtonText,
      "button-icon": buttonType === EButtonType.ButtonIcon,
    });

    return renderSwitch(this.action(), {
      [EButtonActionType.InternalLink]: () => (
        <Link
          key={`Link-${to}`}
          to={to as string}
          className={buttonClass}
          role="button"
          {...remainingProps}
        >
          {children}
          {/* <span className={styles.mask} /> */}
        </Link>
      ),
      [EButtonActionType.ExternalLink]: () => (
        <a
          tabIndex={0}
          key={`a-${href}`}
          href={href}
          className={buttonClass}
          role="button"
          target="_blank"
          rel="noopener noreferrer"
          {...remainingProps}
        >
          {children}
          {/* <span className={styles.mask} /> */}
        </a>
      ),
      [EButtonActionType.NormalButton]: () => (
        <button
          id={id}
          tabIndex={0}
          key="button"
          className={buttonClass}
          disabled={isDisabled}
          onClick={onClick}
          type={submit ? "submit" : "button"}
          data-cy={dataCyName}
        >
          {this.props.isLoading ? <Spinner /> : children}
          {/* <span className={styles.mask} /> */}
        </button>
      ),
    });
  }

  public render() {
    return this.renderButton();
  }
}
