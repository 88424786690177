import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { injectIntl } from "react-intl";

import { Form } from "@components/Form";
import { useSwal } from "@hooks/useSwal";
import { FormInput as Input } from "@components/Form/Input";

import { useFormatMessage } from "@hooks/formatMessage";

import { TProps } from "./Types";
import { VersionSchema } from "./Schema";
import { changeVersion, createVersion } from "../ducks";
import {
  Button,
  Card,
  ModalBackButton,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  FormSelect as Select,
  Controller,
} from "@icr/react.uicomponents";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const VersionsModal: FC<TProps> = props => {
  const { applicationList, tenantList, closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Version has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const version = {
      ...formData,
      application: formData.application ? formData.application.value : undefined,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this version?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Version",
      }).then(value => {
        if (value) {
          changeVersion(formData.id, version).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      delete version.id;

      swalConfirmationMessage({
        text: "Do you really want to create this version?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Version",
      }).then(value => {
        if (value) {
          createVersion(version).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalBackButton
          onCloseModal={closeModal}
          backMessage={formatMessage("app.backToOverview")}
        />
        <ModalTitle title={formatMessage("app.addNewEntry")} />
      </ModalHeader>
      <Card>
        <Form onSubmit={onSubmit} schema={VersionSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Controller
              name="application"
              render={({ field }) => (
                <Select
                  {...field}
                  id="application"
                  label={formatMessage("app.application", "Application")}
                  options={applicationList.map(option => ({
                    value: option.id,
                    label: option.description,
                  }))}
                />
              )}
            />
            <Controller
              name="tenantId"
              render={({ field }) => (
                <Select
                  {...field}
                  id="tenantId"
                  label={formatMessage("app.tenantId", "Tenant")}
                  options={tenantList.map(option => ({
                    value: option.id,
                    label: option.tenantName,
                  }))}
                />
              )}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="createdDate"
              name="createdDate"
              label={formatMessage("app.createdDate", "Create Date")}
              type="datetime-local"
            />
            <Input
              id="endDate"
              name="endDate"
              label={formatMessage("app.endDate", "End Date")}
              type="datetime-local"
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="major"
              name="major"
              type="number"
              label={formatMessage("app.major", "major")}
            />
            <Input
              id="minor"
              name="minor"
              type="number"
              label={formatMessage("app.minor", "minor")}
            />
            <Input
              id="build"
              name="build"
              type="number"
              label={formatMessage("app.build", "build")}
            />
            <Input
              id="revision"
              name="revision"
              type="number"
              label={formatMessage("app.revision", "revision")}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Card>
    </ModalContainer>
  );
};

export default injectIntl(VersionsModal);
