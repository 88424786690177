import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useSwal } from "@hooks/useSwal";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  EditIcon,
  TrashIcon,
  ListContainer,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import "../../../styles/index.scss";
import { fetchTenantsToDropdown } from "../Tenant/ducks";
import { TDispatch, TRootState } from "../../../redux/types";
import TenantMappingsModal from "./Modal/TenantMappingModal";
import { getParameterByName } from "@utils/toSearchParams";
import { fetchTenantMappingTypes } from "@actions/enumerator";
import {
  deleteTenantMapping,
  fetchTenantMappingList,
  getTenantMappingById,
  setTenantMappingsPageNo,
} from "./ducks";

enum formMode {
  edit = "edit",
}

const TenantMappings: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantMappingForm, setAddTenantMappingForm] = useState<Record<string, unknown>>();
  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);

  const [tenantList, setTenantList] = useState<Record<string, unknown>[]>();
  const [tenantMappingTypeList, setTenantMappingTypeList] = useState<Record<string, unknown>[]>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { tenantMappings, moreTenantMappings, tenantMappingsPageNo } = useSelector(
    ({
      TenantMappingsReducer: { tenantMappings, moreTenantMappings, tenantMappingsPageNo },
    }: TRootState) => ({
      tenantMappings,
      moreTenantMappings,
      tenantMappingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantMappingList());
    dispatch(fetchTenantsToDropdown()).then(res => setTenantList(res));
    dispatch(fetchTenantMappingTypes()).then(res => setTenantMappingTypeList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantMappings) {
      dispatch(
        fetchTenantMappingList(tenantMappingsPageNo, 10, { GenericText: genericTextFilter })
      );
    }
  };

  const filterTenantMappings = () => {
    dispatch(setTenantMappingsPageNo(1));
    fetchTenantMappingList(1, 10, { GenericText: genericTextFilter });
  };

  const clearFilters = () => {
    setGenericTextFilter(null);

    dispatch(setTenantMappingsPageNo(1));
    dispatch(fetchTenantMappingList(1, 10));
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantMappings();
  };

  const openModal = (content, message?, tenantMapping?) => {
    getTenantMappingById(tenantMapping.id).then(res => {
      if (res.success) {
        setAddTenantMappingForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTenantMappings = () => {
    history.push({
      search: "",
    });
    filterTenantMappings();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Mapping has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTenantMappings
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this tenant mapping?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove tenant mapping",
    }).then(value => {
      if (value) {
        deleteTenantMapping(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TenantMappingsModal
            closeModal={closeModal}
            initialData={addTenantMappingForm}
            tenantList={tenantList}
            tenantMappingTypeList={tenantMappingTypeList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTenantMappingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewTenantMapping")}
            onClick={() => {
              setAddTenantMappingForm({});
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={tenantMappings}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.tenantName")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.type")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.value")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"tenantMappingRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.tenantName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.typeName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.value}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Mapping"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Mapping"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterTenantMappings}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default TenantMappings;
