import * as yup from "yup";

const MailServerSchema = yup.object().shape({
  id: yup.string(),
  emailFrom: yup.string().email().required(),
  mailServerFqdn: yup.string().required(),
  port: yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .required(),
  enableSsl: yup.boolean(),
  username: yup.string().required(),
  password: yup.string().when("id", {
    is: val => !val,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  passwordrepeat: yup.string().when("id", {
    is: val => !val,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "app.passwordsDontMatch")
      .required(),
    otherwise: yup.string(),
  }),
  isActive: yup.boolean(),
  defaultBodyHtml: yup.string().nullable(),
});

export { MailServerSchema };
