import * as React from "react";
import { InputText } from "../Text/index";
import { DropdownInput } from "../Dropdown/index";
import { EInputTextType, IInputConfig } from "../index";

interface IProps {
  isFocused: boolean;
  isLoading: boolean;
  name: string;
  value: string[] | string | number;
  placeholder: string;
  type: string;
  onChange?: (name: string, value: string) => void;
  onBlur?: any;
  onDropdownOpen: (isOpen: boolean) => void;
  additionalClass?: string;
  isDisabled?: boolean;
  clearable?: boolean;
  readOnly?: boolean;
  onSelected?: any;
  filtering?: boolean;
  selectOptions?: IInputConfig[];
  inputTextType?: EInputTextType;
  isOpen?: boolean;
  onFocus?: any;
}

export function InputSelect(props: IProps) {
  const {
    name,
    value,
    placeholder,
    type,
    isFocused,
    isLoading,
    isDisabled,
    onChange,
    onDropdownOpen,
    selectOptions,
    inputTextType,
    clearable,
    readOnly,
    onSelected,
    onBlur,
    filtering,
    isOpen,
    onFocus,
  } = props;
  return (
    selectOptions.length > 0 && (
      <DropdownInput
        dropdownOptions={selectOptions}
        name={name}
        value={value}
        onSelected={onSelected}
        onOpen={onDropdownOpen}
        isDisabled={isDisabled}
        readOnly={readOnly}
        clearable={clearable}
        onBlur={onBlur}
        filtering={filtering}
        isOpen={isOpen}
        onFocus={onFocus}
      >
        <InputText
          isFocused={isFocused}
          isLoading={isLoading}
          onChange={onChange}
          isDisabled={false}
          name={name}
          placeholder={placeholder}
          type={type}
          readOnly={readOnly}
          value={value}
          inputTextType={inputTextType}
          onBlur={onBlur}
          filtering={filtering}
          isDropdown
        />
      </DropdownInput>
    )
  );
}
