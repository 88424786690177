import { Cookies } from "react-cookie";
import { Observable } from "rxjs";

import { Body } from "./types";
import BaseRequestModel from "./baseRequestModel";

const apiUrl = process.env.REACT_APP_REST_API_URL;
const cookies = new Cookies();

/**
 * Deprecated
 * @param method
 * @param path
 * @param data
 * @param headers
 * @param isAuthentication
 */
export default function apiHandler<T>(
  method: string,
  path: string,
  data?: any,
  headers?: Record<string, unknown>,
  isAuthentication?: boolean
): Promise<T[] | null> {
  const apiToken = cookies.get("bearerToken");

  // Build default request object
  const apiObject = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: null,
  };

  // Build default request body
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    apiObject.body = data ? JSON.stringify(data) : null;
  }

  // Check if request is to sign in
  if (!isAuthentication)
    apiObject.headers = {
      Authorization: `Bearer ${apiToken}`,
      ...apiObject.headers,
    } as any;

  return fetch(apiUrl + path, apiObject).then(res => {
    if (res.ok) {
      return res.json();
    }
    // Throw error
    return res.json().then(responseJson => {
      throw responseJson;
    });
  });
}

export const ApiService = {
  // get request
  get: (route: string): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "application/json",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "GET", headers);
    return newBase.request();
  },
  // post request
  post: (route: string, data: Body): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "application/json",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "POST", headers, JSON.stringify(data));
    return newBase.request();
  },
  put: (route: string, data: Body): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "application/json",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "PUT", headers, JSON.stringify(data));
    return newBase.request();
  },
  patch: (route: string, data: Body): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "application/json",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "PATCH", headers, JSON.stringify(data));
    return newBase.request();
  },
  delete: (route: string): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "application/json",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "DELETE", headers);
    return newBase.request();
  },
  upload: (route: string, data: FormData): Observable<any> => {
    const apiToken = cookies.get("bearerToken");
    let headers = {
      "Content-Type": "multipart/form-data",
    };
    // Check if request is to sign in
    if (apiToken)
      headers = {
        Authorization: `Bearer ${apiToken}`,
        ...headers,
      } as any;
    const newBase = new BaseRequestModel(route, "POST", headers, data);
    return newBase.request();
  },
};
