import * as constants from "../constants";
import { ACTION } from "../shared/models/action";

export const setSidebar = (payload: boolean): ACTION => ({
  type: constants.SIDEBAR_SET,
  payload,
});

export const setPopup = (payload: boolean): ACTION => ({
  type: constants.POPUP,
  payload,
});

export const setLoading = (payload: boolean): ACTION => ({
  type: constants.LOADING,
  payload,
});
