import * as constants from "../constants/index";

export interface AppState {
  sidebarOpened: boolean;
  avatarSrc: string;
  users: any;
  loading: boolean;
  entities: any;
  popupIsOpen: boolean;
}

const getInitialState = () => {
  return {
    sidebarOpened: false,
    avatarSrc: "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png",
    entities: {},
    users: {},
    loading: false,
    errors: [],
    popupIsOpen: false,
    profiles: {},
  };
};

const app = (state: AppState = getInitialState(), action: any) => {
  switch (action.type) {
    case constants.SIDEBAR_SET: {
      // console.log("reducer sidebarset", action);
      return { ...state, sidebarOpened: action.payload };
    }

    case constants.POPUP: {
      // console.log("reducer sidebarset", action);
      return { ...state, popupIsOpen: action.payload };
    }

    case constants.LOADING: {
      // console.log("reducer loading", action);
      return { ...state, loading: action.payload };
    }
  }

  return state;
};

export default app;
