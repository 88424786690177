import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Flex } from "reflexbox";
import { TDispatch, TRootState } from "../../../redux/types";
import { useHistory, useLocation } from "react-router-dom";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
  Checkbox,
} from "@icr/react.uicomponents";
import { ReactComponent as IconKey } from "@assets/icons/key-temp.svg";

import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

import { deserialize } from "../../../api/jsonApiParser";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
  deleteMailServerSetting,
  setMailServerSettingsPageNo,
  fetchMailServerSettingsList,
  getMailServerSettingById,
} from "./ducks";

import MailServerSettingsModalPassword from "./ModalPassword/MailServerSettingsModalPassword";
import MailServerSettingsModal from "./Modal/MailServerSettingsModal";
import "../../../styles/index.scss";
import "./MailServerSettings.scss";

enum formMode {
  edit = "edit",
  password = "password",
}

const MailServerSettings: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addMailServerSettingForm, setaddMailServerSettingForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [genericTextFilter, setGenericTextFilter] = useState<string>("");
  const [isActiveFilter, setIsActiveFilter] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { mailServerSettingsList, moreMailServerSettings, mailServerSettingsPageNo } = useSelector(
    ({
      MailServerSettingsReducer: {
        mailServerSettings,
        moreMailServerSettings,
        mailServerSettingsPageNo,
      },
    }: TRootState) => ({
      mailServerSettingsList: mailServerSettings,
      moreMailServerSettings,
      mailServerSettingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchMailServerSettingsList());
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreMailServerSettings) {
      setShowMoreSpinner(true);
      dispatch(
        fetchMailServerSettingsList(mailServerSettingsPageNo, 10, {
          GenericText: genericTextFilter,
          IsActive: isActiveFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
      });
    }
  };

  const filterMailServerSettings = () => {
    dispatch(setMailServerSettingsPageNo(1)).then(() => {
      dispatch(
        fetchMailServerSettingsList(1, 10, {
          GenericText: genericTextFilter,
          IsActive: isActiveFilter,
        })
      );
    });
  };

  const clearFilters = () => {
    setGenericTextFilter("");
    setIsActiveFilter(false);

    dispatch(setMailServerSettingsPageNo(1)).then(() => {
      dispatch(fetchMailServerSettingsList(1, 10));
    });
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getMailServerSettingById(entry.id).then(ret => {
        if (ret) {
          setaddMailServerSettingForm(deserialize(ret.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setaddMailServerSettingForm({ id: entry.id });
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterMailServerSettings = () => {
    history.push({
      search: "",
    });
    filterMailServerSettings();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterMailServerSettings();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Mail Server Setting has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this mail server setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove mail server setting",
    }).then(value => {
      if (value) {
        deleteMailServerSetting(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterMailServerSettings);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && modalContent !== formMode.password ? (
          <MailServerSettingsModal closeModal={closeModal} initialData={addMailServerSettingForm} />
        ) : (
          <MailServerSettingsModalPassword
            closeModal={closeModal}
            initialData={addMailServerSettingForm}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addMailServerSettingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewmailserversetting", "Add MailServer Setting")}
            onClick={() => {
              setModalContent(undefined);
              setaddMailServerSettingForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={mailServerSettingsList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.domain", "Domain")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.email", "E-mail")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.port", "Port")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"mailServerSettingRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.mailServerFqdn}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.emailFrom}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.port}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Mail Server Setting"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <IconKey
                        id={"changePasswordEntry" + item.id}
                        title="Edit Password"
                        onClick={() => openModal(formMode.password, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Mail Server Setting"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterMailServerSettings}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />

          <div className="breaker mb-5" />

          <Checkbox
            id="isActiveSearch"
            name="isActiveSearch"
            label={formatMessage("app.isactive", "IsActive")}
            checked={isActiveFilter}
            setChecked={value => setIsActiveFilter(value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default MailServerSettings;
