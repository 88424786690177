import React, { FC, useEffect } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { shallowEqual, useSelector } from "react-redux";

import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

import { Form } from "@components/Form";
import { TRootState } from "@redux/types";
import { FormInput as Input } from "@components/Form/Input";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";

import { TProps } from "./types";
import { UserPasswordSchema } from "./schema";
import { changeUserPassword } from "../ducks";
import { TextButton, Button } from "@icr/react.uicomponents";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const UsersModalPassword: FC<TProps> = props => {
  // Props
  const { closeModal } = props;
  // Language
  const formatMessage = useFormatMessage();
  // Swal
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const { formUser } = useSelector(
    ({ UsersReducer: { formUser } }: TRootState) => ({
      formUser,
    }),
    shallowEqual
  );

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const onSubmit = formData => {
    swalConfirmationMessage({
      text: "Do you really want to change user's password?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Change!",
    }).then(value => {
      if (value) {
        changeUserPassword(formUser.id, formData.password).then(ret => {
          if (ret.success) {
            swalAlertWithCallback(
              {
                text: "Password has been changed!",
                type: "success",
                confirmationText: "Ok!",
              },
              closeModal
            );
          }
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex mr="10px" width="16px" height="16px" alignItems="center">
              <IconArrowLeftBlue />
            </Flex>
            {formatMessage("app.backToOverview")}
          </TextButton>
        </div>
      </div>
      <Form onSubmit={onSubmit} schema={UserPasswordSchema}>
        <Flex>
          <Input id="id" name="id" hidden />
          <Input
            id="password"
            name="password"
            type="password"
            label={formatMessage("app.password", "Password")}
          />
          <Input
            id="passwordrepeat"
            name="passwordrepeat"
            type="password"
            width="75%"
            label={formatMessage("app.passwordRepeat", "Repeat Password")}
          />
        </Flex>
        <Flex>
          <Checkbox
            id="mustChangePwd"
            name="mustChangePwd"
            label={formatMessage("app.passwordMustBeChanged", "Password must be changed")}
            wrapperProps={{ width: "25%" }}
          />
        </Flex>

        <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
          <Button id="usersModalCancelBtn" mr="10px" onClick={closeModal}>
            {formatMessage("app.cancel", "Cancel")}
          </Button>
          <Button id="usersModalSubmitBtn" type="submit">
            {formatMessage("app.save", "Save")}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default UsersModalPassword;
