import http from "@api/http";

export const updateDatabase = () => {
  return http.post("Database/Update");
};

export const cleanUpDatabase = (
  deleteTempFiles: boolean,
  deleteIncompleteFiles: boolean,
  clearSession: boolean
) => {
  return http.post(
    `Database/CleanUp?deleteTempFiles=${deleteTempFiles}&deleteIncompleteFiles=${deleteIncompleteFiles}&clearSession=${clearSession}`
  );
};

export const activateMaintenanceModeDatabase = () => {
  return http.post("Database/ActivateMaintenanceMode");
};

export const deactivateMaintenanceModeDatabase = () => {
  return http.post("Database/DeactivateMaintenanceMode");
};

export const getMaintenanceMode = () => {
  return http.get("Database/InMaintenanceMode");
};
