const colors = {
  primary: "#263238",
  primaryHover: "#455347",
  primaryLight: "#afafb1",
  grey: "#afafb1",
  greyDark: "#263238",
  greyLight: "#f3f3f3",
  greyHover: "#e7e7e7",
  blue: "#0020a2",
  blueLight: "#37383c",
  red: "#d22630",
  redHover: "#d55353",
  redMedium: "#d55353",
  redLight: "#d78787",
  green: "#b5c67e",
  greenLight: "#c4d787",
  greenHover: "#b3c67e",
  faint: "#f5f5f5",
  dark: "#000000",
  light: "#ffffff",
  mdcThemePrimary: "#263238",
};

export { colors };
