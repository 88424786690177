import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import dateFormat from "dateformat";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useSwal } from "@hooks/useSwal";
import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  EditIcon,
  TrashIcon,
  ListContainer,
  Input,
  Filter,
  Modal,
} from "@icr/react.uicomponents";

import "../../../styles/index.scss";
import VersionsModal from "./Modal/VersionsModal";
import { fetchTenantsToDropdown } from "../../Tenant/Tenant/ducks";
import { TDispatch, TRootState } from "../../../redux/types";
import { fetchApplications } from "../../../reducers/enumerator";
import { getParameterByName } from "../../../utils/toSearchParams";
import { deleteVersion, fetchVersionList, getVersionById, setVersionsPageNo } from "./ducks";

enum formMode {
  edit = "edit",
  password = "password",
}

const Versions: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addVersionForm, setAddVersionForm] = useState("");

  const [genericTextFilter, setGenericTextFilter] = useState<string>("");
  const [applicationList, setApplicationList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { versions, moreVersions, versionsPageNo } = useSelector(
    ({ VersionReducer: { versions, moreVersions, versionsPageNo } }: TRootState) => ({
      versions,
      moreVersions,
      versionsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchVersionList());
    dispatch(fetchApplications()).then(res => setApplicationList(res));
    dispatch(fetchTenantsToDropdown()).then(res => setTenantList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreVersions) {
      dispatch(fetchVersionList(versionsPageNo, 10, { GenericText: genericTextFilter }));
    }
  };

  const filterConnections = () => {
    dispatch(setVersionsPageNo(1)).then(() =>
      dispatch(fetchVersionList(1, 10, { GenericText: genericTextFilter }))
    );
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setVersionsPageNo(1)).then(() => dispatch(fetchVersionList()));
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterConnections();
  };

  const openModal = (content, message?, entry?) => {
    if (content !== formMode.password.toString()) {
      getVersionById(entry.id).then(res => {
        if (res.success) {
          setAddVersionForm(deserialize(res.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      setAddVersionForm(entry);
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterVersions = () => {
    history.push({
      search: "",
    });
    filterConnections();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Version has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterVersions
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this version?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove version",
    }).then(value => {
      if (value) {
        deleteVersion(id).then(res => {
          if (res.success) showSuccessMessage("removed");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        {modalIsOpen && (
          <VersionsModal
            closeModal={closeModal}
            initialData={addVersionForm}
            applicationList={applicationList}
            tenantList={tenantList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addVersionBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewversion", "Add Version")}
            onClick={() => {
              setAddVersionForm("");
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={versions}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.fullVersion", "Version")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.applicationName", "Application")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.createdDate", "Create Date")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.endDate", "End Date")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.tenantDescription", "Tenant")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"versionRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.fullVersion}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.applicationName}</Text>
                    </ListColumn>

                    <ListColumn>
                      <Text>
                        {dateFormat(item.createdDate, "yyyy/mm/dd HH:mm")}
                        <br />
                        {item.createdDate}
                      </Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>
                        {dateFormat(item.endDate, "yyyy/mm/dd HH:mm")}
                        <br />
                        {item.endDate}
                      </Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.tenantDescription}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Version"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Version"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterConnections}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Versions;
