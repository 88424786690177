import styled from "@emotion/styled";
import { Flex } from "reflexbox";

const Card = styled(Flex)`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 18px 60px -30px rgb(38 50 56 / 20%);
  padding: 3.2rem;
  width: 100%;
`;

export { Card };
