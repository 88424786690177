import React, { FC, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  Checkbox,
  Select,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import "../../../styles/index.scss";
import { fetchTenantsToDropdown } from "../Tenant/ducks";
import { TDispatch, TRootState } from "../../../redux/types";
import { getParameterByName } from "../../../utils/toSearchParams";
import TenantTranslationsModal from "./Modal/TenantTranslationModal";
import {
  fetchTenantTranslationList,
  getTenantTranslationById,
  setTenantTranslationsPageNo,
} from "./ducks";
import {
  fetchBaseTextTranslates,
  fetchLayers,
  fetchModules,
  fetchTranslationTypes,
} from "@actions/enumerator";

import { fetchLanguages } from "../../../reducers/enumerator";

enum formMode {
  edit = "edit",
}

const TenantTranslations: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantTranslationForm, setAddTenantTranslationForm] = useState(null);

  const [genericTextFilter, setGenericTextFilter] = useState<string>("");
  const [tenantIdFilter, setTenantIdFilter] = useState<string>("");
  const [layerFilter, setLayerFilter] = useState<string>("");
  const [moduleFilter, setModuleFilter] = useState<string>("");
  const [translationTypeFilter, setTranslationTypeFilter] = useState<string>("");
  const [languageFilter, setLanguageFilter] = useState<string>("");
  const [textTranslatedFilter, setTextTranslatedFilter] = useState<string>("");
  const [listNotTranslated, setListNotTranslated] = useState<boolean>(false);
  const [translationIdFilter, setTranslationIdFilter] = useState<string>("");

  const [languageList, setLanguageList] = useState<Record<string, unknown>[]>(null);
  const [baseTextTranslateIdList, setBaseTextTranslateIdList] = useState<Record<string, unknown>[]>(
    null
  );
  const [translationTypeList, setTranslationTypeList] = useState<Record<string, unknown>[]>(null);
  const [moduleList, setModuleList] = useState<Record<string, unknown>[]>(null);
  const [layerList, setLayerList] = useState<Record<string, unknown>[]>(null);
  const [tenantList, setTenantList] = useState<Record<string, unknown>[]>(null);

  const formatMessage = useFormatMessage();
  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();

  const { tenantTranslations, moreTenantTranslations } = useSelector(
    ({
      TenantTranslationReducer: {
        tenantTranslations,
        moreTenantTranslations,
        tenantTranslationsPageNo,
      },
    }: TRootState) => ({
      tenantTranslations,
      moreTenantTranslations,
      tenantTranslationsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantTranslationList());
    dispatch(fetchLanguages()).then(res => setLanguageList(res));
    dispatch(fetchBaseTextTranslates()).then(res => setBaseTextTranslateIdList(res));
    dispatch(fetchTranslationTypes()).then(res => setTranslationTypeList(res));
    dispatch(fetchModules()).then(res => setModuleList(res));
    dispatch(fetchLayers()).then(res => setLayerList(res));
    dispatch(fetchTenantsToDropdown()).then(res => setTenantList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantTranslations) {
      dispatch(fetchTenantTranslationList());
    }
  };

  const filterTenantTranslations = () => {
    dispatch(setTenantTranslationsPageNo(1)).then(() =>
      dispatch(
        fetchTenantTranslationList(1, 10, {
          GenericText: genericTextFilter,
          TenantId: tenantIdFilter,
          Layer: layerFilter,
          Module: moduleFilter,
          TranslationType: translationTypeFilter,
          Language: languageFilter,
          TextTranslated: textTranslatedFilter,
          ListNotTranslated: listNotTranslated,
          TranslationId: translationIdFilter,
        })
      )
    );
  };

  const clearFilters = () => {
    setGenericTextFilter("");
    setTenantIdFilter("");
    setLayerFilter("");
    setModuleFilter("");
    setTranslationTypeFilter("");
    setLanguageFilter("");
    setTextTranslatedFilter("");
    setListNotTranslated(false);
    setTranslationIdFilter("");

    dispatch(setTenantTranslationsPageNo(1)).then(() => dispatch(fetchTenantTranslationList()));
  };

  const prepareLanguageOptions = useCallback(() => {
    if (languageList) {
      return languageList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [languageList]);

  const prepareModuleOptions = useCallback(() => {
    if (moduleList) {
      return moduleList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [moduleList]);

  const prepareTranslationTypeOptions = useCallback(() => {
    if (translationTypeList) {
      return translationTypeList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [translationTypeList]);

  const prepareLayerOptions = useCallback(() => {
    if (layerList) {
      return layerList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [layerList]);

  const prepareTenantOptions = useCallback(() => {
    if (tenantList) {
      return tenantList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [tenantList]);

  const validateValue = (value, arr) => {
    return value ? arr?.find(item => item.value === value) : "";
  };

  const renderTranslationsSideBar = () => {
    const languageOptions = prepareLanguageOptions();
    const layerOptions = prepareLayerOptions();
    const moduleOptions = prepareModuleOptions();
    const translationTypeOptions = prepareTranslationTypeOptions();
    const tenantOptions = prepareTenantOptions();

    return (
      <Filter
        id="filterArea"
        searchButtonText={formatMessage("app.search", "Search")}
        onClickSearchButton={filterTenantTranslations}
        clearButtonText={formatMessage("app.clear", "Clear")}
        onClickClearButton={clearFilters}
        open={true}
      >
        <Input
          id="dynamicSearch"
          label="Dynamic Filter"
          placeholder={formatMessage("app.anyfield", "Any Field")}
          value={genericTextFilter}
          onChange={e => setGenericTextFilter(e.target.value)}
        />

        <div className="breaker mb-5" />

        <Select
          id="tenantSearch"
          label={formatMessage("app.tenant", "Tenant")}
          options={tenantOptions}
          value={validateValue(tenantIdFilter, tenantOptions)}
          onChange={e => setTenantIdFilter(e.value)}
        />
        <Select
          id="layerSearch"
          label={formatMessage("app.layer", "Layer")}
          wrapperProps={{ marginTop: "10px" }}
          options={layerOptions}
          value={validateValue(layerFilter, layerOptions)}
          onChange={e => setLayerFilter(e.value)}
        />
        <Select
          id="moduleSearch"
          label={formatMessage("app.module", "Module")}
          wrapperProps={{ marginTop: "10px" }}
          options={moduleOptions}
          value={validateValue(moduleFilter, moduleOptions)}
          onChange={e => setModuleFilter(e.value)}
        />
        <Select
          id="translationTypeSearch"
          label={formatMessage("app.translationType", "Translation Type")}
          wrapperProps={{ marginTop: "10px" }}
          options={translationTypeOptions}
          value={validateValue(translationTypeFilter, translationTypeOptions)}
          onChange={e => setTranslationTypeFilter(e.value)}
        />
        <Select
          id="languageSearch"
          label={formatMessage("app.language", "Language")}
          wrapperProps={{ marginTop: "10px" }}
          options={languageOptions}
          value={validateValue(languageFilter, languageOptions)}
          onChange={e => setLanguageFilter(e.value)}
        />
        <Input
          id="translationIdSearch"
          placeholder={formatMessage("app.translationId", "Translation Id")}
          wrapperProps={{ marginTop: "10px" }}
          value={translationIdFilter}
          onChange={e => setTranslationIdFilter(e.target.value)}
        />
        <Input
          id="textTranslatedSearch"
          placeholder={formatMessage("app.textTranslated", "Text translated")}
          wrapperProps={{ marginTop: "10px" }}
          value={textTranslatedFilter}
          onChange={e => setTextTranslatedFilter(e.target.value)}
        />
        <Checkbox
          id="listNotTranslatedSearch"
          name="listNotTranslatedSearch"
          label={formatMessage("app.listNotTranslated", "List not translated")}
          wrapperProps={{ marginTop: "10px" }}
          checked={listNotTranslated}
          setChecked={value => setListNotTranslated(value)}
        />
      </Filter>
    );
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantTranslations();
  };

  const openModal = (content, message?, tenantTranslation?) => {
    getTenantTranslationById(tenantTranslation.id).then(res => {
      if (res.success) {
        setAddTenantTranslationForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const renderModalContent = () =>
    modalIsOpen && (
      <TenantTranslationsModal
        closeModal={closeModal}
        initialData={addTenantTranslationForm}
        languageList={languageList}
        baseTextTranslateIdList={baseTextTranslateIdList}
        translationTypeList={translationTypeList}
        moduleList={moduleList}
        layerList={layerList}
        tenantList={tenantList}
      />
    );

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {renderModalContent()}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <ScrollableList
          items={tenantTranslations}
          onLoadMore={handleScroll}
          noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
          renderHeader={
            <Flex width="100%">
              <ListColumn>
                <Text>{formatMessage("app.layer")}</Text>
              </ListColumn>
              <ListColumn>
                <Text>{formatMessage("app.module")}</Text>
              </ListColumn>
              <ListColumn>
                <Text>{formatMessage("app.language")}</Text>
              </ListColumn>
              <ListColumn>
                <Text>{formatMessage("app.text")}</Text>
              </ListColumn>
              <ListColumn>
                <Text>{formatMessage("app.translated")}</Text>
              </ListColumn>
              <ListColumn />
            </Flex>
          }
          renderItem={(item, index) => (
            <ExpandableRow
              key={"tenantTranslationRow" + index}
              id={item.id as string}
              expandable={true}
              top={
                <Flex width="100%">
                  <ListColumn>
                    <Text font="semiBold">{item.layerName}</Text>
                  </ListColumn>
                  <ListColumn>
                    <Text>{item.moduleName}</Text>
                  </ListColumn>
                  <ListColumn>
                    <Text>{item.languageCodeIso}</Text>
                  </ListColumn>
                  <ListColumn>
                    <Text
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {item.originalText}
                    </Text>
                  </ListColumn>
                  <ListColumn>
                    <Text
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {" "}
                      {item.textTranslated}
                    </Text>
                  </ListColumn>
                  <ListColumn icon>
                    <EditIcon
                      id={"changeEntry" + item.id}
                      title="Edit Translation"
                      onClick={() => openModal(formMode.edit, "", item)}
                    />
                  </ListColumn>
                </Flex>
              }
              more={
                <Flex width="100%" mb="40px">
                  <ListColumn direction="column">
                    <Text color="#969899" font="medium" fontSize="small">
                      {formatMessage("tenant")}
                    </Text>
                    <Text mt="10px">{item.tenantName}</Text>
                  </ListColumn>
                  <ListColumn direction="column">
                    <Text color="#969899" font="medium" fontSize="small">
                      {formatMessage("app.translationType")}
                    </Text>
                    <Text mt="10px">{item.translationTypeName}</Text>
                  </ListColumn>
                  <ListColumn direction="column">
                    <Text color="#969899" font="medium" fontSize="small">
                      {formatMessage("app.translationId")}
                    </Text>
                    <Text mt="10px">{item.translationId}</Text>
                  </ListColumn>
                  <ListColumn direction="column">
                    <Text color="#969899" font="medium" fontSize="small">
                      {formatMessage("app.automaticTranslation")}
                    </Text>
                    <Checkbox
                      id="TableDebuggModeInput"
                      checked={item.automaticTranslation as boolean}
                    />
                  </ListColumn>
                </Flex>
              }
            />
          )}
        />
        {renderTranslationsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default TenantTranslations;
