import React, { FC } from "react";
import { useIntl } from "react-intl";

import { Input } from "@components/Input";
import { StyledError as Error } from "@components/Error";
import { TInputFile } from "@components/Form/InputFile/types";
import { StyledWrapper as Wrapper } from "@components/Input/styles";

const InputFile: FC<TInputFile> = ({ name, errors, wrapperProps, ...rest }) => {
  const intl = useIntl();

  return (
    <Wrapper pl="15px" pr="15px" {...wrapperProps}>
      <Input name={name} type="file" {...rest} />
      <Error>
        {errors &&
          errors[name] &&
          (errors[name]?.type === "custom"
            ? errors[name]?.message
            : intl.formatMessage({
                id: errors[name]?.message,
                defaultMessage: "* This field is required",
              }))}
      </Error>
    </Wrapper>
  );
};

export { InputFile };
