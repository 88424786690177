import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import dateFormat from "dateformat";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";
import { logOut } from "../../shared/state/AuthenticateActions";
import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  InputFilter,
  Filter,
  Header,
} from "@icr/react.uicomponents";

import "../../styles/index.scss";
import { TDispatch, TRootState } from "../../redux/types";
import { fetchSessionsList, setSessionsPageNo } from "./ducks";

const Session: FC = () => {
  const [sessionFilter, setSessionFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const userData = useSelector(({ LoginReducer }: TRootState) => LoginReducer.userData);
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { sessions, moreSessions } = useSelector(
    ({ SessionReducer: { sessions, sessionsPageNo, moreSessions } }: TRootState) => ({
      sessions,
      sessionsPageNo,
      moreSessions,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchSessionsList());
  }, [dispatch]);

  const handleScroll = () => {
    // eslint-disable-next-line no-console
    console.log(sessionFilter);
    if (moreSessions) {
      dispatch(fetchSessionsList());
    }
  };

  const filterSessions = () => {
    dispatch(setSessionsPageNo(1)).then(() => dispatch(fetchSessionsList()));
  };

  const clearFilters = () => {
    setSessionFilter(prev => ({
      ...prev,
      dynamicFilter: "",
    }));

    dispatch(setSessionsPageNo(1)).then(() => dispatch(fetchSessionsList()));
  };

  return (
    <ListContainer>
      <Header
        title="Sessions"
        changePasswordLabel={formatMessage("app.changePassword", "Change Password")}
        logoutLabel={formatMessage("app.logout", "Sign Out")}
        userData={userData}
        logOut={() => dispatch(logOut())}
      />
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <ScrollableList
            items={sessions}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.prename")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.lastname", "Last name")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.email")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.sessionFrom")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.sessionUntil")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"sessionRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.username}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.firstName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.lastName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.email}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{dateFormat(item.sessionStartDate, "yyyy/mm/dd HH:mm:ss")}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {dateFormat(item.sessionExpirationDate, "yyyy/mm/dd HH:mm:ss")}</Text>
                    </ListColumn>
                    <ListColumn icon></ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterSessions}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <InputFilter
            id="dynamicSearch"
            label="Dynamic Filter"
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={value => setSessionFilter(value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Session;
