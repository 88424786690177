import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Button, ExpandableRow, ListContainer } from "@icr/react.uicomponents";

import Drawer from "@components/Drawer";
import Header from "@components/Header";
import { OldInput } from "@components/OldInput";
import { Column } from "@components/ExpandableRow/styles";
import { EButtonType } from "@components/OldButton";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import { ReactComponent as IconPDF } from "@assets/icons/pdf.svg";
import { ReactComponent as IconZIP } from "@assets/icons/zip.svg";
import { ReactComponent as IconEdit } from "@assets/icons/edit.svg";
import { ReactComponent as IconFile } from "@assets/icons/file.svg";
import { ReactComponent as IconTrash } from "@assets/icons/trash.svg";
import { ReactComponent as IconUpload } from "@assets/icons/upload.svg";
import { ReactComponent as IconFilter } from "@assets/icons/filter.svg";
import { ReactComponent as IconFolder } from "@assets/icons/folder.svg";
import { ReactComponent as IconDownload } from "@assets/icons/download.svg";
import { ReactComponent as IconCreateFolder } from "@assets/icons/createfolder.svg";
import FoldersModal from "./FolderModal/FolderModal";
import FilesModal from "./FileModal/FileModal";
import { fetchFolderTypes } from "../../actions/enumerator";
import {
  deleteFile,
  deleteFolder,
  downloadFile,
  downloadFolder,
  fetchFileStructureList,
  fetchFolders,
  getFileById,
  getFolderById,
  setFileStructurePageNo,
} from "./ducks";
import "../../styles/index.scss";
import { TDispatch, TRootState } from "../../redux/types";
import { getParameterByName } from "utils/toSearchParams";
import { fetchTenantsToDropdown } from "../Tenant/Tenant/ducks";
import { useSwal } from "@hooks/useSwal";

enum formMode {
  folder = "Folder",
  file = "File",
}

const FileStructure: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [addFolderForm, setAddFolderForm] = useState(undefined);
  const [addFileForm, setAddFileForm] = useState(undefined);
  const [fileStructureFilter, setFileStructureFilter] = useState({
    GenericText: null,
    dynamicFilter: "",
  });
  const [folderList, setFolderList] = useState([]);
  const [folderTypeList, setFolderTypeList] = useState([]);
  const [tenantList, setTenantList] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { foldersAndFiles } = useSelector(
    ({
      FileStructureReducer: { foldersAndFilesPageNo, foldersAndFiles, moreFoldersAndFiles },
    }: TRootState) => ({
      foldersAndFilesPageNo,
      foldersAndFiles,
      moreFoldersAndFiles,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  const setBaseFolder = () => {
    setBreadCrumb([{ text: "Base", filterId: "" }]);
  };

  useEffect(() => {
    setBaseFolder();
    dispatch(fetchFileStructureList()).then(() => {
      setBaseFolder();
    });
    dispatch(fetchFolderTypes()).then(res => setFolderTypeList(res));
    dispatch(fetchFolders()).then(res => setFolderList(res));
    dispatch(fetchTenantsToDropdown()).then(res => setTenantList(res));
  }, [dispatch]);

  const changeFilterOpened = () => {
    setFiltersOpened(prev => !prev);
  };

  const filterFileStructure = () => {
    setBaseFolder();
    dispatch(setFileStructurePageNo(1)).then(() => {
      dispatch(fetchFileStructureList(1, 10, "", fileStructureFilter));
    });
  };

  const clearFilters = () => {
    setFileStructureFilter({
      GenericText: "",
      dynamicFilter: "",
    });
    setBaseFolder();

    dispatch(setFileStructurePageNo(1)).then(() => {
      dispatch(fetchFileStructureList());
    });
  };

  const openFolder = (name: string, id: string) => {
    setBreadCrumb(prev => [...prev, { text: name, filterId: id }]);
    dispatch(fetchFileStructureList(1, 10, id));
  };

  const openFolderFromBreadCrumb = (id: string) => {
    const breadcrumbCopy = [...breadCrumb];

    const index = breadcrumbCopy.findIndex(entry => entry.filterId === id);

    breadcrumbCopy.length = index + 1;
    setBreadCrumb(breadcrumbCopy);
    dispatch(fetchFileStructureList(1, 10, id));
  };

  const renderBreadCrumb = () => (
    <>
      {breadCrumb.length > 0 &&
        breadCrumb.map((entry, idx) => (
          <>
            {idx > 0 && <div> &gt; </div>}
            <div
              className="pointer"
              id={String(idx)}
              onClick={e => {
                e.stopPropagation();
                openFolderFromBreadCrumb(entry.filterId);
              }}
            >
              {entry.text}
            </div>
          </>
        ))}
    </>
  );

  const returnIconFile = mimeType => {
    switch (mimeType) {
      case "application/zip":
        return <IconZIP />;
      case "application/pdf":
        return <IconPDF />;
      default:
        return <IconFile />;
    }
  };

  const saveHistoryAndFilterFileStructure = () => {
    history.push({
      search: "",
    });
    dispatch(fetchFolders()).then(res => setFolderList(res));
    filterFileStructure();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterFileStructure();
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.folder.toString()) {
      getFolderById(entry.id).then(res => {
        if (res.success) {
          setAddFolderForm(deserialize(res.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      getFileById(entry.id).then(res => {
        if (res.success) {
          setAddFileForm(deserialize(res.data));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    }
  };

  const successfulMessage = (message: string, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = (content: string, id: string) => {
    swalConfirmationMessage({
      text: `You really want to delete this ${content}?`,
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove" + content + "?",
    }).then(value => {
      if (value) {
        if (content === formMode.folder.toString())
          deleteFolder(id).then(res => {
            if (res.success) successfulMessage("deleted", saveHistoryAndFilterFileStructure);
          });
        else
          deleteFile(id).then(res => {
            if (res.success) successfulMessage("deleted", saveHistoryAndFilterFileStructure);
          });
      }
    });
  };

  const forceDownload = (file, mimeType: string, fileName: string) => {
    const data = new Blob([file], { type: mimeType });
    const info = window.URL.createObjectURL(data);
    const tempLink = document.createElement("a");
    tempLink.href = info;
    tempLink.setAttribute("download", fileName);
    tempLink.click();
  };

  const download = (content: string, id: string, mimeType: string, fileName: string) => {
    swalConfirmationMessage({
      text: `You really want to download this ${
        content === formMode.folder ? "entire folder" : "file"
      }?`,
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Yes, download!",
    }).then(value => {
      if (value) {
        if (content === formMode.folder.toString())
          downloadFolder(id).then(res => {
            if (res.success) forceDownload(res.data, "application/zip", fileName + ".zip");
          });
        else
          downloadFile(id).then(res => {
            if (res.success) forceDownload(res.data, mimeType, fileName);
          });
      }
    });
  };

  const createFolder = id => {
    setAddFolderForm({ parentFolderId: id });
    setModalIsOpen(true);
    setModalContent(formMode.folder.toString());
  };

  const upload = id => {
    setAddFileForm({ folderId: id });
    setModalIsOpen(true);
    setModalContent(formMode.file.toString());
  };

  const renderFileStructureTable = () => {
    return (
      <div className="user-tab list-view">
        <div className="header">
          <div className="column column-1">
            <FormattedMessage id="app.application" defaultMessage="Name" />
          </div>
          <div className="column column-2">
            <div
              id="toggleFilterArea"
              className="icon-filter"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation();
                changeFilterOpened();
              }}
            >
              <IconFilter />
            </div>
          </div>
        </div>
        {foldersAndFiles.length > 0 ? (
          [
            foldersAndFiles.map((entry, idx) => (
              <ExpandableRow
                data-cy="fileRow"
                key={"fileRow" + idx}
                id={idx}
                index={idx}
                translucent={false}
                expandable={false}
                top={
                  <>
                    <Column size={1}>
                      <div data-cy="Name" className="d-flex flex-row showChildOnHover">
                        <div className="column-text">
                          {entry.type === formMode.folder.toString() && (
                            <div
                              className="pointer folder"
                              onClick={e => {
                                e.stopPropagation();
                                openFolder(entry.name, entry.id);
                              }}
                            >
                              <div className="iconWrapper medium mr-2">
                                <IconFolder />
                              </div>
                              <span>{entry.name}</span>
                            </div>
                          )}
                          {entry.type === formMode.file.toString() && (
                            <>
                              <div className="iconWrapper medium mr-2">
                                {returnIconFile(entry.mimeType)}
                              </div>
                              <span>{entry.name}</span>
                            </>
                          )}
                        </div>
                      </div>
                    </Column>
                    <Column size={2}>
                      <div className="d-flex flex-row showChildOnHover">
                        {entry.type === formMode.folder && (
                          <div
                            data-cy="changeEntry"
                            className="iconWrapper pointer large"
                            title="Edit"
                            style={{}}
                            onClick={() => openModal(entry.type, "", entry)}
                          >
                            <IconEdit />
                          </div>
                        )}
                        <div
                          data-cy="addFolderEntry"
                          className="iconWrapper pointer large"
                          title="Create Folder"
                          style={{}}
                          onClick={() =>
                            createFolder(
                              entry.type === formMode.folder.toString()
                                ? entry.id
                                : entry.ParentFolderId
                            )
                          }
                        >
                          <IconCreateFolder />
                        </div>
                        <div
                          data-cy="deleteEntry"
                          className="iconWrapper pointer large"
                          title="Delete"
                          style={{}}
                          onClick={() => remove(entry.type, entry.id)}
                        >
                          <IconTrash />
                        </div>
                        <div
                          data-cy="downloadEntry"
                          className="iconWrapper pointer large"
                          title="Download"
                          style={{}}
                          onClick={() => download(entry.type, entry.id, entry.mimeType, entry.name)}
                        >
                          <IconDownload />
                        </div>
                        {entry.type === formMode.folder && (
                          <div
                            data-cy="uploadEntry"
                            className="iconWrapper pointer large"
                            title="Upload File"
                            style={{}}
                            onClick={() =>
                              upload(
                                entry.type === formMode.folder.toString()
                                  ? entry.id
                                  : entry.ParentFolderId
                              )
                            }
                          >
                            <IconUpload />
                          </div>
                        )}
                      </div>
                    </Column>
                  </>
                }
              />
            )),
          ]
        ) : (
          <div className="blank_row">
            <FormattedMessage id="app.nousers" defaultMessage="No settings available" />
          </div>
        )}
      </div>
    );
  };

  const renderFileStructureSideBar = () => {
    return (
      <Drawer id="filterArea" opened={filtersOpened}>
        <div className="paddingContainer">
          <p className="colorGrey mb-1">Dynamic Filter</p>
          <div data-cy="dynamicFilterByField">
            <OldInput
              id="dynamicSearch"
              name="dynamicSearch"
              key="dynamicSearch"
              inputWrapperClass="text-input filter-input"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={fileStructureFilter.dynamicFilter}
              onChange={(name, value) =>
                setFileStructureFilter(prev => ({
                  ...prev,
                  dynamicFilter: value,
                }))
              }
              type="text"
              filtering
            />
          </div>

          <div className="breaker mb-5" />

          <div className="modal-buttons">
            <Button
              dataCyName="buttonClearSearch"
              className="modal-sub-button primary"
              onClick={e => {
                e.stopPropagation();
                clearFilters();
              }}
              buttonType={EButtonType.ButtonText}
            >
              <FormattedMessage id="app.clear" defaultMessage="Clear" />
            </Button>
            <Button
              dataCyName="buttonSearch"
              className="modal-main-button primary"
              onClick={e => {
                e.stopPropagation();
                filterFileStructure();
              }}
              buttonType={EButtonType.Button}
            >
              <FormattedMessage id="app.search" defaultMessage="Search" />
            </Button>
          </div>
        </div>
      </Drawer>
    );
  };

  const renderModalContent = () => {
    switch (modalContent) {
      case formMode.folder.toString():
        return (
          modalIsOpen && (
            <FoldersModal
              closeModal={closeModal}
              initialData={addFolderForm}
              parentFolderList={folderList}
              tenantList={tenantList}
              folderTypeList={folderTypeList}
            />
          )
        );
      case formMode.file.toString():
        return (
          modalIsOpen && (
            <FilesModal closeModal={closeModal} initialData={addFileForm} folderList={folderList} />
          )
        );
    }
  };

  return (
    <ListContainer>
      <Header title="File Structure" />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {renderModalContent()}
      </Modal>

      <div className="main-content-container">
        <div className="content-container">
          <div className="breadCrumb mb-3">{renderBreadCrumb()}</div>
          <div>{renderFileStructureTable()}</div>
        </div>
        {renderFileStructureSideBar()}
      </div>
    </ListContainer>
  );
};

export default FileStructure;
