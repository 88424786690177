import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  EditIcon,
  TrashIcon,
  ListContainer,
  Input,
  Filter,
  Header,
} from "@icr/react.uicomponents";

import { logOut } from "../../shared/state/AuthenticateActions";

import CompaniesModal from "./Modal/CompanyModal";
import { deserialize } from "@api/jsonApiParser";
import { TDispatch, TRootState } from "../../redux/types";
import { getParameterByName } from "../../utils/toSearchParams";
import { deleteCompany, fetchCompanyList, getCompanyById, setCompaniesPageNo } from "./ducks";
import "../../styles/index.scss";
import { useSwal } from "@hooks/useSwal";

enum formMode {
  edit = "edit",
  password = "password",
}

const Companies: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const userData = useSelector(({ LoginReducer }: TRootState) => LoginReducer.userData);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addCompanyForm, setAddCompanyForm] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [genericTextFilter, setGenericTextFilter] = useState<string>("");

  const history = useHistory();
  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { companyList, moreCompanies, companiesPageNo } = useSelector(
    ({ CompaniesReducer: { companies, moreCompanies, companiesPageNo } }: TRootState) => ({
      companyList: companies,
      moreCompanies,
      companiesPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchCompanyList());
  }, [dispatch]);

  const handleLoadMore = () => {
    if (moreCompanies) {
      dispatch(fetchCompanyList(companiesPageNo, 10, { GenericText: genericTextFilter }));
    }
  };

  const filterCompanies = () => {
    dispatch(setCompaniesPageNo(1)).then(() => {
      dispatch(fetchCompanyList(1, 10, { GenericText: genericTextFilter }));
    });
  };

  const saveHistoryAndFilterCompanies = () => {
    history.push({
      search: "",
    });
    filterCompanies();
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setCompaniesPageNo(1));
    dispatch(fetchCompanyList());
  };

  const openModal = (content, message?, entry?) => {
    getCompanyById(entry.id).then(ret => {
      if (ret.success) {
        setAddCompanyForm(deserialize(ret.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterCompanies();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const successfulMessage = (message: string, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Company has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this company?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove company",
    }).then(value => {
      if (value) {
        deleteCompany(id).then(ret => {
          if (ret.success) successfulMessage("deleted", saveHistoryAndFilterCompanies);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Header
        title="Company"
        changePasswordLabel={formatMessage("app.changePassword", "Change Password")}
        logoutLabel={formatMessage("app.logout", "Sign Out")}
        userData={userData}
        logOut={() => dispatch(logOut())}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && <CompaniesModal closeModal={closeModal} initialData={addCompanyForm} />}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addCompanyBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewcompany", "Add Company")}
            onClick={() => {
              setAddCompanyForm({});
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={companyList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.companyName", "Name")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.parentCompanyName", "Parent Company")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"commpanyRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.companyName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.parentCompanyName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Company"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Company"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterCompanies}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Companies;
