import React, { FC, useEffect, useState } from "react";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import Header from "@components/Header";
import Tenants from "./Tenant/Tenant";
import TenantMappings from "./TenantMappings/TenantMapping";
import TenantSettings from "./TenantSettings/TenantSetting";
import TenantTranslations from "./TenantTranslations/TenantTranslation";

import { HeaderTab } from "@components/HeaderTab";
import { HeaderText } from "@components/HeaderText";

import { useFormatMessage } from "@hooks/formatMessage";

import { TTenantBaseProps } from "./types";
import { ListContainer } from "@components/ListContainer";

const tabs = [
  { path: "tenants", renderComponent: props => <Tenants {...props} /> },
  { path: "tenantmappings", renderComponent: props => <TenantMappings {...props} /> },
  { path: "tenantsettings", renderComponent: props => <TenantSettings {...props} /> },
  { path: "tenanttranslations", renderComponent: props => <TenantTranslations {...props} /> },
];

const TenantBase: FC<TTenantBaseProps> = props => {
  const { locales, history } = props;

  const [currentTab, setCurrentTab] = useState("");
  const { path, url } = useRouteMatch();
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const state = location.state as Record<string, string>;

  useEffect(() => {
    document.body.classList.add("usersPage");
  }, []);

  useEffect(() => {
    if (state) {
      setCurrentTab(state.currentTab);
    }
  }, [state]);

  return (
    <ListContainer>
      <Header title="Tenant" locales={locales}>
        {tabs.map(tab => (
          <HeaderTab active={currentTab === tab.path}>
            <Link to={{ pathname: `${url}/${tab.path}`, state: { currentTab: tab.path } }}>
              <HeaderText active={currentTab === tab.path}>
                {formatMessage(`tenant.tab${tab.path}`)}
              </HeaderText>
            </Link>
          </HeaderTab>
        ))}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route path={`${path}/${tab.path}`}>{tab.renderComponent({ location, history })}</Route>
        ))}
      </Switch>
    </ListContainer>
  );
};

export default TenantBase;
