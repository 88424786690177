import React, { FC } from "react";
import { Flex } from "reflexbox";
import { TRootState } from "@redux/types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormInput as Input } from "@icr/react.uicomponents/lib/components/Form/Input";

import { useFormatMessage } from "@hooks/formatMessage";

import logo1x from "../../assets/imgs/logo-220w.jpg";
import logo2x from "../../assets/imgs/logo-600w.jpg";
import { TLoginStep, TStep1, TStep2 } from "@features/Login/types";
import { Button, Form, TextButton } from "@icr/react.uicomponents";
import { LoginSchema, ResetPasswordStep2Schema, Step1Schema } from "@features/Login/schema";

const LoginStep: FC<TLoginStep> = ({ login, resetPassword }) => {
  const formatMessage = useFormatMessage();

  const { isLoading } = useSelector(({ LoginReducer: { isLoading } }: TRootState) => ({
    isLoading,
  }));

  return (
    <div className="card mx-auto text-right">
      <img
        alt="logo"
        src={logo1x}
        srcSet={logo1x + " 1x," + logo2x + " 2x"}
        className="login-logo"
      />
      <div className="login-title">Administration</div>
      <Form onSubmit={login} schema={LoginSchema}>
        <Flex flexDirection="column">
          <Input id="id" name="id" hidden />
          <Input
            id="login"
            name="login"
            label={formatMessage("app.login", "Login")}
            wrapperProps={{ mb: "32px" }}
          />
          <Input
            id="password"
            name="password"
            label={formatMessage("app.password")}
            type="password"
          />
        </Flex>
        <Flex flexDirection="column" alignItems="flex-end" width="100%">
          <TextButton id="forgotPassword" onClick={resetPassword} mt="15px" type="button">
            {formatMessage("app.forgotPassword")}
          </TextButton>
          <Button type="submit" id="loginBtn" mt="20px" isLoading={isLoading} disabled={isLoading}>
            {formatMessage("app.signIn")}
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

const ResetPasswordStep1: FC<TStep1> = ({ sendPasswordResetRequest }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className="card mx-auto text-right">
      <img
        alt="logo"
        src={logo1x}
        srcSet={logo1x + " 1x," + logo2x + " 2x"}
        className="login-logo"
      />
      <div className="login-title">{formatMessage("app.resetPassword")}</div>
      <Form onSubmit={sendPasswordResetRequest} schema={Step1Schema}>
        <Input
          id="username"
          name="username"
          label={formatMessage("app.username")}
          wrapperProps={{ mb: "32px" }}
        />
        <Button type="submit" id="loginBtn">
          {formatMessage("app.submit")}
        </Button>
      </Form>
    </div>
  );
};

const ResetPasswordStep2: FC<TStep2> = ({ setNewPassword }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className="card mx-auto text-right">
      <img
        alt="logo"
        src={logo1x}
        srcSet={logo1x + " 1x," + logo2x + " 2x"}
        className="login-logo"
      />
      <div className="login-title">{formatMessage("app.setNewPassword")}</div>
      <Form onSubmit={setNewPassword} schema={ResetPasswordStep2Schema}>
        <Input
          id="newPassword"
          name="newPassword"
          type="password"
          label={formatMessage("app.newpassword")}
          wrapperProps={{ mb: "12px" }}
        />

        {/* validationMessageConfig={this.validationMessageConfig( */}
        {/*  "newPassword", */}
        {/*  { */}
        {/*    message: */}
        {/*      "The password must be at least 8 characters long and contains at least 1 special character.", */}
        {/*  }, */}
        {/* )} */}
        <Button type="submit" id="resetPasswordBtn">
          {formatMessage("app.submit")}
        </Button>
      </Form>
    </div>
  );
};

const ResetPasswordStep3: FC = () => {
  const history = useHistory();
  const formatMessage = useFormatMessage();

  return (
    <div className="card mx-auto text-right">
      <img
        alt="logo"
        src={logo1x}
        srcSet={logo1x + " 1x," + logo2x + " 2x"}
        className="login-logo"
      />
      <div className="login-title">{formatMessage("app.resetPassword")}</div>
      <p className="text-left mt-3 pr-4">{formatMessage("app.wesentanemail")}</p>
      {/* <p className="text-left">Please paste it below</p> */}
      <Button id="resetPasswordBtn" onClick={() => history.push({ search: "" })}>
        {formatMessage("app.back")}
      </Button>
    </div>
  );
};

export { ResetPasswordStep1, ResetPasswordStep2, ResetPasswordStep3, LoginStep };
