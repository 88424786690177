import * as React from "react";
import classNames from "classnames";

import styles from "./DropdownCollapse.module.scss"; // Import css modules stylesheet as styles

interface State {
  isOpened: boolean;
  isFocused: boolean;
}

interface Props {
  label: string;
  fullWidth?: boolean;
}

export class DropdownCollapse extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
      isFocused: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.modifyArrowOnCollapse = this.modifyArrowOnCollapse.bind(this);

    document.body.addEventListener("click", event => {
      // console.log("body clicked", event.target, (event.target as any).classList, (event.target as any).parentElement.classList);
      if (
        !(event.target as any).classList.contains("dropdownContent") &&
        !(event.target as any).classList.contains("selectOptionsWrapper") &&
        !(event.target as any).classList.contains("inputSelectOption") &&
        !(event.target as any).classList.contains("inputSelectArrow") &&
        (event.target as any).tagName.toLowerCase() !== "input" &&
        (event.target as any).tagName.toLowerCase() !== "svg" &&
        (event.target as any).tagName.toLowerCase() !== "path"
      ) {
        document.body.classList.remove("backdrop");
        this.setState({ isOpened: false });
      }
      if (
        (event.target as any).classList &&
        ((event.target as any).classList.contains("profileTrigger") ||
          (event.target as any).parentElement.classList.contains("profileTrigger"))
      ) {
        document.body.classList.add("backdrop");
        this.setState({ isOpened: true });
      }
    });
  }

  toggleCollapse(): void {
    this.setState(
      {
        isOpened: !this.state.isOpened,
      },
      () => {
        if (this.state.isOpened) {
          document.body.classList.add("backdrop");
        } else {
          document.body.classList.remove("backdrop");
        }
      }
    );
  }

  modifyArrowOnCollapse() {
    return this.state.isOpened ? styles.activeArrow : "";
  }

  render() {
    return (
      <div
        className={classNames("dropdown", styles.dropdownCollapse, {
          fullWidth: this.props.fullWidth,
        })}
      >
        <div className={styles.labelContainer}>
          <button
            tabIndex={-1}
            className={classNames(
              "d-flex",
              "dropdownContent",
              "headerDropdownButton",
              styles.collapseButton
            )}
            onClick={() => this.toggleCollapse()}
            onBlur={() => this.setState({ isOpened: false })}
          >
            <span className={classNames("dropdownContent", styles.label)}>{this.props.label}</span>
            <div className={`${styles.arrow} ${this.modifyArrowOnCollapse()} dropdownContent`} />
          </button>
        </div>

        <div className={classNames("dropdownContent", styles.contentContainer)}>
          <div
            className={classNames("dropdownContent", styles.content, {
              [`${styles.isOpened}`]: this.state.isOpened,
            })}
          >
            <div className={classNames("dropdownContent", styles.paddingContainer)}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
