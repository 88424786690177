import React, { FC, useEffect, useState } from "react";
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import Objects from "./Objects/Objects";
import Connections from "./Connections/Connections";
import ObjectRoles from "./ObjectRoles/ObjectRoles";
import WebServices from "./WebServices/WebServices";
import Applications from "./Application/Applications";
import MailServerSettings from "./MailServerSettings/MailServerSettings";

import { Header, HeaderTab, ListContainer } from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";

import "../../styles/index.scss";
import "./ConfigurationBase.scss";
import { TConfigurationBaseProps } from "./types";
import { TRootState } from "redux/types";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../shared/state/AuthenticateActions";

const tabs = [
  { path: "connections", renderComponent: props => <Connections {...props} /> },
  { path: "mailserversettings", renderComponent: props => <MailServerSettings {...props} /> },
  { path: "objects", renderComponent: props => <Objects {...props} /> },
  { path: "objectroles", renderComponent: props => <ObjectRoles {...props} /> },
  { path: "webservices", renderComponent: props => <WebServices {...props} /> },
  { path: "applications", renderComponent: props => <Applications {...props} /> },
];

const ConfigurationBase: FC<TConfigurationBaseProps> = () => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("");
  const { path, url } = useRouteMatch();
  const formatMessage = useFormatMessage();
  const location = useLocation();
  const state = location.state as Record<string, string>;

  const userData = useSelector(({ LoginReducer }: TRootState) => LoginReducer.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      setCurrentTab(state.currentTab);
    }
  }, [state]);

  return (
    <ListContainer>
      <Header
        title="Configuration"
        changePasswordLabel={formatMessage("app.changePassword", "Change Password")}
        changeLanguage={null}
        changePassword={null}
        languages={null}
        logoutLabel={formatMessage("app.logout", "Sign Out")}
        userData={userData}
        logOut={() => dispatch(logOut())}
      >
        {tabs.map(tab => (
          <Link to={{ pathname: `${url}/${tab.path}`, state: { currentTab: tab.path } }}>
            <HeaderTab active={currentTab === tab.path} title={tab.path} />
          </Link>
        ))}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route path={`${path}/${tab.path}`}>{tab.renderComponent({ location, history })}</Route>
        ))}
      </Switch>
    </ListContainer>
  );
};

export default ConfigurationBase;
