import styled from "@emotion/styled";
import { fontSize, space } from "styled-system";

import { colors } from "@styles/colors";
import { TText } from "@components/Text/types";

const Text = styled.div<TText>`
  ${space};
  ${fontSize};
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7rem;
  color: ${({ light = true }) => (light ? colors.primaryLight : colors.primary)};
`;

export { Text };
