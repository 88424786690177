import styled from "@emotion/styled";

import { colors } from "@styles/colors";
import { THeaderTab } from "@components/HeaderTab/types";

const HeaderTab = styled.div<THeaderTab>`
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-bottom: ${({ active }) => (active ? `2px solid ${colors.primary}` : "none")};
`;

export { HeaderTab };
